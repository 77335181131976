export const SIDEBAR = {
  my_listings: 'My Listings',
  my_content: 'My Content',
  agents: 'Agents',
  billing: 'Billing',
  our_work: 'Our Work',
  settings: 'Settings',
  help_center: 'Help center',
  log_out: 'Log Out',
  copyright: '© Copyright 2022 by',
  copyright_by: 'PV Apps.',
  all_rights: 'All rights reserved.',
}