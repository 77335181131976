import { HUNDRED_PERCENT, PROGRESS_BAR } from "constants/fileUpload";

const ProgressBar = ({ progress }) => {
  return (
    <div className='photo_loading_wrapper'>
      <div className='photo_loading_outer'>
        <div className='photo_loading_inner'>
          <svg height="40" width="40" transform='rotate(-90)'>
            <circle
              cx="20"
              cy="20"
              r="18"
              stroke="#E7E7E7"
              strokeWidth="3.3"
              fill="none"
              strokeDasharray={`${PROGRESS_BAR.dasharray + (PROGRESS_BAR.frequentative * HUNDRED_PERCENT)}`}
              strokeDashoffset={PROGRESS_BAR.dashoffset}
            />
            <circle
              cx="20"
              cy="20"
              r="18"
              stroke={progress === HUNDRED_PERCENT ? "#44C13C" : "#4DA789"}
              strokeWidth="3.3"
              fill="none"
              strokeDasharray={`${PROGRESS_BAR.dasharray + (PROGRESS_BAR.frequentative * progress)}`}
              strokeDashoffset={PROGRESS_BAR.dashoffset}
            />
          </svg>
        </div>
        {progress === HUNDRED_PERCENT && <div id="tick-mark"></div>}
      </div>
    </div>
  );
};

export default ProgressBar;
