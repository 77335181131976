import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TOAST_MESSAGES } from 'constants';
import { TOAST_TYPES } from 'constants';
import { Toastify } from 'hooks';
import { goToPayment } from 'pages/tours/actions';
import {
  selectedSqFtSelector,
  salesOptionsSelector,
} from 'pages/tours/selectors';
import {
  ADDITIONAL_SERVICES,
  ADD_ONS,
  HUNDRED_PERCENT,
  MORE_SERVICES,
  NOT_SPECIFIED,
  ORDER_SUMMARY,
  PACKAGE,
  PRICE,
  RETURNED_COUNT,
  THIRTEEN_PERCENT,
} from 'pages/tours/create/constants';

import { Button } from 'components/button';
import { ADD_ONE_TO_INDEX, CUSTOMIZE } from 'pages/tours/constants';

export const OrderSummary = ({
  title,
  packages,
  filteredServices,
  selectedPackage,
  packageServices,
  isDetailsPage,
  isPaidMainServices,
  tourId,
  loungeTour,
  loungeTourDetails,
  loungeService,
}) => {
  const [isLoadingPay, setIsLoadingPay] = useState(false);

  const selectedSqFt = useSelector(selectedSqFtSelector);
  const salesOptions = useSelector(salesOptionsSelector);
  const selectedPackageServices = selectedPackage?.century_services
    ? selectedPackage?.century_services
    : selectedPackage?.services;

  const dispatch = useDispatch();

  const selectedPackageServiceIds = () => {
    const ids = [];
    selectedPackageServices?.forEach(({ id }) => {
      ids.push(id);
    });
    return ids;
  };

  const handlePay = () => {
    setIsLoadingPay(true);
    dispatch(goToPayment({ id: tourId, is_main_service: 1 }))
      .then((res) => window.open(res.data.url, '_self'))
      .catch(() => {
        Toastify({
          type: TOAST_TYPES.error,
          message: TOAST_MESSAGES.tryAgain,
        });
      })
      .finally(() => setIsLoadingPay(false));
  };

  const getSalesOptionsPrice = useMemo(() => {
    const subtotal = salesOptions?.reduce(
      (previousValue, option) =>
        option?.price
          ? Number(previousValue) + Number(option?.price)
          : Number(previousValue),
      0
    );
    return subtotal;
  }, [salesOptions]);

  const getPackagePrice = (price = 0) => {
    return Number(price);
  };

  const getPackageOptionTotal = () => {
    const tempSelPack = packages?.find((el) => el.id === selectedPackage?.id);

    const price = tempSelPack?.price;
    return Number(price) + getSalesOptionsPrice;
  };

  const getSubtotal = useMemo(() => {
    if (loungeTourDetails) return Number(loungeService?.price);

    const tempSelPack = packages?.find((el) => el.id === selectedPackage?.id);
    const prices = tempSelPack?.price ? [tempSelPack?.price] : [];

    filteredServices?.forEach((service) => {
      if (
        !selectedPackageServiceIds()?.includes(service.id) ||
        !salesOptions?.find((elem) => elem?.id !== service.id)
      ) {
        prices.push(service.price);
      }
    });

    salesOptions?.forEach((option) => {
      prices.push(option?.price);
    });

    const subtotal = prices.reduce(
      (previousValue, currentValue) =>
        currentValue
          ? Number(previousValue) + Number(currentValue)
          : Number(previousValue),
      0
    );
    return subtotal;
  }, [
    selectedPackage,
    filteredServices,
    selectedSqFt,
    packages,
    selectedPackageServiceIds,
    salesOptions,
  ]);

  const getHST = useMemo(() => {
    return (getSubtotal * THIRTEEN_PERCENT) / HUNDRED_PERCENT;
  }, [getSubtotal]);

  const getTotal = useMemo(() => {
    return (
      getSubtotal +
      (getSubtotal * THIRTEEN_PERCENT) / HUNDRED_PERCENT
    ).toFixed(RETURNED_COUNT);
  }, [getHST]);

  const servicesList = selectedPackageServices?.map((elem) => elem?.title);

  return (
    <>
      <div className={`cardCharge ${loungeTour && 'border-0'}`}>
        <h3>{title}</h3>
        <div className="titleCharge">
          <h4>{PACKAGE}</h4>
          <h4>{PRICE}</h4>
        </div>
        <ul>
          {packages
            ?.filter((el) => el.id === selectedPackage?.id)
            .map((el, i) => (
              <li key={i}>
                <span className="first_letter_uppercase">{el.title}</span> $
                {getPackagePrice(el?.price)}
              </li>
            ))}
          {loungeService && (
            <li>
              <span className="first_letter_uppercase">
                {loungeService.title}
              </span>
              ${loungeService.price ?? 0}
            </li>
          )}
        </ul>
        <ul>
          <li className="services_list">
            {servicesList?.map((el, index) => (
              <div className="service_element" key={index}>
                <span key={index} className="package_name">
                  {el}
                </span>
                {servicesList?.length !== index + ADD_ONE_TO_INDEX && (
                  <div className="service_border"></div>
                )}
              </div>
            ))}
          </li>
        </ul>
        {!!salesOptions?.length && (
          <div>
            <div className="customize_section">
              <div className="customize_title">
                <h4>{CUSTOMIZE}</h4>
              </div>
              <ul>
                {salesOptions?.map((option) => (
                  <li key={option?.id}>
                    <div className="service_name_price">
                      <span>{option?.title}</span>{' '}
                      <span className="option_price">${option?.price}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="customize_title package_total">
              <h4>{ORDER_SUMMARY.package_total}</h4>
              <h4>{`$${getPackageOptionTotal()}`}</h4>
            </div>
          </div>
        )}
        {!loungeTour && !loungeTourDetails && (
          <div className="titleCharge">
            <h4>{ADD_ONS}</h4>
          </div>
        )}
        <ul>
          {filteredServices
            ?.filter((el) => !selectedPackageServiceIds().includes(el.id))
            .map((service, i) => (
              <li key={i}>
                <span className="first_letter_uppercase">{service?.title}</span>{' '}
                {service.price ? `$${service.price}` : NOT_SPECIFIED}
              </li>
            ))}
        </ul>
        {loungeTourDetails && (
          <>
            <div className="titleCharge">
              <h4>{ADDITIONAL_SERVICES}</h4>
            </div>
            <div className="titleCharge">
              <h4>{MORE_SERVICES}</h4>
            </div>
          </>
        )}
        <div className="totalCard">
          <div className="amount">
            <p className="subTotal">
              <span>{ORDER_SUMMARY.subtotal}</span>{' '}
              <strong>${getSubtotal}</strong>
            </p>
            <p>
              <span>{ORDER_SUMMARY.hst}</span>{' '}
              <strong>
                {ORDER_SUMMARY.hst_per} (${getHST})
              </strong>
            </p>
          </div>
          <div className="total">
            <p>
              <span>{ORDER_SUMMARY.total}</span>{' '}
              <strong>${Number.parseFloat(getTotal || 0).toFixed(2)}</strong>
            </p>
          </div>
        </div>
      </div>
      {!isPaidMainServices && isDetailsPage && (
        <Button
          hidden={true}
          disabled={isLoadingPay}
          onClick={handlePay}
          customClasses="btn cta-primary later_charge_pay_btn"
          loading={isLoadingPay}
          text={`${ORDER_SUMMARY.pay} $${Number.parseFloat(getTotal || 0).toFixed(2)}`}
        />
      )}
    </>
  );
};
