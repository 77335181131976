import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  SEE_MORE_LENGTH,
  SEE_MORE_DESC_LENGTH,
  SEE_MORE,
  SEE_LESS,
  EXAMPLE,
  TYPE_STRING,
  SEE_MORE_ARRAY_LENGTH
} from 'pages/intro/constants';

// images
import IntroBuildingsIcon from 'assets/icons/business.svg';
import IntroPlusIcon from 'assets/icons/intro_plus_icon_gold.svg';

const temporaryHide = false; // Temporary hide examples

export const IntroFlowItem = (props) => {
  const {
    title,
    description,
    example_text,
    navigate_path,
    isExistingFlow,
    isBlankTarget,
    isAbsolutePath,
    example_array,
  } = props;

  const [isOpenedSeeMore, setIsOpenedSeeMore] = useState(false);
  const [isOpenedDescSeeMore, setIsOpenedDescSeeMore] = useState(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (isAbsolutePath && isBlankTarget) {
      window.open(navigate_path);
    } else {
      navigate(navigate_path, { state: { isExistingFlow } });
    }
  };

  return (
    <div className="item_container">
      <div className="header_container" onClick={handleNavigate}>
        <img alt="building" src={IntroBuildingsIcon} />
        <h3 className="intro_item_title">{title}</h3>
        <img className="intro_plus_icon" alt="plus" src={IntroPlusIcon} />
      </div>
      <div className="intro_item_description_container">
        <p className="intro_item_description_text"
        >
          {description}
        </p>
        {temporaryHide && description?.length > SEE_MORE_DESC_LENGTH && (
          <>
            {isOpenedDescSeeMore ? (
              <button
                onClick={() => setIsOpenedDescSeeMore(false)}
                className="see_more_btn"
              >
                {SEE_LESS}
              </button>
            ) : (
              <button
                onClick={() => setIsOpenedDescSeeMore(true)}
                className="see_more_btn two_lines_text_ellipsis"
              >
                {SEE_MORE}
              </button>
            )}
          </>
      )}
      </div>
      {temporaryHide && (
        <>
          <div className="intro_item_example_container">
            <h5 className="intro_item_example_title">{EXAMPLE}</h5>
            <p
              className={`intro_item_example_text ${
                !isOpenedSeeMore && 'two_lines_text_ellipsis'
              }`}
            >
              {example_text && example_text}
              {example_array?.length &&
                <ol>
                  {example_array.map((elem, index) => (
                      <li key={index}>{elem}</li>
                  ))}
                </ol>
              }
            </p>
          </div>
          {((example_text && (example_text?.length > SEE_MORE_LENGTH)) ||
            (example_array && (example_array.length > SEE_MORE_ARRAY_LENGTH))) &&
            (
              <>
                {isOpenedSeeMore ? (
                  <button
                    onClick={() => setIsOpenedSeeMore(false)}
                    className="see_more_btn"
                  >
                    {SEE_LESS}
                  </button>
                ) : (
                  <button
                    onClick={() => setIsOpenedSeeMore(true)}
                    className="see_more_btn two_lines_text_ellipsis"
                  >
                    {SEE_MORE}
                  </button>
                )}
              </>
          )}
        </>
      )}
    </div>
  );
};
