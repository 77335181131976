import { useEffect, useMemo, useRef, useState } from 'react';

import { HoverMessage } from 'components/hoverMessage';
import { PackageCheckbox } from './packageCheckbox';
import { CUSTOMIZE } from 'pages/tours/constants';
import { useSelector } from 'react-redux';
import { salesOptionsSelector } from 'pages/tours/selectors';
import checkIcon from './images/checkIcon.svg';
import { PACKAGES } from '../../constants';

export const PackageItem = (props) => {
  const {
    pack,
    isSelected,
    selectPackage,
    selectedSqFt,
    selectedPackage,
    isLastItem,
  } = props;

  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [checked, setChecked] = useState(false);
  const [addAnimation, setAddAnimation] = useState(false);

  const salesOptions = useSelector(salesOptionsSelector);

  const ref = useRef(null);

  const getSaleTotal = useMemo(() => {
    const total = isSelected
      ? salesOptions?.reduce((acc, elem) => (acc += elem?.price), 0)
      : 0;
    return total;
  }, [salesOptions, isSelected, selectedSqFt]);

  const getTotal = useMemo(() => {
    const total = getSaleTotal + pack?.price;
    return total;
  }, [getSaleTotal, pack, selectedSqFt]);

  useEffect(() => {
    if (selectedSqFt || salesOptions) {
      setAddAnimation(true);
      const timeoutId = setTimeout(() => setAddAnimation(false), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [selectedSqFt, salesOptions]);

  return (
    <div
      ref={ref}
      className={`pack_item_container ${isSelected && 'selected_container'} ${
        !selectedSqFt && 'container_blur'
      } ${
        selectedSqFt && !selectedPackage?.id ? 'container_active_blur' : ''
      } ${isLastItem ? 'last_pack_item' : ''}`}
      onClick={() => selectPackage(pack)}
    >
      <div className="price_content">
        <div
          className={`price ${selectedSqFt && pack?.price ? '' : 'no-price'} ${
            addAnimation ? 'add_animation_up' : ''
          }`}
        >
          <p className="package_price">
            {selectedSqFt && pack?.price
              ? `$${getTotal}`
              : PACKAGES.select_sqft}
          </p>
          {!!salesOptions?.length && isSelected && (
            <div className="show_price">
              <div className="line"></div>
              <div className="prices">
                <div>{`$${pack?.price} + $${getSaleTotal}`}</div>
                <div>{CUSTOMIZE}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <p className="package_title">{pack.title}</p>
      <div className="service_names_container">
        {pack?.century_services?.map((service) => {
          return (
            <div key={service.id} className="title_container">
              <p hidden className="service_title_dash">
                -
              </p>
              <img src={checkIcon} alt="Check" />
              <p className="service_title">{service.title}</p>
            </div>
          );
        })}
      </div>
      <button className="select_btn selected_btn">
        {isSelected ? 'Selected' : 'Select'}
      </button>
      {!!pack?.century_sale_options?.length && (
        <div className="sale_options">
          <p className="sale_title">{CUSTOMIZE}</p>
          {pack.century_sale_options.map((option) => (
            <PackageCheckbox
              key={option?.id}
              isPackageSelected={isSelected}
              isOptionSelected={
                !!salesOptions?.find((elem) => elem?.id === option.id)
              }
              option={option}
              selectedSqFt={selectedSqFt}
            />
          ))}
        </div>
      )}
      {!selectedSqFt && (
        <HoverMessage
          myRef={ref}
          coords={coords}
          setCoords={setCoords}
          checked={checked}
          setChecked={setChecked}
          message={'Please select sq/ft'}
          width="fit-content"
          isVisible="hover_message_visible"
        />
      )}
    </div>
  );
};
