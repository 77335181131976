export const LOUNGE_TOUR_DETAILS_TITLE = "Content Lounge Product Details";
export const ALREADY_CHARGED = "Already charged";
export const INSTRUCTIONS = "Instructions & Script Samples"
export const ORDER_PLACED_ON = "Order Placed On";
export const LOUNGE_BOOKING_DATE = "Content Lounge Booking Date";
export const TO_BE_CONFIRMED = "To be confirmed";
export const SAVE = "Save";
export const CANCEL = "Cancel";
export const EDIT = "Edit";
export const REQUESTED_BY = "Requested by";
export const REQUEST_NOTE = "Request Note";
export const DATE_FORMAT = "MM/dd/yyyy h:mm aa";
export const JUST_SINGLE_LINE = "Just a single line...";
export const DOWNLOAD_ZONE = 'DOWNLOAD ZONE';
export const DEFAULT_SHOOT_DATE = '12/02/2021';
export const ID_COVER = 'downloads';
export const UNIT_NUMBER = 'Unit number:';
export const QUANTITY = 'Quantity:';
export const SIZE = 'Size:';
export const LEARN_MORE = 'Learn More';
export const DOWNLOAD_VIDEO = 'DOWNLOAD VIDEO';
export const SUBTITLE = "Instagram Video";
export const DESCRIPTION = "We are very excited to offer our VIRTUAL STAGING service To help our clients adapt to the fast-changing environment of today's Real Estate Market and bring the best possible value proposition to your listings marketing. 2 days Turnaround Beautiful Ultra-Realistic Interiors Design that compliments your space We can work with both Empty and Furnished spaces";
export const DEFAULT_SIZE = 0;