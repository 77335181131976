import { memo, useState } from 'react';

import ProgressBar from 'components/progressBar';
import { HUNDRED_PERCENT } from 'constants/fileUpload';

import trashIcon from 'assets/icons/trash.svg';
import { CircularProgress } from 'components/circularProgress';
import {
  CIRCULAR_PROGRESS_SIZE_NAMES,
  CIRCULAR_PROGRESS_TYPES,
} from 'components/circularProgress/constants';

const imageBase = process.env.REACT_APP_IMAGE_BASE_URL;

const FileComponent = memo(
  ({ item, deleteItem, index, progress = 0, alreadyUploaded, loading }) => {
    const [hideProgress, setHideProgress] = useState(!!alreadyUploaded);

    if (progress === HUNDRED_PERCENT) {
      setTimeout(() => {
        setHideProgress(true);
      }, 1000);
    }

    return (
      <div className="video_item_container">
        <div className="video_item">
          {hideProgress && !loading && (
            <div className="delete_item" onClick={(e) => e.stopPropagation()}>
              <img
                src={trashIcon}
                alt="Trash"
                className="delete_icon"
                onClick={(e) => deleteItem(e, index, item.id)}
              />
            </div>
          )}
          {hideProgress && (
            <>
              {loading ? (
                <div className="oval_spinner">
                  <CircularProgress
                    type={CIRCULAR_PROGRESS_TYPES.white}
                    size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                  />
                </div>
              ) : (
                <img
                  src={`${imageBase}${item?.preview_image}`}
                  className="image_display"
                  alt="Uploaded"
                />
              )}
            </>
          )}
          {!hideProgress && <ProgressBar progress={progress} />}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.progress === nextProps.progress &&
    prevProps.item?.id === nextProps.item?.id
);

export default FileComponent;
