// Images
import PlayPrimaryRadiused from 'assets/images/play_primary_radiused.svg';

const image_base_url_without_storage =
  process.env.REACT_APP_IMAGE_BASE_URL.replace('/storage', '');

export const VideoItem = (props) => {
  const {
    id,
    coverImage,
    duration,
    description,
    onClickItem = () => {},
  } = props;

  const formattedDuration = Math.floor(duration / 60) + ':' + (duration % 60);

  return (
    <div className="video_item">
      <div onClick={() => onClickItem(id)} className="img_part">
        <img
          className="cover_img"
          alt="cover"
          src={`${image_base_url_without_storage}${coverImage}`}
        />
        <div className="play_img_container">
          <img className="play_img" alt="play" src={PlayPrimaryRadiused} />
        </div>
        {!!duration && <div className="duration_item">{formattedDuration}</div>}
      </div>
      <div className="description_part">
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
