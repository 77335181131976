import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actions } from 'pages/tours/store';
import { goToPayment, orderNewTour } from 'pages/tours/actions';
import {
  companyServicesSelector,
  stepsDataSelector,
} from 'pages/tours/selectors';
import { infoMessage } from 'pages/tours/create/constants';
import {
  dateWithDashesSpaceAM,
  dateWithDashesSpaceFullHours24,
  getWeekDayByMoment,
} from 'helpers/momentHelpers';
import {
  CIRCULAR_PROGRESS_SIZE_NAMES,
  CIRCULAR_PROGRESS_TYPES,
} from 'components/circularProgress/constants';

import { OrderSummary } from 'pages/tours/create/new-steps/components/order-summary';
import { CircularProgress } from 'components';

// images
import infoImg from 'assets/images/info-mesaage.svg';
import { TOURS } from 'pages/tours/constants';

export const StepThree = (props) => {
  const {
    filteredServices,
    filteredCompanyServices,
    getSubtotal,
    selectedPackage,
    selectedServices,
    selectedCompanyServices,
    selectedPackageServiceIds,
    getHST,
    getTotal,
    packages,
    chosenOptionsObj,
    salesOptions
  } = props;
  const [isPayingProcess, setIsPayingProcess] = useState(false);

  const companyServices = useSelector(companyServicesSelector);

  const sendingCompanyServices = [];

  selectedCompanyServices.forEach((elementId) => {
    const optionId = chosenOptionsObj[elementId] || null;
    sendingCompanyServices.push({ id: elementId, option_id: optionId });
  });

  const companyServicesTotalPrice = useMemo(() => {
    const filteredCompanyServices = companyServices.filter(
      (serv) => selectedCompanyServices.includes(serv.id) && serv.price
    );
    return filteredCompanyServices.reduce((acc, serv) => acc + serv.price, 0);
  }, [companyServices, selectedCompanyServices]);

  const dispatch = useDispatch();
  const stepsData = useSelector(stepsDataSelector);

  const navigate = useNavigate();
  const formattedDate = dateWithDashesSpaceFullHours24(stepsData.date);

  const tourCreationData = {
    ...stepsData,
    address: stepsData.address.endsWith(TOURS.default_country)
      ? stepsData.address
      : stepsData.address +
        ',' +
        stepsData.city +
        ',' +
        stepsData.province +
        `, ${TOURS.default_country}`,
    package: selectedPackage.id,
    sale_options: salesOptions?.map((elem) => elem?.id),
    date: formattedDate,
    company_services: sendingCompanyServices,
    company_total_cost: companyServicesTotalPrice,
  };

  const redirectToPayment = ({ id, is_main_service }) => {
    dispatch(goToPayment({ id, is_main_service }))
      .then((res) => {
        window.open(res.data.url, '_self');
      })
      .catch(() =>
        navigate('/payment-declined', { state: { propertyListingFlow: true } })
      );
  };

  const createTour = () => {
    setIsPayingProcess(true);
    dispatch(orderNewTour(tourCreationData))
      .then((res) => {
        dispatch(actions.setSelectedSqFt(0));
        dispatch(actions.setSelectedCompanyServices([]));
        dispatch(actions.setSelectedServices([]));
        dispatch(actions.setChosenOptionsObj({}));
        navigate('/payment-accepted', {
          state: { propertyListingFlow: true, id: res?.data?.id },
        });
      })
      .catch(() => {
        navigate('/payment-declined', { state: { propertyListingFlow: true } });
      });
  };

  return (
    <div className="dashboardMain">
      <div className="card">
        <h3 className="heading3 step_three_heading3">{TOURS.finalize_pay}</h3>
        <div className="row">
          <div className="col-xxl-9">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="form-group">
                  <label htmlFor="">{TOURS.property_address}</label>
                  <div className="relative min-width-260">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={stepsData?.address}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="form-group">
                  <label htmlFor="">{TOURS.city}</label>
                  <div className="relative min-width-260">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={stepsData?.city}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="form-group">
                  <label htmlFor="">{TOURS.province}</label>
                  <div className="relative min-width-260">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={stepsData?.province}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="form-group">
                  <label htmlFor="">{TOURS.postal_code}</label>
                  <div className="relative min-width-260">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={stepsData?.postal_code}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="form-group">
                  <label htmlFor="">{TOURS.unit_number}</label>
                  <div className="relative min-width-260">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={stepsData?.unit_number}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="form-group">
                  <label htmlFor="">{TOURS.requested_shooting_date}</label>
                  <div className="relative min-width-260">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`${
                        stepsData?.date
                          ? getWeekDayByMoment(stepsData?.date) +
                            ', ' +
                            dateWithDashesSpaceAM(stepsData?.date)
                          : ''
                      }`}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="packageStep">
            <div className="row">
              <div className="col-xxl-6">
                <OrderSummary
                  title={TOURS.order_summary}
                  packages={packages}
                  selectedPackage={selectedPackage}
                  selectedCompanyServices={selectedCompanyServices}
                  selectedPackageServiceIds={selectedPackageServiceIds}
                  selectedServices={selectedServices}
                  filteredServices={filteredServices}
                  filteredCompanyServices={filteredCompanyServices}
                  getSubtotal={getSubtotal}
                  getHST={getHST}
                  getTotal={getTotal}
                  salesOptions={salesOptions}
                />
              </div>
              <div className="col-xxl-6 ps-xxl-5">
                <div className="infoMessage">
                  <div className="infoHead">
                    <img src={infoImg} alt="Info Message" />
                    <h3 className="infoTitle">{TOURS.payment_message}</h3>
                  </div>
                  <p className="infoText">{infoMessage}</p>
                </div>
              </div>
              {!!stepsData?.company_services?.length && (
                <div className="later_charge_container col-lg-6">
                  <div className="cardCharge cstWidth">
                    <h3 className="later_charge_title">{TOURS.charged_later}</h3>
                    <p className="note">
                      {TOURS.charged_text}
                    </p>
                    <div className="titleCharge">
                      <h4>{TOURS.more_services}</h4>
                      <h4>{TOURS.price}</h4>
                    </div>
                    <ul>
                      {filteredCompanyServices.map((service, i) => (
                        <li key={i}>
                          <span>
                            {service.title.charAt(0).toUpperCase() +
                              service.title.slice(1)}
                          </span>{' '}
                          {service.price
                            ? `$${service.price}`
                            : TOURS.not_specified}
                        </li>
                      ))}
                    </ul>
                    <div className="totalCard">
                      <div hidden className="amount">
                        <p className="subTotal">
                          <span>{TOURS.subtotal}</span> <strong>${getSubtotal}</strong>
                        </p>
                        <p>
                          <span>{TOURS.hst}</span> <strong>{TOURS.hst_percent} (${getHST})</strong>
                        </p>
                      </div>
                      <div className="total later_charge_total">
                        <p>
                          <span>{TOURS.total}</span>{' '}
                          <strong>
                            $
                            {Number.parseFloat(
                              companyServicesTotalPrice || TOURS.default_total
                            ).toFixed(TOURS.fixed_count)}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-end footerCta">
            <button
              id="prev_button"
              className="btn cta-outline-primary mr-1"
              onClick={() => dispatch(actions.setCurrentStep(TOURS.step_two))}
            >
              {TOURS.previous}
            </button>
            <button
              disabled={isPayingProcess}
              className="btn cta-primary"
              onClick={createTour}
            >
              {isPayingProcess ? (
                <CircularProgress
                  type={CIRCULAR_PROGRESS_TYPES.white}
                  size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                />
              ) : TOURS.place_order}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
