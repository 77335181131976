import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getPackages, getCompanyUsers, getServices } from '../actions';
import { actions } from '../store';
import {
  selectedPackageSelector,
  servicesSelector,
  packagesSelector,
  companyServicesSelector,
  currentStepSelector,
  selectedServicesSelector,
  selectedCompanyServicesSelector,
  selectedPackageServiceIdsSelector,
  companyUsersSelector,
  selectedSqFtSelector,
  salesOptionsSelector,
} from '../selectors';
import { emptyTourStepsSelection } from './helpers';
import { isBrokerageAdmin } from 'helpers/checkUsersRoles';

import { NavigationControl } from 'components';
import { NewStepOne, NewStepTwo, NewStepThree } from './new-steps';
import { ControlBar } from './components/ControlBar';
import {
  ExistingStepOne,
  ExistingStepThree,
  ExistingStepTwo,
} from './existing-steps';

export function TourSteps() {
  const dispatch = useDispatch();
  const usersList = useSelector(companyUsersSelector);
  const services = useSelector(servicesSelector);
  const companyServices = useSelector(companyServicesSelector);
  const selectedServices = useSelector(selectedServicesSelector);
  const selectedCompanyServices = useSelector(selectedCompanyServicesSelector);
  const selectedPackageServiceIds = useSelector(
    selectedPackageServiceIdsSelector
  );
  const selectedSqFt = useSelector(selectedSqFtSelector);
  const selectedPackage = useSelector(selectedPackageSelector);
  const salesOptions = useSelector(salesOptionsSelector);
  const currentStep = useSelector(currentStepSelector);
  const packages = useSelector(packagesSelector);
  const chosenOptionsObj = useSelector((state) => state.tours.chosenOptionsObj);
  const user = useSelector((state) => state.auth.user);

  const { state } = useLocation();

  const getStepClass = (step) => {
    if (currentStep === step) {
      return 'active';
    }
    if (currentStep > step) {
      return 'active visited';
    }
  };

  const goToStep = (curr) => {
    if (curr < currentStep) {
      dispatch(actions.setCurrentStep(curr));
    }
    if (curr > currentStep) {
      document.getElementById('next_button').click();
    }
  };

  const filteredServices = useMemo(() => {
    return services?.filter(({ id }) => selectedServices?.includes(id));
  }, [selectedServices, packages]);

  const filteredCompanyServices = companyServices?.filter(({ id }) =>
    selectedCompanyServices.includes(id)
  );

  const getSubtotal = useMemo(() => {
    const prices = selectedPackage?.price ? [selectedPackage?.price] : [];

    filteredServices?.forEach((service) => {
      if (!selectedPackageServiceIds.includes(service.id)) {
        prices.push(service.price);
      }
    });

    salesOptions?.forEach((option) => {
      prices.push(option?.price);
    });

    const subtotal = prices.reduce(
      (previousValue, currentValue) =>
        currentValue
          ? Number(previousValue) + Number(currentValue)
          : Number(previousValue) + 0,
      0
    );
    return subtotal;
  }, [selectedPackage, filteredServices, packages, salesOptions]);

  const getHST = useMemo(() => {
    return (getSubtotal * 13) / 100;
  }, [getSubtotal]);

  const getTotal = useMemo(() => {
    return (getSubtotal + (getSubtotal * 13) / 100).toFixed(2);
  }, [getHST]);

  useEffect(() => {
    if (state?.currentStep) {
      dispatch(actions.setCurrentStep(state.currentStep));
      dispatch(actions.setStepsData(state.steps));
      if (state.forwarded) {
        dispatch(getPackages(state.selectedSqFt));
        dispatch(getServices({ price: state.selectedSqFt }));
        dispatch(actions.setSelectedSqFt(state.selectedSqFt));
        dispatch(actions.setSelectedPackage(state.selectedPackage));
        dispatch(
          actions.setSelectedPackageServiceIds(state.selectedPackageServiceIds)
        );
        dispatch(
          actions.setSelectedCompanyServices(state.selectedCompanyServices)
        );
        dispatch(actions.setSelectedServices(state.selectedServices));
      }
    } else {
      dispatch(actions.setCurrentStep(1));
      dispatch(actions.setStepsData({}));
      dispatch(getPackages());
      if (!usersList && isBrokerageAdmin(user)) {
        dispatch(getCompanyUsers());
      }
    }

    return () => {
      emptyTourStepsSelection(dispatch, actions);
    };
  }, [user, usersList]);

  return (
    <div className="content">
      <NavigationControl title="Listings" isBack />
      <ControlBar getStepClass={getStepClass} goToStep={goToStep} />
      {currentStep === 1 &&
        (state?.isExistingFlow ? <ExistingStepOne /> : <NewStepOne />)}
      {currentStep === 2 &&
        (state?.isExistingFlow ? (
          <ExistingStepTwo
            filteredServices={filteredServices}
            filteredCompanyServices={filteredCompanyServices}
            getSubtotal={getSubtotal}
            selectedPackage={selectedPackage}
            getHST={getHST}
            getTotal={getTotal}
            packages={packages}
          />
        ) : (
          <NewStepTwo
            filteredServices={filteredServices}
            filteredCompanyServices={filteredCompanyServices}
            getSubtotal={getSubtotal}
            selectedPackage={selectedPackage}
            getHST={getHST}
            getTotal={getTotal}
            packages={packages}
          />
        ))}
      {currentStep === 3 &&
        (state?.isExistingFlow ? (
          <ExistingStepThree
            filteredServices={filteredServices}
            filteredCompanyServices={filteredCompanyServices}
            getSubtotal={getSubtotal}
            selectedCompanyServices={selectedCompanyServices}
            selectedPackageServiceIds={selectedPackageServiceIds}
            selectedServices={selectedServices}
            selectedPackage={selectedPackage}
            getHST={getHST}
            getTotal={getTotal}
            packages={packages}
            chosenOptionsObj={chosenOptionsObj}
          />
        ) : (
          <NewStepThree
            filteredServices={filteredServices}
            filteredCompanyServices={filteredCompanyServices}
            getSubtotal={getSubtotal}
            selectedCompanyServices={selectedCompanyServices}
            selectedPackageServiceIds={selectedPackageServiceIds}
            selectedServices={selectedServices}
            selectedPackage={selectedPackage}
            getHST={getHST}
            getTotal={getTotal}
            packages={packages}
            chosenOptionsObj={chosenOptionsObj}
            salesOptions={salesOptions}
          />
        ))}
    </div>
  );
}
