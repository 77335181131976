import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import VimeoInput from "./vimeo-link-components/input";
import { ADD_MEDIA, ADD_NEW_MEDIA, GENERATE_RANDOM_KEY } from "pages/tours/detail/constants";
import { actions } from "pages/tours/store";

import AddIcon from "assets/images/add-dark.svg";

const VimeoLinks = ({ changedVimeoLinks }) => {
  const vimeoLinks = useSelector((state) => state.tours.vimeoLinks);

  const dispatch = useDispatch();

  const addNewLink = () => {
    dispatch(actions.setVimeoLinks([...changedVimeoLinks.current, '']))
  }

  const deleteItem = (index) => {
    const filteredData = changedVimeoLinks.current.filter((elem, idx) => idx !== index)
    dispatch(actions.setVimeoLinks(filteredData))
  }

  useEffect(() => {
    if (vimeoLinks?.length) {
      changedVimeoLinks.current = [...vimeoLinks];
    } else {
      changedVimeoLinks.current = [];
    }
  }, [vimeoLinks]);

  return (
    <div className="vimeo_container">
      <h3 className="heading5">{ADD_MEDIA}</h3>
      {vimeoLinks?.map((elem, index) => (
        <VimeoInput
          item={elem}
          index={index}
          key={Math.random().toString(GENERATE_RANDOM_KEY)}
          deleteItem={deleteItem}
          changedVimeoLinks={changedVimeoLinks}
        />
      ))}
      <button className="add_viemo_btn" onClick={addNewLink}>
        <i>
          <img src={AddIcon} alt="Add Icon" />
        </i>
        {ADD_NEW_MEDIA}
      </button>
    </div>
  )
}

export default VimeoLinks;