import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoungeDate } from './lounge-date';
import { LOUNGE_STEP_TWO_TITLE } from 'pages/tours/create/constants';
import { LoungeOrderSummary } from './lounge-summary';
import { actions } from 'pages/tours/store';
import { CircularProgress } from 'components';
import { loungeStepsData } from 'pages/tours/selectors';
import { createLoungeTour } from 'pages/tours/actions';
import { STEP_ONE } from 'pages/tours/create/constants';
import { getCalendarAddressData } from 'pages/tours/actions';
import { Toastify } from 'hooks';
import { TOAST_TYPES, TOAST_MESSAGES } from 'constants';

import {
  CIRCULAR_PROGRESS_TYPES,
  CIRCULAR_PROGRESS_SIZE_NAMES,
} from 'components/circularProgress/constants';
import { PREVIOUS, PAY } from 'pages/tours/create/constants';
import { TOURS } from 'pages/tours/constants';

export const LoungeStepTwo = ({ getTotal }) => {
  const [isPayingProcess, setIsPayingProcess] = useState(false);
  const navigate = useNavigate();

  const loungeData = useSelector(loungeStepsData);

  const dispatch = useDispatch();

  const createTour = () => {
    if(!loungeData.calendar_event_id || !loungeData.content_lounge_address_id) {
      return Toastify({
        type: TOAST_TYPES.warning,
        message: TOAST_MESSAGES.address_error,
      })
    }
    dispatch(createLoungeTour(loungeData))
    .then((res) => {
      navigate('/payment-accepted', {
        state: { propertyListingFlow: true, id: res?.data?.data?.id, isLounge: true },
      });
    })
    .catch(() => {
      navigate('/payment-declined', { state: { propertyListingFlow: true } });
    });
  };

  useEffect(() => {
    dispatch(getCalendarAddressData());
  }, [])

  return (
    <>
      <div className="d-flex align-items-start align-items-sm-center flex-sm-row flex-column">
        <h3 className="heading3 mb-0 me-4">{LOUNGE_STEP_TWO_TITLE}</h3>
      </div>
      <LoungeDate />
      <LoungeOrderSummary />
      <div className="text-end footerCta mt-lg-5">
        <button
          id="prev_button"
          className="btn cta-outline-primary mr-1"
          onClick={() => dispatch(actions.setLoungeCurrentStep(STEP_ONE))}
        >
          {PREVIOUS}
        </button>
        <button
          disabled={isPayingProcess}
          className="btn cta-primary"
          onClick={createTour}
        >
          {isPayingProcess ? (
            <CircularProgress
              type={CIRCULAR_PROGRESS_TYPES.white}
              size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
            />
          ) : TOURS.place_order}
        </button>
      </div>
    </>
  );
};
