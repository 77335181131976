import React from 'react';
import { Link } from 'react-router-dom';

import PropertyLogo from 'components/propertyLogo';

export default function AuthBackground() {
  return (
    <div className="col-md-6">
      <div className="brandBanner">
        <Link to="#" className="no_underline">
          <PropertyLogo signin />
        </Link>
      </div>
    </div>
  );
}
