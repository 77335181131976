import { StepTwo } from '../components/StepTwo';

export const NewStepTwo = (props) => {
  const {
    filteredServices,
    filteredCompanyServices,
    getSubtotal,
    selectedPackage,
    getHST,
    getTotal,
    packages,
  } = props;

  return (
    <StepTwo
      filteredServices={filteredServices}
      filteredCompanyServices={filteredCompanyServices}
      getSubtotal={getSubtotal}
      selectedPackage={selectedPackage}
      getHST={getHST}
      getTotal={getTotal}
      packages={packages}
    />
  );
};
