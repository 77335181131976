export const SECTIONS = {
  address: 'address',
  date: 'date',
  instructions: 'instructions',
  services: 'services',
};

export const SAVE_CHANGES = 'Save Changes';
export const ADD_MEDIA = 'Add Media';
export const ADD_NEW_MEDIA = 'Add New Media';
export const GENERATE_RANDOM_KEY = 36;
export const VIDEO_LINK = 'Video Link';
export const ADD_ONE_TO_INDEX = 1;

export const TOUR_DETAILS = {
  listing_details: "Listing Details",
  order_placed: "Order Placed On",
  request_shooting_date: "Requested Shooting Date",
  to_be_confirmed: "To be confirmed",
  date_format: "MM/dd/yyyy h:mm aa",
  request_note: "Request Note",
  single_line: "Just a single line...",
  services: "Services",
  save: "Save",
  cancel: "Cancel",
  edit: "Edit",
  select_sqft: "Select sq/ft",
  sqft_change: "*a change in sq/ft will affect the price",
  sq_ft: "SQ/FT",
  select_package: "Selected Package",
  add_ons: "Add-ons",
  other_services: "Other Services",
  pricing: "Pricing",
  already_charged: "Already charged",
  to_be_charged_later: "To be charged later",
  want_to_edit: "If you want to edit, please click on edit button.",
  zero_price: 0,
  hundred_percent: 100,
}