import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import DownloadPageInformation from '../download-page-information/DownloadPageInformation';
import PageLoader from '../loader';
import { PropertyLogo } from 'components';

import { isMobile } from 'constants';

// Styles
import './style.scss';
import 'animate.css';

const Cover = ({
  img,
  title,
  sub_title,
  agent,
  shoot_date,
  video,
  idCover,
  download_icon,
  liner_icon,
  mouse_icon,
  services,
  services_packages,
  APIData,
  pageLoaded,
  loading,
  isLounge,
}) => {
  const nav = useRef();
  const { pathname } = useLocation();
  const [scrollTop, setScrollTop] = useState(window.scrollY > 0);
  const [top, setTop] = useState(window.scrollY);

  const firstAddress = APIData?.address?.split(/[,]/)[0];

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      const scrollDistanceFromTop = 400;
      if (pathname.includes('blog')) {
        setTop();
        return;
      } else if (window.scrollY > 0 && window.scrollY < scrollDistanceFromTop) {
        setTop();
      } else if (
        top > window.scrollY &&
        window.scrollY > scrollDistanceFromTop
      ) {
        if (document.getElementById('pricing')) {
          document.getElementById('pricing').style.top = '0';
        }
        if (
          pathname.includes(`download-zone`) &&
          document.getElementById('sticky')
        ) {
          document.getElementById('sticky').style.top = '56px';
        } else {
          if (document.getElementById('sqftContainer')) {
            document.getElementById('sqftContainer').style.top = '0px';
          }
        }
        setTop();
      } else if (
        top < window.scrollY &&
        window.scrollY > 0 &&
        document.getElementById('pricing')
      ) {
        document.getElementById('pricing').style.top = '-70px';
        if (
          pathname.includes(`download-zone`) &&
          document.getElementById('sticky')
        ) {
          document.getElementById('sticky').style.top = '-9px';
        } else {
          if (document.getElementById('sqftContainer')) {
            document.getElementById('sqftContainer').style.top = '0px';
          }
        }
      }
      setTop(window.scrollY);
    },
    [top]
  );

  useEffect(() => {
    setTop(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    document.querySelector('#' + idCover)?.classList.remove('title_box_hide');

    const navScroll = () => {
      if (window.scrollY > 170) {
        setScrollTop(true);
      } else if (
        nav.current &&
        nav.current.classList[0] === 'downloads_cover_section'
      ) {
        setScrollTop(false);
      }
    };

    window.addEventListener('scroll', navScroll);
    return () => {
      window.removeEventListener('scroll', navScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return (
    <>
      {
        <div
          className={`page_loader_wrapper ${
            !loading && 'page_loader_wrapper_out'
          }`}
        >
          <PageLoader
            loading={loading}
            pageLoaded={pageLoaded}
            APIData={APIData}
            isLounge
          />
        </div>
      }
      <section
        className="downloads_cover_section animate__animated animate__fadeIn"
        id="hero-image-overlay"
        ref={nav}
      >
        <div className="logo-container">
          <PropertyLogo />
        </div>
        <div
          className={`mobile_address_header ${
            window.scrollY > 0 &&
            window.scrollY < 400 &&
            'coming_down_mobile_header'
          }`}
        >
          {firstAddress}
        </div>
        {video && (
          <video
            className="cover_section_video"
            controls={false}
            playsInline
            autoPlay
            muted
            loop
            src={video}
            poster={img}
          ></video>
        )}

        {!video && (
          <img src={img} alt="download" className="bg_image_download" />
        )}
        <div className="overlay">
          <div
            className={`${
              scrollTop && isMobile ? 'scrolled' : ''
            } title_box download_page_information_title_box`}
            id={idCover}
          >
            <DownloadPageInformation
              APIData={APIData}
              title={title}
              sub_title={sub_title}
              agent={agent}
              shoot_date={shoot_date}
              download_icon={download_icon}
              liner_icon={liner_icon}
              services={services}
              services_packages={services_packages}
              isLounge={isLounge}
            />
          </div>
          <div className={`mouse_section ${scrollTop ? 'd-none' : ''}`}>
            <span className="mouse_scroll">
              <img src={mouse_icon} className="mouse" />
            </span>
            <span className="scroll_text">
              Scroll down to explore Download Zone
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
export default Cover;
