import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPackages, getServices } from 'pages/tours/actions';
import { actions } from 'pages/tours/store';
import { ADD_ONE_TO_INDEX, sqFt } from 'pages/tours/constants';
import {
  servicesSelector,
  companyServicesSelector,
  selectedSqFtSelector,
  selectedServicesSelector,
  selectedCompanyServicesSelector,
  currentStepSelector,
  selectedPackageServiceIdsSelector,
  stepsDataSelector,
  salesOptionsSelector,
  selectedPackageSalOptionSelector,
} from 'pages/tours/selectors';
import {
  checkLabelsDropdownState,
  giveAffordableSaleOption,
} from 'pages/tours/create/helpers';

import { OrderSummary } from 'pages/tours/create/new-steps/components/order-summary';
import { PackageItem } from 'pages/tours/create/new-steps/components/packageItem';
import { ServiceLabel } from 'components';
import { InformativeForward } from 'components/informativeForward';
import { STEP_TWO_CONSTANTS } from 'pages/tours/create/constants';

// Images
import HomeIcon from 'assets/images/home.svg';

export const StepTwo = (props) => {
  const {
    filteredServices,
    filteredCompanyServices,
    getSubtotal,
    selectedPackage,
    getHST,
    getTotal,
    packages,
    isExistingFlow,
  } = props;
  const [packageServices, setPackageServices] = useState([]);
  const [optionServices, setOptionServices] = useState([]);
  const [isOpenServiceOptionsId, setIsOpenServiceOptionsId] = useState(null);

  const services = useSelector(servicesSelector);
  const salesOptions = useSelector(salesOptionsSelector);
  const selectedPackageSalOption = useSelector(
    selectedPackageSalOptionSelector
  );
  const companyServices = useSelector(companyServicesSelector);
  const selectedServices = useSelector(selectedServicesSelector);
  const selectedCompanyServices = useSelector(selectedCompanyServicesSelector);
  const selectedSqFt = useSelector(selectedSqFtSelector);
  const currentStep = useSelector(currentStepSelector);
  const selectedPackageServiceIds = useSelector(
    selectedPackageServiceIdsSelector
  );
  const stepsData = useSelector(stepsDataSelector);
  const chosenOptionsObj = useSelector((state) => state.tours.chosenOptionsObj);

  const dispatch = useDispatch();

  const changeSqFt = (sq) => {
    if (Number(sq) === Number(selectedSqFt)) {
      dispatch(actions.setSelectedServices([]));
      dispatch(actions.setSelectedPackage({}));
      dispatch(actions.setSelectedPackageServiceIds([]));
      dispatch(actions.setSelectedPackageSalOption([]));
      setPackageServices([]);
      setOptionServices([]);
      dispatch(getPackages(0));
      dispatch(getServices({ price: 0 }));
      dispatch(actions.setSelectedSqFt(0));
    } else {
      dispatch(getPackages(sq));
      dispatch(getServices({ price: sq }));
      dispatch(actions.setSelectedSqFt(sq));
    }
  };

  const selectService = (service) => {
    const id = service?.id;

    if (!selectedSqFt) {
      return;
    }

    let serviceIds = [];

    if (
      selectedServices.includes(id) &&
      !selectedPackageServiceIds.includes(id)
    ) {
      serviceIds = new Set([...selectedServices]);

      service?.active_services_ids.forEach(serviceIds.delete, serviceIds);

      serviceIds.delete(id);
    } else {
      serviceIds = new Set([...selectedServices, id]);

      service?.inactive_services_ids.forEach(serviceIds.delete, serviceIds);
    }

    dispatch(
      actions.setServices({
        data: services,
        selected_ids: [...serviceIds, ...packageServices, ...optionServices],
      })
    );

    giveAffordableSaleOption({
      serviceIds: [...serviceIds],
      dispatch,
      actions,
      selectedPackageSalOption,
      salesOptions,
    });
    dispatch(actions.setSelectedServices([...serviceIds]));
  };

  const companyServicesTotalPrice = useMemo(() => {
    const filteredCompanyServices = companyServices.filter(
      (serv) => selectedCompanyServices.includes(serv.id) && serv.price
    );
    return filteredCompanyServices.reduce((acc, serv) => acc + serv.price, 0);
  }, [companyServices, selectedCompanyServices]);

  const selectCompanyService = (service) => {
    const id = service?.id;

    let serviceIds = [];
    if (selectedCompanyServices.includes(id)) {
      serviceIds = selectedCompanyServices.filter((el) => el !== id);
    } else {
      serviceIds = new Set([...selectedCompanyServices, id]);
      const selService = companyServices.find((serv) => serv.id === id);
      dispatch(
        actions.setChosenOptionsObj({
          ...chosenOptionsObj,
          [id]: chosenOptionsObj?.[id] || selService?.pricing_options?.[0]?.id,
        })
      );
    }

    dispatch(actions.setSelectedCompanyServices([...serviceIds]));
  };

  const selectPackage = (pack) => {
    if (!selectedSqFt) {
      return;
    }
    const ids = [];
    let packageDisableServiceIDs = [];
    let selServices = [];

    const packageServicesIds =
      selectedPackage.id === pack.id
        ? []
        : pack?.century_services?.map((elem) => elem.id);

    services.forEach((elem) => {
      if (packageServicesIds.includes(elem?.id)) {
        packageDisableServiceIDs.push(...elem?.inactive_services_ids);
      } else {
        packageDisableServiceIDs.push(...elem?.active_services_ids);
      }
    });

    if (selectedPackage.id === pack.id) {
      dispatch(actions.setSelectedPackage({}));
      dispatch(actions.setSelectedPackageServiceIds([]));
      dispatch(actions.setSelectedPackageSalOption([]));
      dispatch(actions.setSalesOptions([]));

      const removedDisabledServices = selectedServices?.filter(
        (servId) => !packageDisableServiceIDs.includes(servId)
      );

      selServices = [...removedDisabledServices];
    } else {
      dispatch(actions.setSelectedPackage(pack));
      dispatch(actions.setSelectedPackageSalOption(pack?.century_sale_options));
      dispatch(actions.setSalesOptions([]));
      pack?.century_services?.forEach((element) => {
        ids.push(element.id);
      });
      dispatch(actions.setSelectedPackageServiceIds(ids));

      const nonPackageServices = selectedServices?.filter(
        (servId) =>
          !ids.includes(servId) && !packageDisableServiceIDs.includes(servId)
      );

      selServices = [...nonPackageServices];

      giveAffordableSaleOption({
        serviceIds: [...nonPackageServices],
        dispatch,
        actions,
        selectedPackageSalOption: pack?.century_sale_options,
        salesOptions,
      });
    }
    const serviceIds = new Set(ids);

    setPackageServices([...serviceIds]);
    dispatch(actions.setSelectedServices(selServices));

    dispatch(
      actions.setServices({
        data: services,
        selected_ids: [...serviceIds, ...optionServices, ...selServices],
      })
    );
  };

  const handleOpenServicesOptionsDropdown = (e, id) => {
    e.stopPropagation();
    if (isOpenServiceOptionsId === id) {
      setIsOpenServiceOptionsId(null);
    } else {
      setIsOpenServiceOptionsId(id);
    }
  };

  const onSubmit = () => {
    if (!selectedPackage.id && !selectedServices?.length) {
      return;
    }
    dispatch(actions.setCurrentStep(currentStep + 1));
    const formattedCompanyServices = [];

    selectedCompanyServices.forEach((id) => {
      if (chosenOptionsObj[id]) {
        formattedCompanyServices.push({
          id: Number(id),
          option_id: Number(chosenOptionsObj[id]),
        });
      } else {
        formattedCompanyServices.push({ id: Number(id), option_id: null });
      }
    });

    const data = {
      sqft: selectedSqFt,
      service: selectedServices,
      package: selectedPackage?.id,
      company_services: formattedCompanyServices,
      total_cost: getTotal,
      discount: 13,
      subtotal: getSubtotal,
    };
    dispatch(actions.setStepsData({ ...stepsData, ...data }));
  };

  const handleOptionSelect = ({ serviceId, optionId }) => {
    dispatch(
      actions.setChosenOptionsObj({
        ...chosenOptionsObj,
        [serviceId]: Number(optionId),
      })
    );
  };

  const handleClickOurWorkPageForward = () => {
    const formattedCompanyServices = [];

    selectedCompanyServices.forEach((id) => {
      if (chosenOptionsObj[id]) {
        formattedCompanyServices.push({
          id: Number(id),
          option_id: Number(chosenOptionsObj[id]),
        });
      } else {
        formattedCompanyServices.push({ id: Number(id), option_id: null });
      }
    });

    const data = {
      sqft: selectedSqFt,
      service: selectedServices,
      package: selectedPackage?.id,
      company_services: formattedCompanyServices,
      total_cost: getTotal,
      discount: 13,
      subtotal: getSubtotal,
    };
    dispatch(actions.setStepsData({ ...stepsData, ...data }));
  };

  useEffect(() => {
    dispatch(getServices({ price: selectedSqFt || 0 }));
  }, []);

  useEffect(() => {
    if (
      selectedPackage &&
      Object.keys(selectedPackage).length &&
      packages?.length
    ) {
      const currentPackage = packages?.find(
        (pack) => pack.id === selectedPackage.id
      );
      dispatch(actions.setSelectedPackage(currentPackage));
    }
  }, [selectedSqFt, dispatch, packages, selectedPackage]);

  useEffect(() => {
    const serviceIds = [];
    salesOptions?.forEach((option) => {
      const services = option.century_pricings?.map((service) => service.id);
      serviceIds.push(...services);
    });

    const nonOptionServices = selectedServices.filter(
      (servId) => !serviceIds.includes(servId)
    );

    dispatch(actions.setSelectedServices(nonOptionServices));
    setOptionServices(serviceIds);
  }, [salesOptions]);

  return (
    <div className="dashboardMain">
      <div className="card">
        <h3 className="heading3">{STEP_TWO_CONSTANTS.add_service_package}</h3>
        <InformativeForward
          infoText={'Not sure which services to choose?.'}
          buttonText={'Explore the samples of our work.'}
          forwardPath={'/our-work'}
          onClick={handleClickOurWorkPageForward}
        />
        {isExistingFlow ? (
          <>
            <div className="captionForm">
              <h4>{STEP_TWO_CONSTANTS.property_sqft}</h4>
            </div>
            <ul className="selectSizeList existing_size_list">
              <li className={'active'}>
                <div>
                  <i>
                    <img src={HomeIcon} alt="home" />
                  </i>
                  <span>
                    &lt; {selectedSqFt}
                    <small>{STEP_TWO_CONSTANTS.sq_ft}</small>
                  </span>
                </div>
              </li>
            </ul>
          </>
        ) : (
          <>
            <div className="captionForm">
              <h4>{STEP_TWO_CONSTANTS.select_sq_ft}</h4>
              <p>
                <i>{STEP_TWO_CONSTANTS.note_to_select}</i>
              </p>
            </div>
            <ul className="selectSizeList">
              {sqFt?.map((sq, index) => (
                <li
                  className={`${!selectedSqFt && 'sq_ft_animation_active'} ${
                    selectedSqFt === sq.value ? 'active' : ''
                  }`}
                  key={index}
                  onClick={() => {
                    changeSqFt(sq.value);
                  }}
                >
                  <div>
                    <i>
                      <img src={HomeIcon} alt="home" />
                    </i>
                    <span>
                      {sq.title === '1000' && <span> &lt; </span>}
                      {sq.title}
                      <small>{STEP_TWO_CONSTANTS.sq_ft}</small>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        <div className="row packages_services_container">
          <div>
            <div className="form-group">
              <label className="package_label" htmlFor="">
                {STEP_TWO_CONSTANTS.select_package}
              </label>
              <div className="packages_container">
                {packages.map((pack, index) => (
                  <PackageItem
                    key={pack.id}
                    pack={pack}
                    selectPackage={selectPackage}
                    selectedSqFt={selectedSqFt}
                    isSelected={selectedPackage?.id === pack?.id}
                    selectedPackage={selectedPackage}
                    isLastItem={index + ADD_ONE_TO_INDEX === packages.length}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-xxl-9 col-lg-8">
            <div className="form-group">
              <label htmlFor="">{STEP_TWO_CONSTANTS.add_ons_desc}</label>
              <ul className="labelList">
                {services?.map((service) => {
                  return (
                    <ServiceLabel
                      key={service.id}
                      service={service}
                      selectedServices={selectedServices}
                      selectService={selectService}
                      isOpenServiceOptionsId={isOpenServiceOptionsId}
                      handleOpenServicesOptionsDropdown={
                        handleOpenServicesOptionsDropdown
                      }
                      checkLabelsDropdownState={checkLabelsDropdownState}
                      handleOptionSelect={handleOptionSelect}
                      hidden={
                        packageServices.includes(service.id) ||
                        selectedPackageServiceIds.includes(service.id)
                      }
                      disabled={
                        packageServices.includes(service.id) ||
                        selectedPackageServiceIds.includes(service.id) ||
                        optionServices.includes(service.id)
                      }
                      className={`ctaService ${
                        selectedServices?.includes(service.id) ? 'active' : ''
                      }`}
                      withOptions={false}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          {!!companyServices?.length && (
            <div className="col-xk-6">
              <div className="form-group more_services_container">
                <label htmlFor="">{STEP_TWO_CONSTANTS.more_services}</label>
                <ul className="labelList">
                  {companyServices?.map((service) => {
                    return (
                      <ServiceLabel
                        key={service.id}
                        service={service}
                        selectedServices={selectedCompanyServices}
                        selectService={selectCompanyService}
                        isOpenServiceOptionsId={isOpenServiceOptionsId}
                        handleOpenServicesOptionsDropdown={
                          handleOpenServicesOptionsDropdown
                        }
                        checkLabelsDropdownState={checkLabelsDropdownState}
                        handleOptionSelect={handleOptionSelect}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="packageStep">
          <div
            className={`row ${
              !companyServices?.length &&
              'justify-content-sm-end justify-content-center'
            }`}
          >
            {!!companyServices?.length && (
              <div className="col-lg-6">
                <div className="cardCharge cstWidth">
                  <h3 className="later_charge_title">
                    {STEP_TWO_CONSTANTS.to_be_charged}
                  </h3>
                  <p className="note">
                    {STEP_TWO_CONSTANTS.charged_later_desc}
                  </p>
                  <div className="titleCharge">
                    <h4>{STEP_TWO_CONSTANTS.more_services}</h4>
                    <h4>{STEP_TWO_CONSTANTS.Price}</h4>
                  </div>
                  <ul>
                    {filteredCompanyServices.map((service, i) => (
                      <li key={i}>
                        <span>
                          {service.title.charAt(0).toUpperCase() +
                            service.title.slice(1)}
                        </span>{' '}
                        {service.price
                          ? `$${service.price}`
                          : STEP_TWO_CONSTANTS.not_specified}
                      </li>
                    ))}
                  </ul>
                  <div className="totalCard">
                    <div hidden className="amount">
                      <p className="subTotal">
                        <span>{STEP_TWO_CONSTANTS.subtotal}</span>{' '}
                        <strong>${getSubtotal}</strong>
                      </p>
                      <p>
                        <span>{STEP_TWO_CONSTANTS.hst}</span>{' '}
                        <strong>
                          {STEP_TWO_CONSTANTS.hst_percent} (${getHST})
                        </strong>
                      </p>
                    </div>
                    <div className="total later_charge_total">
                      <p>
                        <span>{STEP_TWO_CONSTANTS.total}</span>{' '}
                        <strong>
                          $
                          {Number.parseFloat(
                            companyServicesTotalPrice || 0
                          ).toFixed(2)}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-6 col-md-9 col-12 row justify-content-end">
              <OrderSummary
                title="Payable now"
                packages={packages}
                selectedPackage={selectedPackage}
                filteredServices={filteredServices}
                filteredCompanyServices={filteredCompanyServices}
                getSubtotal={getSubtotal}
                getHST={getHST}
                getTotal={getTotal}
                packageServices={packageServices}
                selectedSqFt={selectedSqFt}
              />
            </div>
          </div>
        </div>

        <div className="text-end footerCta">
          <button
            id="prev_button"
            className="btn cta-outline-primary"
            onClick={() => dispatch(actions.setCurrentStep(1))}
          >
            {STEP_TWO_CONSTANTS.previous}
          </button>
          <button
            className={`btn cta-primary ${
              !selectedPackage?.id && !selectedServices?.length && 'disabled'
            }`}
            id="next_button"
            onClick={onSubmit}
            disabled={!selectedPackage?.id && !selectedServices?.length}
          >
            {STEP_TWO_CONSTANTS.next}
          </button>
        </div>
      </div>
    </div>
  );
};
