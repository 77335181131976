import Slider from 'react-slick';

import { defaultSettings, mobileDefaultSettings } from './constants';

// images
import prevArrow from 'assets/images/prevArrow.svg';
import nextArrow from 'assets/images/nextArrow.svg';

const base_url_without_storage =
  process.env.REACT_APP_IMAGE_BASE_URL.split('storage').join('');

export const MainSlider = ({
  close_icon,
  closeGalleryModal,
  swipeGallery,
  settings,
  showDownloadLink = false,
  isDownloadZone = false,
  imageItemKey = 'image',
}) => {
  let default_settings = settings;
  const isMobile = window.innerWidth < 767;

  if (!settings) {
    default_settings =
      swipeGallery?.length === 1
        ? { slidesToShow: 1 }
        : isMobile
        ? mobileDefaultSettings
        : defaultSettings;
  }

  if (window.innerWidth % 10 > 0 && isMobile) {
    default_settings = {
      ...default_settings,
      variableWidth: false,
      nextArrow: <img src={nextArrow} alt="next" />,
      prevArrow: <img src={prevArrow} alt="prev" />,
    };
  }

  const handleContainerClick = (e) => {
    const sliderPositionStart = document
      .getElementsByClassName('slick-list')?.[0]
      ?.getBoundingClientRect()?.top;
    const sliderPositionEnd =
      document
        .getElementsByClassName('slick-list')?.[0]
        ?.getBoundingClientRect()?.height + sliderPositionStart;
    if (
      !(e.clientY > sliderPositionStart && e.clientY < sliderPositionEnd) &&
      !isMobile
    ) {
      closeGalleryModal();
    }
  };

  return (
    <section
      onClick={handleContainerClick}
      className="modal_section gallery_modal"
    >
      <div className="close_btn_container">
        <div className="close-btn_section" onClick={closeGalleryModal}>
          <img src={close_icon} alt="close" />
        </div>
      </div>
      <Slider className="main-slider" {...default_settings}>
        {swipeGallery?.map((item, i) => {
          const imageSrc = showDownloadLink
            ? item.downloadLink
            : base_url_without_storage + item[imageItemKey];
          return (
            <div className="modal_item" key={i}>
              <div className="modal_absolute_layer"></div>
              <img
                src={imageSrc}
                className="image_modal image_modal_swiper"
                alt="house"
                key={i}
              />
            </div>
          );
        })}
      </Slider>
    </section>
  );
};
