import { apiManager } from './apiManager';

export const billingApi = {
  getPaymentHistoryList: async () =>
    await apiManager.get('/dashboard/tours/get-payments'),
  getDownloadLink: async ({ payment_id, auth_id }) =>
    await apiManager.get(
      `/dashboard/tours/download-receipt/${payment_id}?auth_id=${auth_id}`
    ),
};
