import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import { deleteUploadedVideos, confirmUploadedVideos } from './actions';

import ProgressBar from 'components/progressBar';
import {
  UPLOAD,
  DRAG_TEXT,
  SUPPORT_TEXT,
  SAVE,
  CANCEL,
  HUNDRED_PERCENT,
  UPLOADED_FILES,
} from 'constants/fileUpload';
import FileComponent from './fileComponent';
import { CircularProgress } from 'components/circularProgress';
import {
  CIRCULAR_PROGRESS_TYPES,
  CIRCULAR_PROGRESS_SIZE_NAMES,
} from 'components/circularProgress/constants';

import CloseIcon from 'assets/images/close.svg';
import UploadIcon from 'assets/images/upload.svg';
import AddIcon from 'assets/images/add-dark.svg';
import { uploadWithChunks } from 'helpers/uploadChunks';

const FileUploader = ({ customWrapperClass = '', isAuth, tourId }) => {
  const [file, setFile] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [progress, setProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState({});
  const [disableCancel, setDisableCancel] = useState(false);

  const getLocalData = () => JSON.parse(sessionStorage.getItem(UPLOADED_FILES));

  const navigate = useNavigate();

  const onUploadProgress = (loaded, total, id) => {
    setProgress((prev) => ({
      ...prev,
      [id]: Math.round((HUNDRED_PERCENT * loaded) / total),
    }));
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);

    const id = Date.now();
    const addedFiles = acceptedFiles.map((file, index) => ({
      file,
      id: index + id,
      key: index + id,
    }));

    setFile((prev) => [...prev, ...addedFiles]);

    await uploadWithChunks({
      acceptedFiles,
      onUploadProgress,
      setPendingLoading,
      tourId,
      setFilesData,
      id,
      setFile,
      setDisableCancel,
    });

    setLoading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const deleteWholeItems = () => {
    const formData = new FormData();

    getLocalData()?.forEach((item) => {
      formData.append('file_ids[]', item.id);
    });

    if (!!getLocalData()?.length) {
      deleteUploadedVideos(tourId, formData).then(() => {
        setFilesData([]);
        setFile([]);
        sessionStorage.removeItem(UPLOADED_FILES);
      });
    }
  };

  const closePopup = () => {
    deleteWholeItems();
    isAuth ? navigate('/lounge-tours') : navigate('/sign-in');
  };

  const deleteItem = (e, index, id) => {
    e.stopPropagation();

    const formData = new FormData();
    formData.append('file_ids[]', id);

    const filteredData = getLocalData()?.filter((item) => item.id !== id);

    deleteUploadedVideos(tourId, formData).then(() => {
      sessionStorage.setItem(UPLOADED_FILES, JSON.stringify([...filteredData]));
      setFilesData(filesData.filter((item) => item.id !== id));
      setFile([...getLocalData()]);
    });
  };

  const confirmChanges = () => {
    setLoading(true);
    confirmUploadedVideos(tourId)
      .then(() => {
        setFilesData([]);
        setFile([]);
        sessionStorage.removeItem(UPLOADED_FILES);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (getLocalData()) setFile([...getLocalData()]);
  }, []);

  return (
    <div className="uploader_wrapper">
      <div className={`${customWrapperClass} uploader_container`}>
        <div className="uploader_header">
          <div className="title_wrapper">
            <h3 className="title">{UPLOAD}</h3>
          </div>
          <div className="uploader_btn_wrapper">
            <button
              className="uploader_btn"
              onClick={closePopup}
              disabled={disableCancel}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
        </div>
        <div className="video_upload" {...getRootProps()}>
          <div
            className={`${
              !!file.length && 'video_uploaded'
            } video_upload_container`}
          >
            {!!file.length ? (
              <div className="show_videos">
                {file.map((item, index) => (
                  <FileComponent
                    deleteItem={deleteItem}
                    key={item?.key || getLocalData()?.[index]?.id}
                    index={index}
                    item={getLocalData()?.[index]}
                    progress={progress[item.id]}
                    alreadyUploaded={item?.progress_id}
                    loading={pendingLoading[item.id]}
                  />
                ))}
                <div className="upload_btn_container">
                  <button className="upload_btn">
                    <label>
                      <img src={AddIcon} alt="Add" />
                      <span>{UPLOAD}</span>
                    </label>
                    <input
                      className="upload_input"
                      type="file"
                      accept="video/*"
                      {...getInputProps()}
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div className="video_upload_content">
                <div className="uploader_icon">
                  <img src={UploadIcon} alt="Upload" />
                </div>
                <h4 className="video_upload_title">{DRAG_TEXT}</h4>
                <p className="video_upload_text">{SUPPORT_TEXT}</p>
                {!file.length || isDragActive ? (
                  <button className="upload_btn">
                    <label>
                      <img src={AddIcon} alt="Add" />
                      <span>{UPLOAD}</span>
                    </label>
                    <input
                      className="upload_input"
                      type="file"
                      accept="video/*"
                      {...getInputProps()}
                    />
                  </button>
                ) : (
                  <ProgressBar />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="uploader_footer">
          <div className="footer_btn_wrapper">
            <button
              className="uploader_cancel_btn"
              onClick={closePopup}
              disabled={disableCancel}
            >
              {CANCEL}
            </button>
            <button
              className="uploader_save_btn"
              onClick={confirmChanges}
              disabled={loading || !getLocalData()?.length}
            >
              {loading && (
                <div className="oval_spinner">
                  <CircularProgress
                    type={CIRCULAR_PROGRESS_TYPES.white}
                    size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                  />
                </div>
              )}
              <span className={loading ? 'hide_name' : ''}>{SAVE}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
