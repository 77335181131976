import axios from 'axios';

const calendlyBaseUrl = process.env.REACT_APP_CALENDLY_API;

const instance = axios.create({
  baseURL: calendlyBaseUrl,
  headers: {
    Accept: 'application/json',
  },
});

export const calendlyApi = {
  getCalendlyDataById: async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await instance.get(id, config);
  },
  cancelCalendlyById: async (id, body, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return await instance.post(`${id}/cancellation`, body, config);
  },
};
