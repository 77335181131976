import { useState } from "react";

import { Input } from "components";
import { VIDEO_LINK, ADD_ONE_TO_INDEX } from "pages/tours/detail/constants";

const VimeoInput = ({ item, index, deleteItem, changedVimeoLinks }) => {
  const [value, setValue] = useState(item);

  const changeInputValue = (e) => {
    setValue(e.target.value);
    changedVimeoLinks.current[index] = e.target.value;
  }

  return (
    <div className="vimeo_input_field">
      <Input 
        value={value}
        onChange={changeInputValue}
        label={`${VIDEO_LINK} ${index + ADD_ONE_TO_INDEX}`} 
        inputCustomClasses='vimeo_links_field'
        labelClass="input_label"
        customClasses="vimeo_input_container"
      />
      <button className="vimeo_delete_btn" onClick={() => deleteItem(index)}>x</button>
    </div>
  )
}

export default VimeoInput;