import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import config from './config';
import CoverSection from './components/downloads-cover-section/cover-section';
import { getLoungeContent } from './loungeActions';
import { LoungeDownloadBody } from './components/lounge-packages';
import { DOWNLOAD_ZONE, ID_COVER } from 'constants/loungeTour';

import download from 'pages/download-zone/icons/download.png';
import liner from 'pages/download-zone/icons/liner.png';
import mouse from './icons/mouse.svg';
import CoverImage from 'assets/images/coverImage.webp';

const LoungeDownloadPage = () => {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({});

  const { id } = useParams();
  const dispatch = useDispatch();

  const tourDetails = useSelector((state) => state.tours.tourDetails);

  const changeLoadStatus = () => {
    setPageLoaded(true);
  };

  useEffect(() => {
    dispatch(getLoungeContent(id, setLoading));
  }, []);

  return (
    <div className="download_zone_container">
      <div className="relative">
        <CoverSection
          pageLoaded={pageLoaded}
          loading={loading}
          APIData={tourDetails}
          idCover={ID_COVER}
          img={
            tourDetails?.cover
              ? tourDetails.imagesBaseUrl + content?.cover
              : CoverImage
          }
          title={DOWNLOAD_ZONE}
          sub_title={tourDetails?.address ?? ''}
          agent={`${tourDetails?.user?.name} ${tourDetails?.user?.last_name}`}
          shoot_date={null}
          services={tourDetails?.services}
          services_packages={tourDetails?.packages?.pricing_services_list}
          download_icon={download}
          liner_icon={liner}
          mouse_icon={mouse}
          video={
            tourDetails?.cover_video
              ? config.apiBaseUrl + tourDetails?.cover_video
              : null
          }
          isLounge
        />
        <div className="download_lounge_body">
          {tourDetails?.vimeo_links?.map((item, index) => (
            <LoungeDownloadBody
              data={item}
              key={item?.link}
              quantity={tourDetails?.vimeo_links?.length}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoungeDownloadPage;
