import FileUploader from "components/fileUploadPopup";
import { useParams } from "react-router-dom";

export const UploadVideos = () => {
  const { id } = useParams();

  return (
    <div>
      <FileUploader 
        customWrapperClass="videop_uploader_wrapper"
        isAuth={false}
        tourId={id}
      />
    </div>
  )
}