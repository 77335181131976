import { downloadZoneApi } from "api";
import { actions } from "pages/tours/store";

export const getLoungeContent = (id, setLoading) => {
  return async (dispatch) => {
    const res = await downloadZoneApi.getCenturyDownloadData(id);

    const dataOpeningDuration = 800;
    if(res.data.success) {
      setTimeout(() => {
        setLoading(false);
      }, dataOpeningDuration);
    }

    dispatch(actions.setTourDetails(res.data.data.item));
  };
};