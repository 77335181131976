import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DownloadButton } from '../../DownloadButton/DownloadButton';
import getUrlFromIframe from '../../../global/url-helper';
import AddressHolder from '../../address-holder';
import Quantity from '../../quantity-text';
import CopyLinkFromIframe from '../video-section/copy-link/CopyLinkFromIframe';

// Styles
import './style.scss';

const instagramText =
  'Instagram Reels are a short-form, vertical video format on Instagram that is growing in popularity. Real estate agents who want to stay ahead of the game and effectively market their properties and personal brand should take notice, as this format outperforms other types of content. Same format is used on Youtube Shorts & TikTok.';

function Instagram({ APIData }) {
  const params = useParams();
  const [videoPlaying, setVideoPlaying] = useState();

  const play = (e) => {
    const elem = e.target;
    elem.classList.add('hide');
    const video = e.target.parentNode.children[1];
    video.load();
    video.play();
    video.controls = true;
    setVideoPlaying(elem);
  };

  return (
    !!APIData?.instagram?.length && (
      <div className="insta_section" id="17">
        <div className="title_holder">
          <h2 className="social_media_title download_section_title">
            {' '}
            Social media content
          </h2>
          <div>
            <Quantity lengthOfItems={'1'} />
          </div>
        </div>
        <div className="insta_section_item" id="13">
          <div className="insta_show_section">
            <div className="insta_video">
              <div className="insta_video">
                <div className="insta">
                  <iframe
                    title="insta_iframe"
                    src={getUrlFromIframe(APIData?.instagram)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="insta_right_section">
            <div className="right">
              <div className="mobile_styling">
                <h3>Instagram Video</h3>

                <div className="instagram_address_holder">
                  <AddressHolder APIData={APIData} />
                </div>
              </div>
              <span className="insta_section_text">{instagramText}</span>
            </div>
            <div className="btn_wrapper">
              <DownloadButton
                buttonName="DOWNLOAD VIDEO"
                videoLink={getUrlFromIframe(APIData?.instagram)}
              />
              <CopyLinkFromIframe iframe={APIData?.instagram} />
            </div>
          </div>
        </div>
        <span className="liner"></span>
      </div>
    )
  );
}

export default Instagram;
