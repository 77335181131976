import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { formatPhoneNumber } from 'helpers/formatPhoneNumber';
import { signUp } from './actions';
import { Toastify } from 'hooks';
import {
  CIRCULAR_PROGRESS_SIZE_NAMES,
  CIRCULAR_PROGRESS_TYPES,
} from 'components/circularProgress/constants';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';
import {
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_PAGE,
  CREATE_ACCOUNT,
  SIGN_UP_VALID_MESSAGE,
} from 'constants/singUp';

import { AuthBackground, CircularProgress, Input, Select } from 'components';
import { validatePhoneNumberRegex } from 'regex/phoneRegex';

export function SignUp() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    email: yup
      .string(SIGN_UP_VALID_MESSAGE.email)
      .email(SIGN_UP_VALID_MESSAGE.message)
      .required(SIGN_UP_VALID_MESSAGE.email_required),
    password: yup
      .string()
      .min(8, SIGN_UP_VALID_MESSAGE.password_min)
      .max(50, SIGN_UP_VALID_MESSAGE.password_max)
      .required(SIGN_UP_VALID_MESSAGE.password_required),
    password_confirmation: yup
      .string()
      .min(8, SIGN_UP_VALID_MESSAGE.password_min)
      .max(50, SIGN_UP_VALID_MESSAGE.password_max)
      .required(SIGN_UP_VALID_MESSAGE.confirm_pass_required)
      .oneOf(
        [yup.ref(SIGN_UP_VALID_MESSAGE.password), null],
        SIGN_UP_VALID_MESSAGE.password_match
      ),
    position: yup.string().required(SIGN_UP_VALID_MESSAGE.position),
    name: yup
      .string(SIGN_UP_VALID_MESSAGE.first_name)
      .required(SIGN_UP_VALID_MESSAGE.first_name_required),
    last_name: yup
      .string(SIGN_UP_VALID_MESSAGE.last_name)
      .required(SIGN_UP_VALID_MESSAGE.last_name_required),
    phone: yup
      .string(SIGN_UP_VALID_MESSAGE.phone)
      .required(SIGN_UP_VALID_MESSAGE.phone_required)
      .min(10, SIGN_UP_VALID_MESSAGE.phone_min)
      .test(
        SIGN_UP_VALID_MESSAGE.test_type,
        SIGN_UP_VALID_MESSAGE.phone_error,
        () => validatePhoneNumberRegex.test(phoneNumber)
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
      position: '',
      name: '',
      last_name: '',
      phone: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formatedPhoneNumber = formatPhoneNumber(values.phone);
      const newValues = {
        ...values,
        phone: formatedPhoneNumber,
      }

      setIsSubmitLoading(true);
      dispatch(signUp(newValues))
        .then(() => {
          navigate('/sign-in');
        })
        .catch(({ response }) => {
          const message = response.data.errors.email[0];
          Toastify({
            type: TOAST_TYPES.error,
            message: message || TOAST_MESSAGES.existingEmail,
          });
        })
        .finally(() => setIsSubmitLoading(false));
    },
  });

  return (
    <div className="row g-0">
      <AuthBackground />
      <div className="col-md-6">
        <div className="form-wrapper">
          <form onSubmit={formik.handleSubmit}>
            <ul className="cstTabs">
              <li>
                <Link to="/sign-in">{SIGN_IN}</Link>
              </li>
              <li>
                <Link to="/#" className="active">
                  {SIGN_UP}
                </Link>
              </li>
            </ul>
            <Select
              id="position"
              label={SIGN_UP_PAGE.user_type}
              name="position"
              customClasses="label_dark"
              options={[
                {
                  label: SIGN_UP_PAGE.sales_representative,
                  value: SIGN_UP_PAGE.sales_representative,
                },
                {
                  label: SIGN_UP_PAGE.broker,
                  value: SIGN_UP_PAGE.broker,
                },
              ]}
              placeholder={SIGN_UP_PAGE.user_placeholder}
              value={formik.values.position}
              onChange={formik.handleChange}
              error={formik.touched.position && Boolean(formik.errors.position)}
              helperText={formik.touched.position && formik.errors.position}
              required
            />
            <Input
              id="email"
              label={SIGN_UP_PAGE.email}
              name="email"
              customClasses="label_dark"
              placeholder={SIGN_UP_PAGE.email_placeholder}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
            />
            <div className="row">
              <div className="col-lg-6">
                <Input
                  id="name"
                  label={SIGN_UP_PAGE.first_name}
                  name="name"
                  customClasses="label_dark"
                  placeholder={SIGN_UP_PAGE.first_name_placeholder}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  required
                />
              </div>
              <div className="col-lg-6">
                <Input
                  id="last_name"
                  label={SIGN_UP_PAGE.last_name}
                  name="last_name"
                  customClasses="label_dark"
                  placeholder={SIGN_UP_PAGE.last_name_placeholder}
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                  required
                />
              </div>
            </div>
            <Input
              id="phone"
              label={SIGN_UP_PAGE.phone_number}
              name="phone"
              customClasses="label_dark"
              placeholder={SIGN_UP_PAGE.phone_number_placeholder}
              value={phoneNumber}
              onChange={(e) => {
                const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                setPhoneNumber(formattedPhoneNumber);
                formik.handleChange(e);
              }}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              required
            />
            <Input
              id="password"
              label={SIGN_UP_PAGE.password}
              name="password"
              customClasses="label_dark"
              type="password"
              placeholder={SIGN_UP_PAGE.password_placeholder}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              required
            />
            <Input
              id="password_confirmation"
              label={SIGN_UP_PAGE.confirm_pass}
              name="password_confirmation"
              customClasses="label_dark"
              type="password"
              placeholder={SIGN_UP_PAGE.password_placeholder}
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
              error={
                formik.touched.password_confirmation &&
                Boolean(formik.errors.password_confirmation)
              }
              helperText={
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation
              }
              required
            />
            <button
              disabled={isSubmitLoading}
              className="btn cta-primary w-100"
              type="submit"
            >
              {isSubmitLoading ? (
                <CircularProgress
                  type={CIRCULAR_PROGRESS_TYPES.white}
                  size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                />
              ) : (
                CREATE_ACCOUNT
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
