import {
  LOUNGE_STEP_ONE_TITLE,
  LOUNGE_STEP_ONE_TEXT,
} from 'pages/tours/create/constants';

import image from 'assets/images/maskgroup.jpeg';
import playImage from 'assets/images/play_primary_radiused.svg';

export const LoungeServiceInfo = ({ setShowModalVideo }) => {
  return (
    <div className="loungeServiceInfo">
      <div className="textSection">
        <h3>{LOUNGE_STEP_ONE_TITLE}</h3>
        <p>{LOUNGE_STEP_ONE_TEXT}</p>
      </div>
      <div className="videoSection">
        <div className="videoContent">
          <img src={image} alt="Order lounge service" className="video" />
          <img src={playImage} className="playButton" alt="Play" onClick={() => setShowModalVideo(true)} />
        </div>
      </div>
    </div>
  );
};
