import { memo } from 'react';

const SvgIcon = memo(({ width, height, viewBox, children, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
    >
      {children}
    </svg>
  );
});

export default SvgIcon;
