import React from 'react';

import { INTRO_FLOW_ITEMS, TITLE } from './constants';

import { NavigationControl } from 'components';
import { IntroFlowItem } from 'pages/tours/create/components/IntroFlowItem';

export function Intro() {
  return (
    <div className="content">
      <NavigationControl isBack title="New Order" />
      <div className="dashboardMain">
        <div className="card introMain">
          <div className="caption">
            <h3>{TITLE}</h3>
          </div>
          <div className="intro_items_container">
            {INTRO_FLOW_ITEMS.map((item) => {
              return (
                <IntroFlowItem
                  key={item.id}
                  title={item.title}
                  description={item.description}
                  example_text={item?.example_text}
                  example_array={item?.example_array}
                  navigate_path={item.navigate_path}
                  isExistingFlow={item?.isExistingFlow}
                  isBlankTarget={item.isBlankTarget}
                  isAbsolutePath={item.isAbsolutePath}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
