import { actions } from './store';
import { propertyWebsiteApi } from 'api';

export const getPropertyWebsiteHighlights = () => {
  return async (dispatch) => {
    const { data } =
      await propertyWebsiteApi.getTourPropertyWebsiteHighlights();
    dispatch(actions.setPropertyWebsiteHighlights(data));
    return data;
  };
};

export const getPropertyWebsiteData = ({ tourId }) => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await propertyWebsiteApi.getTourPropertyWebsite({
      tourId,
    });
    dispatch(actions.setWebsiteData(data));
    return data;
  };
};

export const getAllHighlights = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await propertyWebsiteApi.getAllHighlights();
    dispatch(actions.setAllHighlights(data));
  };
};

export const saveOrUpdateHouseDater = ({ tourId, params }) => {
  return async (dispatch) => {
    await propertyWebsiteApi.saveOrUpdateHouseDater({ tourId, params });
    await dispatch(getPropertyWebsiteData({ tourId }));
  };
};

export const updateSalePrice = ({ tourId, params }) => {
  return async (dispatch) => {
    await propertyWebsiteApi.updateSalePrice({ tourId, params });
    await dispatch(getPropertyWebsiteData({ tourId }));
  };
};

export const saveOrUpdateHighlights = ({ tourId, params }) => {
  return async (dispatch) => {
    await propertyWebsiteApi.saveOrUpdateHighlights({ tourId, params });
    await dispatch(getPropertyWebsiteData({ tourId }));
  };
};

export const saveOrUpdateTourDetails = ({ tourId, params }) => {
  return async (dispatch) => {
    await propertyWebsiteApi.saveOrUpdateTourDetails({ tourId, params });
    await dispatch(getPropertyWebsiteData({ tourId }));
  };
};

export const saveOrUpdatePresentation = ({
  tourId,
  params,
  savedSuccessFullyToaster = () => {}
}) => {
  return async (dispatch) => {
    const { data } = await propertyWebsiteApi.saveOrUpdatePresentation({ tourId, params });
    if(data?.success) {
      savedSuccessFullyToaster();
      await dispatch(getPropertyWebsiteData({ tourId }));
    }
  };
};

export const saveOrUpdateNeighborhood = ({ tourId, params }) => {
  return async (dispatch) => {
    await propertyWebsiteApi.saveOrUpdateNeighborhood({ tourId, params });
    await dispatch(getPropertyWebsiteData({ tourId }));
  };
};

export const saveOrUpdateAgentInfo = ({ tourId, params, customHeaders }) => {
  return async (dispatch) => {
    await propertyWebsiteApi.saveOrUpdateAgentInfo({
      tourId,
      params,
      customHeaders,
    });
    await dispatch(getPropertyWebsiteData({ tourId }));
  };
};

export const deleteAgent = ({ agentId, tourId }) => {
  return async (dispatch) => {
    await propertyWebsiteApi.deleteAgent({ agentId });
    await dispatch(getPropertyWebsiteData({ tourId }));
  };
};
