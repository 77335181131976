import { apiManager } from './apiManager';
import { CENTURY, COMPANY_TYPE } from 'constants/singUp';

export const toursApi = {
  getTours: async ({ queryStr }) =>
    await apiManager.get(`/dashboard/tours/tours?${queryStr}`, '', {
      [COMPANY_TYPE]: CENTURY,
    }),
  getLoungeTours: async ({ queryStr }) =>
    await apiManager.get(`/dashboard/tours/content-lounge?${queryStr}`, '', {
      [COMPANY_TYPE]: CENTURY,
    }),
  getTourById: async ({ tourId }) =>
    await apiManager.get(`/dashboard/tours/tours/${tourId}`),
  getPricingCompany: async (params) =>
    await apiManager.get('/dashboard/tours/dashboard-pricing-company', params),
  getCompanyDashboard: async (params) =>
    await apiManager.get(
      '/dashboard/century/tours/century-pricing-company',
      params
    ),
  getServices: async (price) =>
    await apiManager.get(`/dashboard/century/tours/services/${price}`),
  getPackages: async (price) =>
    await apiManager.get(`/dashboard/century/tours/packages/${price ?? 1000}`),
  createTour: async (tour) =>
    await apiManager.post('/dashboard/century/tours', tour),
  createMarketingTour: async (params) =>
    await apiManager.post('/dashboard/century/tours/marketing/store', params),
  goToPayment: async ({ id, is_main_service }) =>
    await apiManager.post(
      `/dashboard/tours/${id}/payment?is_main_service=${
        is_main_service ? 1 : 0
      }`
    ),
  payForCompanyServices: async ({ id }) =>
    await apiManager.post(`/dashboard/tours/${id}/company-service-payment`),
  goToMarketingTourPayment: async ({ id }) =>
    await apiManager.post(`/dashboard/tours/${id}/marketing-payment`),
  updateTour: async ({ tourId, body }) =>
    await apiManager.post(`/dashboard/tours/tours/update/${tourId}`, body),
  updateCompanyServicesPrice: async ({ tourId, body }) =>
    await apiManager.post(
      `/dashboard/tours/company-service/update-price/${tourId}`,
      body,
      { [COMPANY_TYPE]: CENTURY }
    ),
  updateTourServices: async ({ tourId, body }) =>
    await apiManager.post(
      `/dashboard/tours/tours/update-pricing/${tourId}`,
      body
    ),
  getCompanyUsers: async () =>
    await apiManager.get('/dashboard/tours/company-users'),
  getExistingToursToSelect: async () =>
    apiManager.get('/dashboard/tours/delivered-parent'),
  getLoungePackages: async () =>
    await apiManager.get('/dashboard/century/tours/content-lounge-services'),
  createLoungeTour: async (params) =>
    await apiManager.post(
      '/dashboard/century/tours/content-lounge-tour',
      params
    ),
  uploadVideos: async ({ id, body, customHeaders }) =>
    await apiManager.post(
      `/dashboard/century/tours/upload/${id}`,
      body,
      customHeaders
    ),
  uploadVideosChunk: async ({ body, customHeaders }) =>
    await apiManager.post(`/upload/chunk`, body, customHeaders),
  uploadVideosStart: async ({ body }) =>
    await apiManager.post(`/upload/start`, body),
  uploadVideosComplete: async ({ body }) =>
    await apiManager.post(`/upload/complete`, body),
  deleteUploadedVideos: async ({ id, body, customHeaders }) =>
    await apiManager.post(
      `/dashboard/century/tours/delete-files/${id}`,
      body,
      customHeaders
    ),
  getCenturyTourDetails: async ({ id }) =>
    await apiManager.get(`/dashboard/century/tours/content-lounge-tours/${id}`),
  updateCenturyVimeoLinks: async ({ id, params }) =>
    await apiManager.post(
      `/dashboard/century/tours/content-lounge-tour/${id}`,
      params,
      { 'Content-Type': 'multipart/form-data' }
    ),
  getCalendarAddressData: async () =>
    await apiManager.get('/dashboard/century/content-lounge-addresses'),
  getLoungeToursAdmin: async ({ queryStr }) =>
    await apiManager.get(
      `/dashboard/century/tours/content-lounge-tours?${queryStr}`
    ),
  updateCalendarData: async (id, body) =>
    await apiManager.post(
      `/dashboard/century/tours/content-lounge-tour-data/${id}`,
      body
    ),
  confirmUploadedVideos: async (id) =>
    await apiManager.post(`/dashboard/century/tours/confirm-upload/${id}`),
};
