import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';

import { actions } from 'pages/tours/store';
import {
  currentStepSelector,
  stepsDataSelector,
  companyUsersSelector,
} from 'pages/tours/selectors';
import { userSelector } from 'pages/auth/selectors';
import { isBrokerageAdmin } from 'helpers/checkUsersRoles';
import { checkIsPassedTime } from 'helpers/checkIsDatePassed';
import {
  getWarningRow,
  searchExistingToursToSelect,
} from 'pages/tours/create/helpers';
import { TOUR_STATUSES } from 'constants';

import { WarningInfoMessage } from 'components/warningInfoMessage';
import { Select, Input, Textarea, GooglePlacesAutocomplete } from 'components';
import { InputDropdown } from 'components/inputDropdown/inputDropdown';
import { CustomDatePickerInput } from 'components/custom-date-picker-input';

import { POSTAL_CODE_ERROR, POSTAL_CODE_HELPER_TEXT } from 'constants';

// styles
import 'react-datepicker/dist/react-datepicker.css';
import 'scss/components/_date-picker.scss';

export const StepOne = (props) => {
  const { isExistingFlow, existingToursToSelect } = props;

  const [date, setDate] = useState(null);
  const [gMapLoaded, setGMapLoaded] = useState(false);
  const [filteredExistingToursToSelect, setFilteredExistingToursToSelect] =
    useState([]);
  const [existingTourInputValue, setExistingTourInputValue] = useState('');
  const [isExistingTourSelected, setIsExistingTourSelected] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [clickedTour, setClickedTour] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [isClosedToursListingDropdown, setIsClosedTourListingDropdown] =
    useState(false);

  const stepsData = useSelector(stepsDataSelector);
  const usersList = useSelector(companyUsersSelector);
  const currentStep = useSelector(currentStepSelector);
  const user = useSelector(userSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initMap = () => {
    setGMapLoaded(true);
  };

  const validationSchema = yup.object({
    user_id: yup.string().nullable(),
    address: yup.string('Enter your address').required('Address is required'),
    city: yup.string('Enter your city').required('City is required'),
    postal_code: yup
      .string('Enter your post code')
      .required('Post code is required'),
    unit_number: yup.string('Enter your unit number'),
    province: yup
      .string('Enter your province')
      .required('Province is required'),
    instructions: yup.string('Enter your note').nullable(),
  });

  const postalCodeValidation = (postalCode) => {
    const postal_value = postalCode.split('');
    let postal_new_value = '';
    postal_value.forEach((value) => {
      if (value !== ' ') {
        postal_new_value += value;
      }
    });
    return postal_new_value.split('').length === 6;
  };

  const formik = useFormik({
    initialValues: {
      user_id: stepsData.user_id ?? '',
      address: stepsData.address ?? '',
      city: stepsData.city ?? '',
      postal_code: stepsData.postal_code ?? '',
      unit_number: stepsData.unit_number ?? '',
      province: stepsData.province ?? '',
      instructions: stepsData.instructions ?? '',
      date: stepsData.date || date || null,
      parent_id: stepsData.parent_id ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      dispatch(actions.setSelectedPackageSalOption([]));
      if (postalCodeValidation(values.postal_code)) {
        dispatch(
          actions.setStepsData({
            ...values,
            parent_id: parentId,
            date: stepsData.date,
          })
        );
        dispatch(actions.setCurrentStep(currentStep + 1));
      } else {
        setErrors({ postal_code: POSTAL_CODE_ERROR });
      }
    },
  });

  const handleSelect = async (value, placeId) => {
    const splittedValue = value.split(',');
    const country = splittedValue.pop();
    const province = splittedValue.pop();
    const city = splittedValue.pop();
    const address = splittedValue.join(',');
    dispatch(actions.setStepsData({ ...stepsData, address, city, province }));
    formik.setFieldValue('province', province);
    formik.setFieldValue('city', city);
    formik.setFieldValue('address', address);
    formik.setFieldValue('date', date);
    const results = await geocodeByAddress(value);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    formik.setFieldValue('postal_code', postalCode);
  };

  const handleChangeAddress = (value) => {
    formik.setFieldValue('address', value);
  };

  const handleChangeDate = (date) => {
    setDate(date);
    dispatch(actions.setStepsData({ ...stepsData, date }));
  };

  const handleChangeExistingTourInputValue = (e) => {
    setIsExistingTourSelected(false);
    setSelectedTour(null);
    setClickedTour(null);
    setShowWarningMessage(false);
    setIsClosedTourListingDropdown(false);
    setExistingTourInputValue(e.target.value);
  };

  const handleSelectExistingTour = (tour) => {
    setClickedTour(tour);
    if (tour.status !== TOUR_STATUSES.delivered) {
      setIsClosedTourListingDropdown(true);
      setShowWarningMessage(true);
      setSelectedTour(null);
      formik.setFieldValue('province', '');
      formik.setFieldValue('city', '');
      formik.setFieldValue('address', '');
      formik.setFieldValue('date', null);
      formik.setFieldValue('postal_code', '');
      formik.setFieldValue('unit_number', '');
      return;
    }
    setSelectedTour(tour);
    setIsExistingTourSelected(true);
    setIsClosedTourListingDropdown(true);
    formik.setFieldValue('province', tour.province);
    formik.setFieldValue('city', tour.city);
    formik.setFieldValue('address', tour.address);
    formik.setFieldValue('date', tour.date);
    formik.setFieldValue('postal_code', tour.postal_code);
    setParentId(tour.id);
    if (tour.unit_number) {
      formik.setFieldValue('unit_number', tour.unit_number);
    }
    dispatch(actions.setSelectedSqFt(Number(tour.sqft)));
  };

  useEffect(() => {
    initMap();
    setDate(stepsData?.date);
  }, []);

  useEffect(() => {
    setFilteredExistingToursToSelect(
      searchExistingToursToSelect(existingToursToSelect, existingTourInputValue)
    );
  }, [existingToursToSelect, existingTourInputValue]);

  return (
    <>
      {gMapLoaded && (
        <div className="dashboardMain">
          <div className="card">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-5">
                  {isExistingFlow ? (
                    <>
                      <div className="existing_tours_search_container">
                        <h3 className="heading3 step_one_heading3">
                          Select a Listing
                        </h3>
                        <Input
                          name="search_value"
                          id="search_value"
                          placeholder={'Select Your Listing By Address'}
                          searchIcon={true}
                          onChange={(e) =>
                            handleChangeExistingTourInputValue(e)
                          }
                          value={
                            selectedTour?.address ||
                            clickedTour?.address ||
                            existingTourInputValue
                          }
                        >
                          {!!existingTourInputValue &&
                            !isExistingTourSelected &&
                            !isClosedToursListingDropdown && (
                              <InputDropdown
                                handleSelectExistingTour={
                                  handleSelectExistingTour
                                }
                                tours={filteredExistingToursToSelect}
                              />
                            )}
                        </Input>
                      </div>

                      {showWarningMessage && (
                        <WarningInfoMessage
                          text={getWarningRow(
                            clickedTour.id,
                            clickedTour.status
                          )}
                          withIcon={true}
                        />
                      )}
                    </>
                  ) : (
                    isBrokerageAdmin(user) && (
                      <>
                        <h3 className="heading3 step_one_heading3">
                          Assign an agent
                        </h3>
                        <Select
                          customClasses={'step_one_agent_select'}
                          name="user_id"
                          id="user_id"
                          placeholder="Select Agent"
                          value={formik.values.user_id}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.user_id &&
                            Boolean(formik.errors.user_id)
                          }
                          helperText={
                            formik.touched.user_id && formik.errors.user_id
                          }
                          options={usersList?.data?.map((user) => ({
                            label: `${user.name} ${user.last_name} ${user.position}`,
                            value: user.id,
                          }))}
                        />
                      </>
                    )
                  )}
                </div>
                <div className="col-lg-7" />
                <div className="col-lg-12">
                  <h3 className="heading3 step_one_heading3">
                    Property Details & Shooting Date
                  </h3>
                </div>

                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      {isExistingFlow ? (
                        <Input
                          customClasses={isExistingFlow && 'disabled_input'}
                          disabled={isExistingFlow}
                          label="Property Address"
                          name="address"
                          id="address"
                          value={selectedTour?.address}
                        />
                      ) : (
                        <GooglePlacesAutocomplete
                          value={formik.values.address}
                          onChange={handleChangeAddress}
                          onSelect={handleSelect}
                          error={
                            formik.touched.address &&
                            Boolean(formik.errors.address)
                          }
                          helperText={
                            formik.touched.address && formik.errors.address
                          }
                        />
                      )}
                    </div>
                    <div className="col-lg-6">
                      <Input
                        customClasses={isExistingFlow && 'disabled_input'}
                        disabled={isExistingFlow}
                        noPlaceholder={isExistingFlow}
                        id="city"
                        label="City"
                        name="city"
                        placeholder="Enter City"
                        value={selectedTour?.city || formik.values.city}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Input
                        customClasses={`postal_code_margin ${isExistingFlow && 'disabled_input'}`}
                        disabled={isExistingFlow}
                        noPlaceholder={isExistingFlow}
                        id="postal_code"
                        label="Postal Code"
                        name="postal_code"
                        placeholder="Postal Code"
                        value={
                          selectedTour?.postal_code.toUpperCase() ||
                          formik.values.postal_code.toUpperCase()
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.postal_code &&
                          Boolean(formik.errors.postal_code)
                        }
                        helperText={
                          formik.touched.postal_code &&
                          formik.errors.postal_code
                        }
                      >
                        <div
                          className={`format_text ${
                            formik.touched.postal_code &&
                            Boolean(formik.errors.postal_code) && "format_text_sizes"
                          }`}
                        >
                          {POSTAL_CODE_HELPER_TEXT}
                        </div>
                      </Input>
                    </div>
                    <div className="col-lg-6">
                      <Input
                        customClasses={isExistingFlow && 'disabled_input'}
                        disabled={isExistingFlow}
                        noPlaceholder={isExistingFlow}
                        id="unit_number"
                        label="Unit Number"
                        name="unit_number"
                        placeholder="Unit Number"
                        value={
                          selectedTour?.unit_number || formik.values.unit_number
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.unit_number &&
                          Boolean(formik.errors.unit_number)
                        }
                        helperText={
                          formik.touched.unit_number &&
                          formik.errors.unit_number
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <Input
                        customClasses={isExistingFlow && 'disabled_input'}
                        disabled={isExistingFlow}
                        noPlaceholder={isExistingFlow}
                        id="province"
                        label="Province"
                        name="province"
                        placeholder="Enter Province"
                        value={selectedTour?.province || formik.values.province}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.province &&
                          Boolean(formik.errors.province)
                        }
                        helperText={
                          formik.touched.province && formik.errors.province
                        }
                      />
                    </div>
                  </div>
                  <DatePicker
                    selected={date}
                    onChange={(date) => handleChangeDate(date)}
                    showTimeSelect
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    timeInputLabel="time"
                    dateFormat="E, MM/dd/yyyy h:mm aa"
                    minDate={new Date()}
                    filterTime={(date) => checkIsPassedTime(date)}
                    customInput={
                      <CustomDatePickerInput
                        label={
                          <label className="date_picker_label">
                            Requested Date & Time{' '}
                            <span className="red_text">(to be confirmed)</span>
                          </label>
                        }
                        labelText="Requested Date & Time (to be confirmed)"
                        placeholderText="Select date & time"
                      />
                    }
                  />
                </div>
              </div>
              <div className="row align-items-end mb-3">
                <div className="col-lg-6">
                  <Textarea
                    className="mb-0"
                    name="instructions"
                    id="instructions"
                    label="Note for Photographer"
                    placeholder="Write your note here..."
                    value={formik.values.instructions}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.instructions &&
                      Boolean(formik.errors.instructions)
                    }
                    helperText={
                      formik.touched.instructions && formik.errors.instructions
                    }
                  />
                </div>
              </div>
              <div className="text-end footerCta">
                <button
                  className="btn cta-outline-primary"
                  onClick={() => navigate('/order-new-tour')}
                >
                  Back to Selection
                </button>
                <button
                  id="next_button"
                  className="btn cta-primary"
                  type="submit"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
