import React from 'react';

import { DEFAULT_DATE, SELECT_DATE } from 'constants';

// Images
import Calendar from 'assets/images/calendar.svg';
import { PAYMENT_WEBSITE } from 'constants';

export const CustomDatePickerInput = React.forwardRef((props, ref) => {
  const {
    label,
    labelText,
    inputIcon,
    onClick,
    placeholderText,
    value = '',
    onChange = () => {},
    isDisable,
    maxWidth,
    showPresentationError = false
  } = props;
  return (
    <div ref={ref} className="row">
      <div className={maxWidth ? "col-lg-12": "col-lg-7"}>
        <div className="form-group date-picker-input-container">
          {label || (
            <label htmlFor="">{labelText || SELECT_DATE}</label>
          )}
          <div onClick={!isDisable ? onClick : () => {}} className="datePicker">
            <i>
              <img src={inputIcon || Calendar} alt="calendar" />
            </i>
            <input
              type="text"
              className={`form-control ${
                maxWidth ? "width_500" : ""} ${
                  showPresentationError ? "show_error" : ""
                }`
              }
              placeholder={placeholderText || DEFAULT_DATE}
              value={value}
              onChange={onChange}
              disabled={isDisable}
            />
            {showPresentationError && (
              <span className='required_text'>{PAYMENT_WEBSITE.required}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
