import { SpinnerCircular } from 'spinners-react';
import {
  CIRCULAR_PROGRESS_COLOR,
  CIRCULAR_PROGRESS_SIZE,
  CIRCULAR_PROGRESS_TYPE,
} from './constants';

export const CircularProgress = (props) => {
  const { type, size, progressColor, progressSecondColor } = props;
  const color = progressColor || CIRCULAR_PROGRESS_COLOR[type];
  const secondaryColor = progressSecondColor || CIRCULAR_PROGRESS_COLOR.secondaryColor
  const circleSize = CIRCULAR_PROGRESS_SIZE[size];

  return <SpinnerCircular color={color} size={circleSize} secondaryColor={secondaryColor} />;
};
