import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPackages, getServices } from 'pages/tours/actions';

import { StepTwo } from '../components/StepTwo';

export const ExistingStepTwo = (props) => {
  const {
    filteredServices,
    filteredCompanyServices,
    getSubtotal,
    selectedPackage,
    getHST,
    getTotal,
    packages,
  } = props;

  const selectedSqFt = useSelector((state) => state.tours.selectedSqFt);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPackages(selectedSqFt));
    dispatch(getServices({ price: selectedSqFt }));
  }, [selectedSqFt]);

  return (
    <StepTwo
      filteredServices={filteredServices}
      filteredCompanyServices={filteredCompanyServices}
      getSubtotal={getSubtotal}
      selectedPackage={selectedPackage}
      getHST={getHST}
      getTotal={getTotal}
      packages={packages}
      isExistingFlow={true}
    />
  );
};
