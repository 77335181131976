export const sqFt = [
  {
    title: '1000',
    value: 1000,
  },
  {
    title: '1000- 1500',
    value: 1500,
  },
  {
    title: '1500- 2000',
    value: 2000,
  },
  {
    title: '2000- 3000',
    value: 3000,
  },
  {
    title: '3000- 4500',
    value: 4500,
  },
];

export const ADD_FOR_ONLY = 'Add for only';
export const DOLLAR_SYMBOL = '$';
export const CUSTOMIZE = 'Customize';
export const ADD_ONE_TO_INDEX = 1;

export const TOURS = {
  place_order: 'Place the Order',
  finalize_pay: 'Finalize & Pay',
  property_address: 'Property Address',
  city: 'City',
  province: 'Province',
  postal_code: 'Postal Code',
  unit_number: 'Unit Number',
  requested_shooting_date: 'Requested Shooting Date & Time',
  order_summary: 'Order Summary',
  payment_message: 'Payment Message',
  charged_later: 'To be charged later.',
  charged_text:
    '*Due to the nature of this services some details require further information/clarification. These charges will be verified and processed at a later day.',
  more_services: 'More Services',
  price: 'Price',
  not_specified: 'Not specified',
  subtotal: 'Subtotal',
  hst: 'HST',
  hst_percent: '13%',
  total: 'Total',
  default_total: 0,
  fixed_count: 2,
  previous: 'Previous',
  step_two: 2,
  default_country: 'Canada',
};

export const EMPTY_PAGE = {
  no_order_created: 'No Orders Created Yet',
  new_order_desc:
    'Click the “Order New Tour” button to create your first listing.',
  see_work: 'See Our Work',
  order_tour: 'Order New Tour',
};
