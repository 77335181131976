export const OTHER = 'OTHER';
export const ORDER_DATE = 'Order Place Date:';
export const ANALYTICS = 'Go to Analytics';
export const DOWNLOAD_ZONE = 'Download Zone';
export const UPLOAD_VIDEO = 'Upload Video';
export const ORDERED_BY = 'Ordered by:';
export const ORDER_ID = "Order ID:";
export const REQUEST_DATE = 'Requested Shoot Date';
export const SHEDULED_DATE = 'Scheduled Shoot Date';
export const UNIT_NUMBER = "unit number:";
export const PROPERTY_WEBSITE = "Property Website";
export const LOUNGE_BOOKING_DATE = "Content Lounge Booking Date";
export const LOUNGE_TOUR_VIDEO_URL =
  'https://player.vimeo.com/progressive_redirect/playback/908783540/rendition/1080p/file.mp4?loc=external&log_user=0&signature=0cca80f43593c2dfbe6709462021bbce663ffee5181022f0eea00f36c1f8288e';
