export const UPLOAD = 'Upload';
export const DRAG_TEXT = 'Click or drag file to this area to upload';
export const SUPPORT_TEXT =
  'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files';
export const SAVE = 'Save';
export const CANCEL = 'Cancel';
export const HUNDRED_PERCENT = 100;
export const PROGRESS_BAR = {
  dasharray: 310,
  dashoffset: '251',
  frequentative: 0.6,
};
export const UPLOADED_FILES = 'uploaded_files';
export const GOOGLE_DRIVE_URL =
  'https://drive.google.com/uc?export=download&id=';
export const IMAGE = 'image';
export const IPHONE = 'iPhone';
export const FILE = 'file';
export const UPLOAD_ID = 'upload_id';
export const CHUNK_NUMBER = 'chunk_number';
export const VIDEO = 'video';
export const PREVIEW_IMAGES_KEY = 'preview_images[]';
