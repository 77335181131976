import {
  HD,
  BIG_SCREEN_SIZE,
  MIDDLE_SCREEN_SIZE,
  LOW_HD_SCREEN_SIZE,
  MIDDLE_SECOND_SCREEN_SIZE,
  HIGH_SD_SCREEN_SIZE,
  SD,
  MIDDLE_SD_SCREEN_SIZE,
  LOW_SD_SCREEN_SIZE,
} from './constants';
import { downloadZoneApi } from 'api';

export const getVimeoById = (videoLink = '', setDownloadedUrl) => {
  const getVideoByQuality = (data) => {
    setDownloadedUrl(data);
  };

  downloadZoneApi.getVideoIdByUrl(videoLink).then((res) => {
    if (Array.isArray(res?.data)) {
      const videos = {
        HD: [
          { size: BIG_SCREEN_SIZE, quality: HD, video: null },
          { size: MIDDLE_SCREEN_SIZE, quality: HD, video: null },
          { size: MIDDLE_SECOND_SCREEN_SIZE, quality: HD, video: null },
          { size: LOW_HD_SCREEN_SIZE, quality: HD, video: null },
        ],
        SD: [
          { size: HIGH_SD_SCREEN_SIZE, quality: SD, video: null },
          { size: MIDDLE_SD_SCREEN_SIZE, quality: SD, video: null },
          { size: LOW_SD_SCREEN_SIZE, quality: SD, video: null },
        ],
      };

      for (const quality in videos) {
        for (const videoData of videos[quality]) {
          videoData.video = res.data.find(
            (video) =>
              video.quality === videoData.quality &&
              video.width === videoData.size
          );
          if (videoData.video) {
            getVideoByQuality(videoData.video, res.data);
            return;
          }
        }
      }

      const data = res.data.sort((a, b) => b.width - a.width)[0];
      setDownloadedUrl(data);
    }
  });
};
