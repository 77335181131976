import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from 'components/button';
import { Toastify } from 'hooks';
import { SAVE_CHANGES } from 'pages/tours/detail/constants';
import { updateCenturyVimeoLinks } from 'pages/tours/actions';
import { TOAST_TYPES, TOAST_MESSAGES } from 'constants';

const SaveVimeoLinks = ({ status, changedVimeoLinks, setLoading }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleSaveVimeoLinks = () => {
    setLoading(true);
    const formData = new FormData();
    changedVimeoLinks.current?.forEach((item) => {
      formData.append('vimeo_links[]', item);
    });
    formData.append('status', status);

    dispatch(updateCenturyVimeoLinks({ id, params: formData}))
    .then(() => {
      Toastify({
        type: TOAST_TYPES.success,
        message: TOAST_MESSAGES.savedChanges,
      });
    })
    .finally(() => setLoading(false))
  }

  return (
    <div className='admin_save_btn_field'>
      <Button
        customClasses="admin_save_btn"
        text={SAVE_CHANGES}
        onClick={handleSaveVimeoLinks}
      />
    </div>
  )
}

export default SaveVimeoLinks;