export const HD = 'hd';
export const SD = 'sd';
export const BIG_SCREEN_SIZE = 1920;
export const MIDDLE_SCREEN_SIZE = 1280;
export const MIDDLE_SECOND_SCREEN_SIZE = 1080;
export const LOW_HD_SCREEN_SIZE = 720;
export const HIGH_SD_SCREEN_SIZE = 540;
export const MIDDLE_SD_SCREEN_SIZE = 360;
export const LOW_SD_SCREEN_SIZE = 240;
export const EMPTY_LENGTH = 0;
