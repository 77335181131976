export const infoMessage =
  'A temporary hold will be placed on the payment card you have selected, securing the amount for processing. Once the content is delivered, an email will be sent to you containing detailed instructions and a download link. At that point, the payment will be processed and finalized. Thank you for your patience and cooperation.';

export const ADD_PACKAGES = 'Add Package/Services';
export const FINALIZE_PAY = 'Finalize & Pay';
export const LOUNGE_TITLE = 'Title';
export const LOUNGE_INFO_TEXT =
  'Not sure which service to choose? Check out our works';
export const EXPLORE_OUR_WORK = 'Explore our works';
export const NEW_ORDER = 'New Order';

export const LOUNGE_STEP_ONE_TITLE = 'How do Content Lounge Services work?';
export const LOUNGE_STEP_ONE_TEXT =
  "It's incredibly easy! We have meticulously designed and prepared three top-notch filming spaces in Brampton, Orangeville, and Collingwood. These stylishly decorated lounges provide the perfect setting for filming short, relevant video content that is crucial for today's social media strategies. Moreover, we have equipped each lounge with a professional filming setup and curated an extensive list of sample video ideas. Each idea comes with clear instructions and sample scripts, allowing our agents to simply walk in, film their part, and upload the files to our portal. Our dedicated post-production team takes care of the professional editing process, ensuring a polished end result. Creating recurring, impactful video content tailored for social media has never been more accessible or straightforward than with our services.";
export const ADD_BTN = 'Add';
export const ADDED_BTN = 'Added';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const PAYABLE_NOW = 'Payable now';
export const ADD_ONS = 'Add-ons';
export const ADDITIONAL_SERVICES = 'Additional Services';
export const MORE_SERVICES = 'More Services';
export const STEP_ONE = 1;
export const STEP_TWO = 2;
export const LOUNGE_STEP_TWO_TITLE = 'Finalize & Pay';
export const SELECT_DATE = 'Select Date & Time';
export const SELECT_LOCATION = 'Select Location';
export const START_DATE = '3:30pm';
export const END_DATE = '4:30pm';
export const LOUNGE_INFO_MESSAGE =
  'A temporary hold will be placed on the payment card you have selected, securing the amount for processing. Once the content is delivered, an email will be sent to you containing detailed instructions and a download link. At that point, the payment will be processed and finalized. Thank you for your patience and cooperation.';
export const PAYMENT_MESSAGE = 'Payment Message';
export const PAY = 'Pay $';
export const THIRTEEN_PERCENT = 13;
export const HUNDRED_PERCENT = 100;
export const RETURNED_COUNT = 2;
export const PACKAGE = 'Package';
export const PRICE = 'Price';
export const NOT_SPECIFIED = 'Not specified';

export const ORDER_SUMMARY = {
  customize_service: 'Customize Service',
  package: 'Package',
  price: 'Price',
  default_price: 0,
  add_ons: 'Add-ons',
  fixed_count: 2,
  hundred_percent: 100,
  hst_percent: 13,
  single_element_count: 1,
  title: 'Order Summary',
  package_total: 'Package Total',
  subtotal: 'Subtotal',
  hst: 'HST',
  hst_per: '13%',
  total: 'Total',
  pay: 'Pay',
};

export const STEP_TWO_CONSTANTS = {
  add_service_package: 'Add Packages / Services',
  property_sqft: 'Property Sq/ft',
  sq_ft: 'SQ/FT',
  select_sq_ft:
    'Select SQ/FT range. Please select the square footage of your Listing in order to see the Pricing.',
  note_to_select: 'Note: The selected range must include finished basements.',
  select_package: 'Select package, or skip to à la carte add-ons/services',
  add_ons_desc:
    'Add-ons: Customize your selected package by adding any of our add-ons to your order.',
  more_services: 'More Services',
  to_be_charged: 'To be charged later.',
  charged_later_desc:
    '*Due to the nature of this services some details require further information/clarification. These charges will be verified and processed at a later day.',
  price: 'Price',
  not_specified: "'Not specified'",
  subtotal: 'Subtotal',
  hst: 'HST',
  hst_percent: '13%',
  total: 'Total',
  previous: 'Previous',
  next: 'Next',
};

export const PACKAGES = {
  select_sqft: 'Select sq/ft',
};
