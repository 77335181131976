export const BUTTON_TYPES = {
  main_light: 'btn_main_light',
  main_dark: 'btn_main_dark',
  edit: 'edit',
  save: 'save',
  cancel: 'cancel',
  forward: 'forward',
  main_save: 'main_save',
  back: 'back',
  download_dark: 'btn_download_dark',
  lounge_download: "btn_lounge_download"
};
