import { createSelector } from '@reduxjs/toolkit';

const toursRootSelector = createSelector(
  ({ tours }) => tours,
  (tours) => tours
);

export const toursListSelector = createSelector(
  toursRootSelector,
  ({ list }) => list
);

export const pricingCompanyListSelector = createSelector(
  toursRootSelector,
  ({ pricingCompanyList }) => pricingCompanyList
);

export const stepsDataSelector = createSelector(
  toursRootSelector,
  ({ steps }) => steps
);

export const currentStepSelector = createSelector(
  toursRootSelector,
  ({ currentStep }) => currentStep
);

export const packagesSelector = createSelector(
  toursRootSelector,
  ({ packages }) => packages
);

export const servicesSelector = createSelector(
  toursRootSelector,
  ({ services }) => services
);

export const companyServicesSelector = createSelector(
  toursRootSelector,
  ({ companyServices }) => companyServices
);

export const selectedSqFtSelector = createSelector(
  toursRootSelector,
  ({ selectedSqFt }) => selectedSqFt
);

export const selectedServicesSelector = createSelector(
  toursRootSelector,
  ({ selectedServices }) => selectedServices
);

export const selectedCompanyServicesSelector = createSelector(
  toursRootSelector,
  ({ selectedCompanyServices }) => selectedCompanyServices
);

export const selectedPackageSelector = createSelector(
  toursRootSelector,
  ({ selectedPackage }) => selectedPackage
);

export const selectedPackageServiceIdsSelector = createSelector(
  toursRootSelector,
  ({ selectedPackageServiceIds }) => selectedPackageServiceIds
);

export const companyUsersSelector = createSelector(
  toursRootSelector,
  ({ companyUsers }) => companyUsers
);

export const loungePackagesSelector = createSelector(
  toursRootSelector,
  ({ loungePackages }) => loungePackages
);

export const selectedLoungePackage = createSelector(
  toursRootSelector,
  ({ selectedLoungePackage }) => selectedLoungePackage
);

export const loungeStepsData = createSelector(
  toursRootSelector,
  ({ loungeStepsData }) => loungeStepsData
);

export const loungeCurrentStepsSelector = createSelector(
  toursRootSelector,
  ({ loungeCurrentSteps }) => loungeCurrentSteps
);

export const calendarAddressDataSelector = createSelector(
  toursRootSelector,
  ({ calendarAddressData }) => calendarAddressData
);

export const calendarDataSelector = createSelector(
  toursRootSelector,
  ({ calendarData }) => calendarData
);

export const salesOptionsSelector = createSelector(
  toursRootSelector,
  ({ salesOptions }) => salesOptions
);

export const selectedPackageSalOptionSelector = createSelector(
  toursRootSelector,
  ({ selectedPackageSalOption }) => selectedPackageSalOption
);

export const disabledServicesSelector = createSelector(
  toursRootSelector,
  ({ disabledServices }) => disabledServices
);
