import fileIcon from 'assets/images/file.svg';
import addIcon from 'assets/images/add.svg';
import { EMPTY_PAGE } from '../constants';
import { useNavigate } from 'react-router-dom';
import emptyBackground from 'assets/images/emptyBackground.png';

export const EmptyPage = ({ isLounge }) => {
  const navigation = useNavigate();

  const routeOurWork = () => {
    navigation('/our-work');
  };

  const routeOrderTours = () => {
    navigation('/order-new-tour');
  };

  return (
    <div className="empty_page">
      <div className="empty_page_container">
        <div className="background_image">
          <img src={emptyBackground} alt="Background" className="image" />
          <div className="empty_page_content">
            <div className="icon_container">
              <img src={fileIcon} alt="File" className="file_icon" />
            </div>
          </div>
        </div>
        <div className="no_order_container">
          <h4 className="empty_title">{EMPTY_PAGE.no_order_created}</h4>
          <p className="empty_description">{EMPTY_PAGE.new_order_desc}</p>
          <div className="actions_field">
            <button
              className="see_our_work_btn empty_btn"
              onClick={routeOurWork}
            >
              {EMPTY_PAGE.see_work}
            </button>
            <button
              className="order_new_tour_btn empty_btn"
              onClick={routeOrderTours}
            >
              <img src={addIcon} alt="add icon" />
              {EMPTY_PAGE.order_tour}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
