import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import closeImg from '../../images/closeImg.svg';
import './style.scss';

function VideoModal({ close, show, value, customClass = '', showIframe }) {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  const handleClose = () => {
    close(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <div
      className={`${show ? 'modal_cover_section_of_video' : 'hide_modal'}
       priority_high
      }`}
    >
      <div className="video_modal_section">
        <div className={`${customClass} video_of_modal_section`}>
          <div className="button_section">
            <div className="close_section">
              <img src={closeImg} onClick={() => handleClose()} alt="Close" />
            </div>
          </div>
          <div className={`video_grabber remove_padding`}>
            {showIframe ? (
              <div className="video_container">
                <video
                  src={value}
                  className="modal_iframe"
                  controls
                  autoPlay
                ></video>
              </div>
            ) : (
              <ReactPlayer url={value} controls className="modal_iframe" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoModal;
