import { StepThree } from '../components/StepThree';

export const ExistingStepThree = (props) => {
  const {
    filteredServices,
    filteredCompanyServices,
    getSubtotal,
    selectedCompanyServices,
    selectedPackageServiceIds,
    selectedServices,
    selectedPackage,
    getHST,
    getTotal,
    packages,
    chosenOptionsObj,
  } = props;

  return (
    <StepThree
      filteredServices={filteredServices}
      filteredCompanyServices={filteredCompanyServices}
      getSubtotal={getSubtotal}
      selectedCompanyServices={selectedCompanyServices}
      selectedPackageServiceIds={selectedPackageServiceIds}
      selectedServices={selectedServices}
      selectedPackage={selectedPackage}
      getHST={getHST}
      getTotal={getTotal}
      packages={packages}
      chosenOptionsObj={chosenOptionsObj}
      isExistingFlow={true}
    />
  );
};
