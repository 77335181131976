import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import downloadAsZip from '../../global/download-zip/download-zip';
import { actions as downloadZoneActions } from 'pages/download-zone/newStore';

import './style.scss';

const DownloadButtonPopup = ({
  isDownloaded,
  links,
  setIsDownloaded,
  secondLink,
  thirdLink,
  data,
  sectionNameFirst,
  sectionNameSecond,
  sectionNameThird,
  downloadTypeFirst,
  downloadTypeSecond,
  downloadTypeThird,
  folderNameFirst,
  folderNameSecond,
  section,
  filesTypeCount,
  setIsNotificationShown = () => {},
  bothVisibility = true,
}) => {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const dispatch = useDispatch();

  const createNotificationElement = () => {
    setIsNotificationShown(true);
  };

  const deleteNotificationElement = () => {
    setIsNotificationShown(false);
    setMouseX(0);
    setMouseY(0);
  };

  const setNotificationElementCoordinates = (event) => {
    const notificationElement = document.getElementById(
      'notification_element_for_download_button'
    );
    setMouseX(event.clientX - 10);
    setMouseY(window.scrollY - window.innerHeight + event.clientY + 30);
    if(notificationElement) {
      notificationElement.style.left = `${mouseX}px`;
      notificationElement.style.top = `${mouseY}px`;
    }
  };

  useEffect(() => {
    const downloadNotificationButton = document.getElementsByClassName(
      'download_button_popup_download_btn  download_button_popup_download_btn_opacity'
    );
    const HTMLCollectionArray = [...downloadNotificationButton];
    HTMLCollectionArray.forEach((elem) => {
      elem.addEventListener('mouseenter', createNotificationElement);
      elem.addEventListener('mouseleave', deleteNotificationElement);
    });

    return () => {
      HTMLCollectionArray.forEach((elem) => {
        elem.removeEventListener('mouseenter', createNotificationElement);
        elem.removeEventListener('mouseover', deleteNotificationElement);
      });
    };
  }, []);

  useEffect(() => {
    const downloadNotificationButton = document.getElementsByClassName(
      'download_button_popup_download_btn  download_button_popup_download_btn_opacity'
    );
    const HTMLCollectionArray = [...downloadNotificationButton];
    HTMLCollectionArray.forEach((elem) => {
      elem.addEventListener('mousemove', setNotificationElementCoordinates);
    });
    return () => {
      HTMLCollectionArray.forEach((elem) => {
        elem.removeEventListener(
          'mousemove',
          setNotificationElementCoordinates
        );
      });
    };
  }, [mouseX, mouseY]);

  return (
    <div className="download_button_popup_download_btn_wrapper">
      <button
        className={`down_zone_main_btn download_button_popup_download_btn ${
          !!isDownloaded ? 'download_button_popup_download_btn_disable' : ''
        } ${!links.length ? 'download_button_popup_download_btn_opacity' : ''}`}
        disabled={!!isDownloaded ? true : false}
      >
        DOWNLOAD
        <div className="download_button_popup_liner_border"></div>
      </button>
      <div
        className={
          links.length
            ? 'download_button_popup_dowload_section'
            : 'download_button_popup_hide'
        }
      >
        <div
          className={`download_button_popup_download_options ${
            !!isDownloaded && 'download_button_popup_download_options_hide'
          }`}
        >
          {!!links.length && (
            <span
              onClick={() => {
                dispatch(
                  downloadZoneActions.setDownloadFileType(
                    `${section} ${folderNameFirst}`
                  )
                );
                downloadAsZip(links, setIsDownloaded, sectionNameFirst);
              }}
            >
              {downloadTypeFirst}
            </span>
          )}
          {!!links.length && !!data?.[1]?.files?.length && (
            <span
              onClick={() => {
                dispatch(
                  downloadZoneActions.setDownloadFileType(
                    `${section} ${folderNameSecond}`
                  )
                );
                downloadAsZip(secondLink, setIsDownloaded, sectionNameSecond);
              }}
            >
              {downloadTypeSecond}
            </span>
          )}
          {!!links.length && !!data?.[1]?.files?.length && bothVisibility && (
            <span
              onClick={() => {
                dispatch(
                  downloadZoneActions.setDownloadFileType(`${section} Both`)
                );
                downloadAsZip(
                  thirdLink,
                  setIsDownloaded,
                  sectionNameThird,
                  secondLink,
                  filesTypeCount,
                  folderNameFirst,
                  folderNameSecond
                );
              }}
            >
              {downloadTypeThird}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DownloadButtonPopup;
