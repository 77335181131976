import { TOUR_STATUSES } from 'constants';

// Images
import ArrowUp from 'assets/images/arrow-up.svg';
import ArrowDown from 'assets/images/arrow-down.svg';
import ArrowDownBlue from 'assets/images/arrow-down-blue.svg';
import ArrowUpBlue from 'assets/images/arrow-up-blue.svg';
import { Link } from 'react-router-dom';

export const checkLabelsDropdownState = ({ isSelected, isOpen }) => {
  return isSelected
    ? isOpen
      ? ArrowUp
      : ArrowDown
    : isOpen
    ? ArrowUpBlue
    : ArrowDownBlue;
};

export const emptyTourStepsSelection = (dispatch, actions) => {
  dispatch(actions.setSelectedCompanyServices([]));
  dispatch(actions.setSelectedOtherServices([]));
  dispatch(actions.setSelectedServices([]));
  dispatch(actions.setSelectedPackage([]));
  dispatch(actions.setSelectedPackageServiceIds([]));
  dispatch(actions.setSelectedSqFt(0));
  dispatch(actions.setSalesOptions([]));
};

export const searchExistingToursToSelect = (tours, searchValue) => {
  const values = searchValue.split(' ');
  const resTours = tours?.filter((tour) => {
    return values?.every((val) =>
      tour?.address.toLowerCase().includes(val.toLowerCase())
    );
  });
  return resTours;
};

export const getWarningRow = (id, status) => {
  if (status === TOUR_STATUSES.new) {
    return (
      <>
        {'Selected listing status is “Requested”. You can edit it from '}
        <Link className="warning_text_link" to={`/tour-details/${id}`}>
          {'details'}
        </Link>
        .
      </>
    );
  } else if (status === TOUR_STATUSES.scheduled) {
    return 'Selected listing status is “Scheduled”. For adding services please contact with your agent.';
  }
};

export const giveAffordableSaleOption = ({
  serviceIds,
  selectedPackageSalOption,
  dispatch,
  actions,
  salesOptions
}) => {
  selectedPackageSalOption?.forEach((element) => {
    let isOptionAffordable = true;

    element?.century_pricings?.forEach((item) => {
      if (serviceIds?.find((service) => service === item?.id)) {
        return
      } else {
        isOptionAffordable = false;
      }
    })

    if (isOptionAffordable) {
      dispatch(actions.setSalesOptions([...salesOptions, element]))
    }
  })
};