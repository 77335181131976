import { useEffect, useState } from 'react';

import { Button } from 'components/button';
import { getVimeoById } from './getVideosById/getVideos';
import { downloadFile } from '../DownloadButton/DownloadFile';
import {
  LEARN_MORE,
  QUANTITY,
  SIZE,
  DOWNLOAD_VIDEO,
  SUBTITLE,
  DESCRIPTION,
  DEFAULT_SIZE,
} from 'constants/loungeTour';
import { BUTTON_TYPES } from 'components/button/constants';

import './style.scss';

import DotIcon from 'assets/images/dot.svg';
import phone from 'assets/images/Frame.png';
import playButton from 'assets/images/play-button.svg';
import VideoModal from '../video-modal';

const LoungeDownloadBody = ({ data, quantity }) => {
  const [downloadedUrl, setDownloadedUrl] = useState({});
  const [showModalVideo, setShowModalVideo] = useState(false);

  const handlePlayVideo = () => {
    setShowModalVideo(!showModalVideo);
  };

  useEffect(() => {
    getVimeoById(data.link, setDownloadedUrl);
  }, []);

  return (
    <div className="lounge_download_component">
      <h2 className="package_title">{data?.title}</h2>
      <div className="about_package">
        <div className="quantity_field">{`${QUANTITY} ${quantity}`}</div>
        <img src={DotIcon} alt="Dot" />
        <div className="size_filed">{`${SIZE} ${
          downloadedUrl?.size_short || DEFAULT_SIZE
        }`}</div>
      </div>
      {showModalVideo && (
        <VideoModal
          close={setShowModalVideo}
          show={showModalVideo}
          value={data.link}
          customClass="video_modal_center"
        />
      )}
      <div className="download_content">
        <div className="loungeVideo" onClick={handlePlayVideo}>
          <img
            src={data?.preview_image || phone}
            alt="Phone"
            className="posterImage"
          />
          <img src={playButton} alt="Play" className="play_button" />
        </div>
        <div className="content_text_section">
          <div className="size_filed size_field_text">{`${SIZE} ${
            downloadedUrl?.size_short || DEFAULT_SIZE
          }`}</div>
          <h3 className="download_lounge_subtitle">{SUBTITLE}</h3>
          <p className="download_lounge_description">{DESCRIPTION}</p>
          <a href="#" className="download_learn_more">
            {LEARN_MORE}
          </a>
          <div className="lounge_download_btn">
            <Button
              text={DOWNLOAD_VIDEO}
              type={BUTTON_TYPES.lounge_download}
              disabled={!downloadedUrl?.link}
              onClick={() => downloadFile([downloadedUrl?.link])}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoungeDownloadBody;
