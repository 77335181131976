import { apiManager } from './apiManager';

export const downloadZoneApi = {
  getContent: async (id) => {
    return await apiManager.get(`/api/downloads/center/${id}`);
  },
  getVideosById: async (id) =>
    await this.getRequest(`/api/vimeo/download-links/${id}`),
  getCenturyDownloadData: async (id) => 
    await apiManager.get(`/downloads/center/century/${id}`),
  getVideoIdByUrl: async (link) => 
    await apiManager.get(`/vimeo/download-links-by-url?url=${link}`),
};
