export const INTRO_FLOW_ITEMS = [
  {
    id: 1,
    navigate_path: '/tour-steps',
    title: 'Order Services For New Listings',
    description:
      'Everything you need to make your Listings shine online from Realtor.ca to Social media platforms and Print marketing.',
    example_text:
      'Still photography ,Cinematic video, 3D Matterport, Agent Intro, Floor plans, Instagram Reels, Drone videography/photography, Twilight photography, Brochures, Feature sheets, Postcards.',
  },
  {
    id: 2,
    navigate_path: '/tour-steps',
    title: 'Order Services For Existing Listings',
    description:
      'If your Listing already exists in the system, please follow this link to order additional services and reshoots.',
    example_text:
      'Still photography ,Cinematic video, 3D Matterport, Agent Intro, Floor plans, Instagram Reels, Drone videography/photography, Twilight photography, Brochures, Feature sheets, Postcards.',
    isExistingFlow: true,
  },
  {
    id: 3,
    navigate_path: '/lounge-steps',
    title: 'Content Lounge Services',
    description:
      'Harness the potential of social media with our comprehensive solution. We provide ready-to-go video concepts specifically tailored for filming in our dedicated video lounge. Our expert marketing team will skillfully edit the footage, resulting in captivating and professional videos. Empower your social media content strategy effortlessly, creating high-quality videos with minimal effort and at a fraction of the typical cost.',
    example_array: [
      "Busting Real Estate Myths",
      "Market Update: Insights into Real Estate Trends",
      "Seller's Guide: Preparing Your Home for Listing",
      "Quick and Easy Home Staging Tips",
      "Quick Tips for First-Time Homebuyers",
      "Multiple Offers Explained",
      "What to Expect When Closing Your First Property",
      "Must-Do Property Fixes to Increase Resale Value",
      "Should You Sell First Then Buy or Vice Versa?",
      "5 Things You Probably Didn't Know About Pre-Construction Condos",
      "Top 5 Listings in Your Target Area Monthly",
      "Investment Property Secrets: Maximizing Returns",
      "Home Buying Mistakes to Avoid",
      "Interior Design Hacks to Transform Your Space",
      "Real Estate Negotiation Tips: Getting the Best Deal",
      "Renting vs. Buying: Making the Right Decision",
      "Navigating the Mortgage Process: Tips for First-Time Homebuyers",
      "Creating a Budget for Homeownership: Financial Planning",
      "Unlocking Hidden Gems: Exploring Unique Properties",
      "Real Estate Investing 101: Building Wealth through Property",
    ]
  },
];

export const SEE_MORE_LENGTH = 100;
export const SEE_MORE_ARRAY_LENGTH = 2;
export const SEE_MORE_DESC_LENGTH = 153;
export const  SEE_LESS = "See Less";
export const  SEE_MORE = "See More";
export const EXAMPLE = "Example";
export const TYPE_STRING = "string";
export const TITLE = "Please Select the Order Type";
