import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import DatePicker from 'react-datepicker';

import {
  getPackages,
  getServices,
  getTourById,
  updateTour,
  updateTourServices,
  payForCompanyServices,
} from '../actions';
import { actions } from '../store';
import {
  types,
  TOUR_STATUSES,
  TOAST_TYPES,
  TOAST_MESSAGES,
  RESPONSE_STATUSES,
  CONTROL_BAR_PAGES,
} from 'constants';
import {
  FOOTER_ELEMENTS_DIRECTIONS,
  MODAL_TYPES,
} from 'components/modal/constants';
import { BUTTON_TYPES } from 'components/button/constants';
import { sqFt, ADD_ONE_TO_INDEX } from 'pages/tours/constants';
import {
  dateWithDashesSpaceFullHours24,
  dayMonthYearHoursMinutesAMSpacesFormatter,
  dayMonthYearSpaces,
  getTimeAMPM,
  getWeekDayByMoment,
} from 'helpers/momentHelpers';
import { isBrokerageAdmin } from 'helpers/checkUsersRoles';
import {
  CIRCULAR_PROGRESS_SIZE_NAMES,
  CIRCULAR_PROGRESS_TYPES,
} from 'components/circularProgress/constants';
import { SECTIONS, TOUR_DETAILS } from './constants';
import { Toastify } from 'hooks';

import {
  CircularProgress,
  ControlBar,
  ModalComponent,
  NavigationControl,
  ServiceLabel,
} from 'components';
import { Button } from 'components/button';
import { ServicesOrderSummary } from 'components/servicesOrderSummary';
import { CustomDatePickerInput } from 'components/custom-date-picker-input';
import { AddressSection } from './components/addressSection';
import { PackageItem } from '../create/new-steps/components/packageItem';
import { AgentNoImage } from 'components/agentNoImage';
import { OrderSummary } from '../create/new-steps/components/order-summary';
import { changePrice } from 'helpers/changePrice';
import {
  salesOptionsSelector,
  selectedPackageSalOptionSelector,
} from '../selectors';

// Images
import HomeIcon from 'assets/images/home.svg';
import { giveAffordableSaleOption } from '../create/helpers';

const base_url = process.env.REACT_APP_IMAGE_BASE_URL;

const fixedPercentAmount = 0;
const fixedPercentForTotalPrice = 13;

export function TourDetails() {
  const tourDetails = useSelector((state) => state.tours.tourDetails);
  const salesOptions = useSelector(salesOptionsSelector);
  const selectedPackageSalOption = useSelector(
    selectedPackageSalOptionSelector
  );

  const [date, setDate] = useState(null);
  const [optionServiceIds, setOptionServiceIds] = useState([]);
  const [tourAddress, setTourAddress] = useState('');
  const [editingFieldsState, setEditingFieldsState] = useState({
    address: { status: false, loading: false, value: '' },
    date: { status: false, loading: false, value: '' },
    instructions: { status: false, loading: false, value: '' },
    services: {
      status: false,
      loading: false,
      value: {
        services: [],
        company_services: [],
        sqft: '',
        package: {},
      },
    },
  });
  const [addressDetails, setAddressDetails] = useState({
    unit_number: '',
    street: '',
    city: '',
    province: '',
    postal_code: '',
  });
  const [onlyAddress, setOnlyAddress] = useState(
    tourDetails?.address?.split(',')?.slice(0, -3)?.join(',') || null
  );
  const [localSelectedServices, setLocalSelectedServices] = useState([]);
  const [localSelectedCompanyServices, setLocalSelectedCompanyServices] =
    useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoadingPayCompanyServices, setIsLoadingPayCompanyServices] =
    useState(false);
  const [selectedCompanyServices, setSelectedCompanyServices] = useState(
    tourDetails?.company_services
  );

  const packages = useSelector((state) => state.tours.packages);
  const services = useSelector((state) => state.tours.services);
  const companyServices = useSelector((state) => state.tours.companyServices);

  const params = useParams();

  const { state } = useLocation();

  const navigate = useNavigate();

  const isEditingDisabled = tourDetails.status !== TOUR_STATUSES.new;

  const formattedTourCreationDate = dayMonthYearHoursMinutesAMSpacesFormatter(
    tourDetails.created_at
  );
  const formattedTourShootingDate = `${getWeekDayByMoment(
    tourDetails.date
  )}, ${dayMonthYearSpaces(tourDetails.date)}`;
  const formattedShootingTime = getTimeAMPM(tourDetails.date);

  const alreadyChargedSubtotal =
    localSelectedServices?.reduce(
      (acc, item) => Number(acc) + Number(item?.price || 0),
      0
    ) + Number(tourDetails?.package?.price || 0);
  const alreadyChargedSubtotalPercentPrice =
    ((alreadyChargedSubtotal || 0) * fixedPercentForTotalPrice) / 100;
  const alreadyChargedTotal =
    Number(alreadyChargedSubtotal) + Number(alreadyChargedSubtotalPercentPrice);

  const dispatch = useDispatch();

  const handleMakeEditingFieldDefaultState = (name) => {
    setEditingFieldsState({
      ...editingFieldsState,
      [name]: {
        ...editingFieldsState[name],
        status: false,
        loading: false,
      },
    });
  };

  const handleClickEdit = ({ name }) => {
    if (tourDetails.status !== TOUR_STATUSES.new) {
      setIsModalOpened(true);
      return;
    }
    setEditingFieldsState({
      ...editingFieldsState,
      [name]: { ...editingFieldsState[name], status: true },
    });
  };

  const setInitialEditingFieldsState = (name) => {
    const getSectionEditStatus = (sectionName) => {
      return name === sectionName
        ? !editingFieldsState[name].status
        : editingFieldsState[sectionName].status;
    };
    setEditingFieldsState((prev) => ({
      address: {
        ...prev.address,
        value: tourDetails?.address?.split(',')?.[0],
        status: getSectionEditStatus(SECTIONS.address),
      },
      date: {
        ...prev.date,
        value: dayMonthYearHoursMinutesAMSpacesFormatter(tourDetails.date),
        status: getSectionEditStatus(SECTIONS.date),
      },
      instructions: {
        ...prev.instructions,
        value: tourDetails.instructions,
        status: getSectionEditStatus(SECTIONS.instructions),
      },
      services: {
        ...prev.services,
        status: getSectionEditStatus(SECTIONS.services),
        value: {
          services: tourDetails?.service?.map((serv) => ({
            id: serv?.id,
            price: serv?.price,
            title: serv?.title,
          })),
          company_services: tourDetails.company_services,
          sqft: tourDetails.sqft,
          package: tourDetails.package,
        },
      },
    }));
  };

  const handleClickCancel = ({ name }) => {
    setInitialEditingFieldsState(name);
    setLocalSelectedCompanyServices(
      tourDetails.company_services.map((item) => item.id)
    );
    setLocalSelectedServices(
      tourDetails.service.map((item) => ({ id: item.id, price: item.price }))
    );
  };

  const handleChangeTourDetails = ({ name, value }) => {
    setEditingFieldsState({
      ...editingFieldsState,
      [name]: { ...editingFieldsState[name], value },
    });
  };

  const handleChangeTourDetailsDate = (value) => {
    setDate(value);
    setEditingFieldsState({
      ...editingFieldsState,
      date: { ...editingFieldsState.date, value },
    });
  };

  const getSaleOptionsPrice = useMemo(() => {
    const price = salesOptions?.length
      ? salesOptions?.reduce(
          (acc, option) => Number(acc) + Number(option?.price),
          0
        )
      : 0;
    return price;
  }, [salesOptions]);

  const handleClickSave = ({ name }) => {
    const {
      instructions,
      date,
      address,
      city,
      province,
      postal_code,
      unit_number,
    } = tourDetails;
    const localUnitNumber = addressDetails?.unit_number;
    const localPostalCode = addressDetails?.postal_code;
    const localCity = addressDetails?.city.trim();
    const localProvince = addressDetails?.province.trim();
    setEditingFieldsState({
      ...editingFieldsState,
      [name]: { ...editingFieldsState[name], loading: true },
    });
    if (name === SECTIONS.services) {
      const subTotal =
        getSaleOptionsPrice +
        editingFieldsState.services.value.package.price +
        editingFieldsState.services.value.services.reduce(
          (acc, item) => Number(acc) + Number(item.price),
          TOUR_DETAILS.zero_price
        );
      const totalCost =
        Number(subTotal || TOUR_DETAILS.zero_price) +
        ((subTotal || TOUR_DETAILS.zero_price) * fixedPercentForTotalPrice) /
          TOUR_DETAILS.hundred_percent;
      dispatch(
        updateTourServices({
          tourId: params?.id,
          body: {
            package: editingFieldsState.services.value.package.id,
            service: editingFieldsState.services.value.services.map(
              (serv) => serv.id
            ),
            sale_options: salesOptions.map((elem) => elem.id) || [],
            company_services:
              editingFieldsState.services.value.company_services.map((serv) => {
                const serviceInTour =
                  !!tourDetails?.company_services &&
                  tourDetails?.company_services?.find(
                    (service) => service?.id === serv?.id
                  );
                return {
                  id: serv.id,
                  option_id: null,
                  price: serv?.price || serviceInTour?.price,
                };
              }),
            sqft: Number(editingFieldsState.services.value.sqft),
            subtotal: subTotal || alreadyChargedSubtotal,
            total_cost: totalCost || alreadyChargedTotal,
            discount: fixedPercentAmount,
            company_total_cost: selectedCompanyServices.reduce(
              (acc, service) => {
                return acc + Number(service.price);
              },
              0
            ),
          },
        })
      ).finally(() => {
        handleMakeEditingFieldDefaultState(name);
      });
    } else {
      dispatch(
        updateTour({
          tourId: params?.id,
          body: {
            instructions,
            date: dateWithDashesSpaceFullHours24(date),
            date: dateWithDashesSpaceFullHours24(date),
            city: localCity || city,
            province: localProvince || province,
            postal_code: localPostalCode || postal_code,
            unit_number: localUnitNumber || unit_number,
            [name]:
              name === SECTIONS.date
                ? dateWithDashesSpaceFullHours24(editingFieldsState[name].value)
                : editingFieldsState[name].value,
            address: `${address}`,
          },
        })
      ).finally(() => {
        handleMakeEditingFieldDefaultState(name);
      });
    }
  };

  const handleChangeAddress = (value) => {
    const data = {
      ...tourDetails,
      address: value,
    };
    setTourAddress(value);
    setOnlyAddress(value);
    dispatch(actions.setTourDetails(data));
  };

  const handleChangeSqFt = (sq) => {
    dispatch(getPackages(sq, setEditingFieldsState));
    dispatch(getServices({ price: sq, setEditingFieldsState }));
    dispatch(actions.setSelectedSqFt(sq));
    dispatch(actions.setSelectedServices([]));
    dispatch(actions.setSelectedPackage({}));
    setEditingFieldsState((prev) => ({
      ...prev,
      services: {
        ...prev.services,
        value: { ...prev.services.value, sqft: sq },
      },
    }));
  };

  const handleSelectPackage = (pack) => {
    const packageServicesIds = pack?.century_services?.map((elem) => elem.id);
    let packageDisableServiceIDs = [];
    services.forEach((elem) => {
      if (packageServicesIds.includes(elem?.id)) {
        packageDisableServiceIDs.push(...elem?.inactive_services_ids);
      } else {
        packageDisableServiceIDs.push(...elem?.active_services_ids);
      }
    });

    const resServices = localSelectedServices?.filter(
      (serv) =>
        !packageServicesIds.includes(serv?.id) &&
        !packageDisableServiceIDs.includes(serv?.id)
    );

    setEditingFieldsState((prev) => ({
      ...prev,
      services: {
        ...prev.services,
        value: {
          ...prev.services.value,
          package: { ...pack, services: pack.century_services },
          services: resServices,
        },
      },
    }));

    const servicesIds = resServices?.map((elem) => elem.id);

    setLocalSelectedServices(resServices);

    if (pack?.id !== editingFieldsState.services.value.package.id) {
      dispatch(actions.setSalesOptions([]));
      dispatch(
        actions.setServices({
          data: services,
          selected_ids: [...packageServicesIds, ...servicesIds],
        })
      );
    }
  };

  const handleToggleServiceSelection = (serv) => {
    let ids = [];
    if (localSelectedServices.some((locServ) => locServ.id === serv.id)) {
      const resSelectedServices = localSelectedServices.filter(
        (service) => service.id !== serv.id
      );

      const selectedServiceIds = resSelectedServices?.map((elem) => elem?.id);
      const ids = new Set([...selectedServiceIds]);

      serv?.active_services_ids.forEach(ids.delete, ids);

      const newSelectedServices = resSelectedServices?.filter((elem) =>
        ids.has(elem?.id)
      );

      setLocalSelectedServices(newSelectedServices);

      setEditingFieldsState((prev) => ({
        ...prev,
        services: {
          ...prev.services,
          value: {
            ...prev.services.value,
            services: newSelectedServices,
          },
        },
      }));
    } else {
      const serviceIds = new Set([
        ...localSelectedServices.map((elem) => elem.id),
        serv.id,
      ]);

      serv?.inactive_services_ids.forEach(serviceIds.delete, serviceIds);

      ids = new Set([...serviceIds]);

      const newLocalData = localSelectedServices?.filter((elem) =>
        ids.has(elem?.id)
      );

      setLocalSelectedServices(() => [
        ...newLocalData,
        { id: serv.id, price: serv.price, title: serv.title },
      ]);

      setEditingFieldsState((prev) => ({
        ...prev,
        services: {
          ...prev.services,
          value: {
            ...prev.services.value,
            services: [
              ...newLocalData,
              { id: serv.id, price: serv.price, title: serv.title },
            ],
          },
        },
      }));

      giveAffordableSaleOption({
        serviceIds: [...serviceIds],
        dispatch,
        actions,
        selectedPackageSalOption,
        salesOptions,
      });
    }
    const packageServicesIds =
      editingFieldsState?.services?.value?.package?.century_services?.map(
        (elem) => elem?.id
      ) || [];

    dispatch(
      actions.setServices({
        data: services,
        selected_ids: [...ids, ...packageServicesIds],
      })
    );
  };

  const handleToggleCompanyServiceSelection = (id) => {
    if (localSelectedCompanyServices.includes(id)) {
      const resSelectedServices = localSelectedCompanyServices.filter(
        (servId) => servId !== id
      );
      setLocalSelectedCompanyServices(resSelectedServices);
      setEditingFieldsState((prev) => ({
        ...prev,
        services: {
          ...prev.services,
          value: {
            ...prev.services.value,
            company_services: [
              ...resSelectedServices.map((servId) => ({
                id: servId,
                option_id: null,
              })),
            ],
          },
        },
      }));
    } else {
      setLocalSelectedCompanyServices((prev) => [...prev, id]);
      setEditingFieldsState((prev) => ({
        ...prev,
        services: {
          ...prev.services,
          value: {
            ...prev.services.value,
            company_services: [
              ...prev.services.value.company_services,
              { id, option_id: null },
            ],
          },
        },
      }));
    }
  };

  const handlePayForCompanyServices = () => {
    setIsLoadingPayCompanyServices(true);
    dispatch(payForCompanyServices({ id: params?.id }))
      .then((res) => window.open(res.data.url, '_self'))
      .catch(() => {
        Toastify({
          type: TOAST_TYPES.error,
          message: TOAST_MESSAGES.tryAgain,
        });
      })
      .finally(() => setIsLoadingPayCompanyServices(false));
  };

  useEffect(() => {
    const selectedCompanyServices = localSelectedCompanyServices.map((id) =>
      companyServices.find((elem) => elem.id === id)
    );
    setSelectedCompanyServices(selectedCompanyServices);
  }, [localSelectedCompanyServices]);

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getTourById({
          tourId: params?.id,
        })
      ).catch((err) => {
        if (err.response.status === RESPONSE_STATUSES.not_found) {
          navigate('/tours');
        }
      });
    }
  }, [params?.id]);

  useEffect(() => {
    if (tourDetails.sqft) {
      dispatch(getPackages(tourDetails.sqft));

      const serviceIDs = tourDetails?.service?.map((elem) => elem?.id) || [];
      const packageServices =
        tourDetails?.package?.services?.map((elem) => elem.id) || [];
      let optionServicesIds = [];
      tourDetails?.century_sale_options?.forEach((elem) => {
        elem.century_pricings?.forEach((elem) =>
          optionServicesIds.push(elem.id)
        );
      });

      dispatch(
        getServices({
          price: tourDetails.sqft,
          selected_ids: [
            ...serviceIDs,
            ...packageServices,
            ...optionServicesIds,
          ],
        })
      );
    }
    if (tourDetails?.service?.length) {
      const selServices = tourDetails?.service.map((serv) => ({
        id: serv.id,
        price: serv.price,
        title: serv.title,
      }));
      setLocalSelectedServices(selServices);
    }
    if (tourDetails?.company_services?.length) {
      const selCompanyServices = tourDetails?.company_services.map(
        (serv) => serv.id
      );
      setLocalSelectedCompanyServices(selCompanyServices);
    }
    setDate(
      !!tourDetails?.date
        ? new Date(tourDetails?.date?.replace(/[ ]/g, 'T'))
        : null
    );
    setInitialEditingFieldsState();
    setAddressDetails({
      unit_number: tourDetails?.unit_number,
      postal_code: tourDetails.postal_code,
      city: tourDetails.city,
      province: tourDetails.province,
    });
    setOnlyAddress(
      tourDetails?.address?.split(',')?.slice(0, -3)?.join(',') || null
    );
    if (!tourAddress?.length && tourDetails?.address) {
      setTourAddress(tourDetails?.address);
    }
    dispatch(actions.setSalesOptions(tourDetails?.century_sale_options));
  }, [tourDetails]);

  useEffect(() => {
    const serviceIds = [];
    salesOptions?.forEach((option) => {
      const services = option?.century_pricings?.map((service) => service.id);
      serviceIds.push(...services);
    });
    setOptionServiceIds(serviceIds);

    if (salesOptions?.length) {
      const nonOptionServices = localSelectedServices?.filter(
        (serv) => !serviceIds.includes(serv?.id)
      );

      setLocalSelectedServices(nonOptionServices);
    }
  }, [salesOptions]);

  useEffect(() => {
    dispatch(
      actions.setSelectedPackageSalOption(
        packages.find(
          (pack) =>
            pack?.id === editingFieldsState?.services?.value?.package?.id
        )?.century_sale_options
      )
    );
  }, [editingFieldsState?.services?.value?.package?.id, packages]);

  return (
    <div className="content">
      <NavigationControl
        tourStatus={tourDetails.status}
        isStatus={true}
        isBack
        statuses={types}
        title={tourDetails?.address}
        childNumber={tourDetails.tour_child_numbers}
        noButtons={true}
      />
      <ModalComponent
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={MODAL_TYPES.warning}
        title={'Attention'}
        content_1={'Not possible to edit order after it has been "Scheduled"'}
        content_2={
          'For any changes please contact your administrator - Name Surname'
        }
        footerElementsDirection={FOOTER_ELEMENTS_DIRECTIONS.column}
        button_1_phone={true}
        button_1_type={BUTTON_TYPES.main_dark}
        button_1_text={'+1 (555) 000-00000'}
        button_2_type={BUTTON_TYPES.main_light}
        button_2_text={'Discard'}
      />
      <ControlBar
        params={params}
        state={state}
        pageName={CONTROL_BAR_PAGES.tourDetails}
      />
      <div className="dashboardMain">
        <div className="main_content_container">
          <div className="card">
            <h3 className="heading3">{TOUR_DETAILS.listing_details}</h3>
            <div className="requestCard">
              <div className="userType">
                {isBrokerageAdmin(tourDetails?.user)
                  ? `${tourDetails?.user?.name} ${tourDetails?.user?.last_name}`
                  : tourDetails?.user?.position}
              </div>
              <div className="avatar">
                {tourDetails?.user?.image ? (
                  <img
                    width="62px"
                    src={`${base_url}${tourDetails?.user?.image}`}
                    alt="avatar"
                  />
                ) : (
                  <AgentNoImage
                    name={tourDetails?.user?.name}
                    surname={tourDetails?.user?.last_name}
                  />
                )}
              </div>
              <div className="mediaBody">
                <div className="name">{`${tourDetails?.user?.name || ''} ${
                  tourDetails?.user?.last_name || ''
                }`}</div>
                <p>M: {`${tourDetails?.user?.email || ''}`}</p>
                <p>P: {`${tourDetails?.user?.phone || ''}`}</p>
              </div>
            </div>
          </div>
          <div className="tourMain">
            <div className="details_first_section">
              <div
                className={`col-sm-6 address_part_container ${
                  editingFieldsState.address.status && 'address_container'
                }`}
              >
                <div className="card-detail tour_details_card_detail">
                  <AddressSection
                    editingFieldsState={editingFieldsState}
                    tourAddress={tourAddress}
                    handleChangeAddress={handleChangeAddress}
                    handleClickSave={handleClickSave}
                    tourDetails={tourDetails}
                    handleClickEdit={handleClickEdit}
                    isEditingDisabled={isEditingDisabled}
                    setTourAddress={setTourAddress}
                    onlyAddress={onlyAddress}
                    addressDetails={addressDetails}
                    setAddressDetails={setAddressDetails}
                    handleClickCancel={handleClickCancel}
                  />
                </div>
              </div>
              <div className="dates_part_container">
                <div className="">
                  <div className="card-detail tour_details_card_detail">
                    <h3>{TOUR_DETAILS.order_placed}</h3>
                    <p>{formattedTourCreationDate}</p>
                  </div>
                </div>
                <div className="">
                  <div className="card-detail tour_details_card_detail shooting_date_card">
                    <div className="date_card_heading">
                      <h3 className="shooting_date_title">
                        {TOUR_DETAILS.request_shooting_date}
                      </h3>
                      <span className="to_be_confirmed">
                        {TOUR_DETAILS.to_be_confirmed}
                      </span>
                    </div>
                    {editingFieldsState.date.status ? (
                      <DatePicker
                        selected={date}
                        onChange={(date) => handleChangeTourDetailsDate(date)}
                        showTimeSelect
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        timeInputLabel="time"
                        dateFormat={TOUR_DETAILS.date_format}
                        minDate={new Date()}
                        customInput={<CustomDatePickerInput />}
                      />
                    ) : (
                      <div className="date_card_contain shooting_time_container">
                        <p className="shooting_date_text">
                          {formattedTourShootingDate}
                        </p>
                        <p className="shooting_time_text">
                          {formattedShootingTime}
                        </p>
                      </div>
                    )}
                    {editingFieldsState.date.loading ? (
                      <CircularProgress
                        type={CIRCULAR_PROGRESS_TYPES.danger}
                        size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                      />
                    ) : editingFieldsState.date.status ? (
                      <div className="card_buttons_container">
                        <Button
                          onClick={({ target }) => handleClickSave(target)}
                          name={SECTIONS.date}
                          type={BUTTON_TYPES.save}
                          text={TOUR_DETAILS.save}
                        />
                        <Button
                          onClick={({ target }) => handleClickCancel(target)}
                          name={SECTIONS.date}
                          type={BUTTON_TYPES.cancel}
                          text={TOUR_DETAILS.cancel}
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={({ target }) => handleClickEdit(target)}
                        name={SECTIONS.date}
                        type={BUTTON_TYPES.edit}
                        text={TOUR_DETAILS.edit}
                        disabledWithClassOnly={isEditingDisabled}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="request_note_part">
                <div className="card-detail tour_details_card_detail">
                  <h3>{TOUR_DETAILS.request_note}</h3>
                  {editingFieldsState.instructions.loading ? (
                    <CircularProgress
                      type={CIRCULAR_PROGRESS_TYPES.danger}
                      size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                    />
                  ) : editingFieldsState.instructions.status ? (
                    <>
                      <TextareaAutosize
                        name="instructions"
                        autoFocus
                        minRows={3}
                        maxRows={20}
                        className="edit-input"
                        defaultValue={TOUR_DETAILS.single_line}
                        value={editingFieldsState.instructions.value || ''}
                        onChange={({ target }) =>
                          handleChangeTourDetails(target)
                        }
                      />

                      <div className="card_buttons_container">
                        <Button
                          onClick={({ target }) => handleClickSave(target)}
                          name={SECTIONS.instructions}
                          type={BUTTON_TYPES.save}
                          text={TOUR_DETAILS.save}
                        />
                        <Button
                          onClick={({ target }) => handleClickCancel(target)}
                          name={SECTIONS.instructions}
                          type={BUTTON_TYPES.cancel}
                          text={TOUR_DETAILS.cancel}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <p>{tourDetails?.instructions}</p>
                      <Button
                        onClick={({ target }) => handleClickEdit(target)}
                        name={SECTIONS.instructions}
                        type={BUTTON_TYPES.edit}
                        text={TOUR_DETAILS.edit}
                        disabledWithClassOnly={isEditingDisabled}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`details_services_section ${
              !editingFieldsState.services.status &&
              'details_services_section_disabled'
            }`}
          >
            <div className="pricingMain">
              <div className="services_header_container">
                <h3 className="heading3">{TOUR_DETAILS.services}</h3>
                {editingFieldsState.services.loading ? (
                  <CircularProgress
                    type={CIRCULAR_PROGRESS_TYPES.danger}
                    size={CIRCULAR_PROGRESS_SIZE_NAMES.small}
                  />
                ) : editingFieldsState.services.status ? (
                  <div className="card_buttons_container">
                    <Button
                      onClick={({ target }) => handleClickSave(target)}
                      name={SECTIONS.services}
                      type={BUTTON_TYPES.save}
                      text={TOUR_DETAILS.save}
                    />
                    <Button
                      onClick={({ target }) => handleClickCancel(target)}
                      name={SECTIONS.services}
                      type={BUTTON_TYPES.cancel}
                      text={TOUR_DETAILS.cancel}
                    />
                  </div>
                ) : (
                  <Button
                    onClick={({ target }) => handleClickEdit(target)}
                    name={SECTIONS.services}
                    type={BUTTON_TYPES.edit}
                    text={TOUR_DETAILS.edit}
                    disabledWithClassOnly={isEditingDisabled}
                  />
                )}
              </div>
            </div>
            <div className="packages">
              {!editingFieldsState.services.status && (
                <abbr
                  className="section_disable_container_abbr"
                  title={TOUR_DETAILS.want_to_edit}
                >
                  <div
                    className={`section_disable_container ${
                      isModalOpened && 'disable_transparent'
                    }`}
                  ></div>
                </abbr>
              )}
              <div className="form-group">
                <label>
                  {TOUR_DETAILS.select_sqft}
                  <span>
                    <i> {TOUR_DETAILS.sqft_change}</i>
                  </span>
                </label>
                <ul className="selectSizeList">
                  {sqFt?.map((sq, index) => {
                    return (
                      <li
                        className={
                          Number(editingFieldsState.services.value.sqft) ===
                          sq.value
                            ? 'active'
                            : ''
                        }
                        key={index}
                        onClick={() => {
                          handleChangeSqFt(sq.value);
                        }}
                      >
                        <div>
                          <i>
                            <img src={HomeIcon} alt="home" />
                          </i>
                          <span>
                            {sq.title}
                            <small>{TOUR_DETAILS.sq_ft}</small>
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="">
                <div className="">
                  <div className="">
                    <div className="package_section">
                      <h4>{TOUR_DETAILS.select_package}</h4>
                      <div className="selected_packages_container">
                        {packages.map((pack, index) => {
                          return (
                            <PackageItem
                              key={pack.id}
                              pack={pack}
                              isSelected={
                                pack?.id ===
                                editingFieldsState?.services?.value?.package?.id
                              }
                              isLastItem={
                                index + ADD_ONE_TO_INDEX === packages.length
                              }
                              selectPackage={() => handleSelectPackage(pack)}
                              selectedSqFt={
                                editingFieldsState.services.value.sqft
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                    {!!services?.length && (
                      <div className="">
                        <div className="additional_services_container">
                          <h4>{TOUR_DETAILS.add_ons}</h4>
                          <ul className="services_ul">
                            {services.map((serv) => {
                              return (
                                <ServiceLabel
                                  key={serv.id}
                                  service={serv}
                                  selectedServices={localSelectedServices}
                                  selectService={() =>
                                    handleToggleServiceSelection(serv)
                                  }
                                  disabled={
                                    editingFieldsState?.services?.value?.package?.services?.some(
                                      (service) => service?.id === serv?.id
                                    ) ||
                                    editingFieldsState?.services?.value?.package?.century_services?.some(
                                      (service) => service?.id === serv?.id
                                    ) ||
                                    optionServiceIds?.find(
                                      (option) => option === serv?.id
                                    )
                                  }
                                />
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!!companyServices.length && (
                  <div className="">
                    <div className="additional_services_container">
                      <h4>{TOUR_DETAILS.other_services}</h4>
                      <ul className="services_ul">
                        {companyServices.map((serv) => {
                          const serviceInTour =
                            tourDetails?.company_services?.find(
                              (service) => service?.id === serv?.id
                            );
                          return (
                            <ServiceLabel
                              key={serv.id}
                              service={serv}
                              selectedServices={localSelectedCompanyServices}
                              selectService={
                                handleToggleCompanyServiceSelection
                              }
                              serviceInTour={serviceInTour}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row details_cards_container">
            <h3 className="heading3">{TOUR_DETAILS.pricing}</h3>
            {(!!localSelectedCompanyServices?.length ||
              !!tourDetails?.company_services?.length) && (
              <div className="col-xl-6">
                <ServicesOrderSummary
                  title={`${
                    tourDetails.company_services_paid
                      ? TOUR_DETAILS.already_charged
                      : TOUR_DETAILS.to_be_charged_later
                  }`}
                  services={
                    editingFieldsState.services.status
                      ? companyServices.filter(({ id }) =>
                          localSelectedCompanyServices.includes(id)
                        )
                      : tourDetails.company_services
                  }
                  handlePay={handlePayForCompanyServices}
                  isPaid={tourDetails.company_services_paid}
                  fixedPercentAmount={fixedPercentAmount}
                  isLoadingPayCompanyServices={isLoadingPayCompanyServices}
                  tourId={params?.id}
                  disablePriceSet={editingFieldsState.services.status}
                />
              </div>
            )}
            <div className="col-xl-6">
              <OrderSummary
                title={TOUR_DETAILS.already_charged}
                packages={packages}
                selectedPackage={
                  editingFieldsState?.services.value?.package ||
                  tourDetails?.package
                }
                filteredServices={
                  services.filter((serv) =>
                    localSelectedServices.some(({ id }) => id === serv.id)
                  ) ||
                  editingFieldsState?.services.value?.services ||
                  tourDetails?.service
                }
                filteredCompanyServices={
                  editingFieldsState?.services.value?.company_services ||
                  tourDetails?.company_services
                }
                packageServices={tourAddress?.package?.services}
                isDetailsPage={true}
                isPaidMainServices={tourDetails.main_services_paid}
                tourId={params?.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
