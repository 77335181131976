export const SIGN_UP_PAGE = {
  user_type: 'Sales Representative /Broker',
  sales_representative: 'Sales Representative',
  broker: 'Broker',
  user_placeholder: 'Select',
  email: 'Email',
  email_placeholder: 'Valid email adrress',
  first_name: 'First Name',
  first_name_placeholder: 'John',
  last_name: 'Last Name',
  last_name_placeholder: 'Doe',
  phone_number: 'Phone Number',
  phone_number_placeholder: '(437) 800-5463',
  password: 'Password',
  password_placeholder: 'Your password (min 8 chars)',
  confirm_pass: 'Confirm Password',
};

export const SIGN_IN = 'Sign In';
export const SIGN_UP = 'Sign Up';
export const CREATE_ACCOUNT = 'Create an Account';

export const CENTURY = 'century';
export const COMPANY_TYPE = 'company-type';

export const SIGN_UP_VALID_MESSAGE = {
  email: 'Enter your email',
  email_message: 'Enter a valid email',
  email_required: 'Email is required',
  password_min: 'Password should be of minimum 8 characters length',
  password_max: 'Password is too long',
  password_required: 'Password is required',
  confirm_pass_required: 'Confirm password is required',
  password_match: 'Passwords must match',
  password: 'password',
  position: 'User type is required',
  first_name: 'Enter first name',
  first_name_required: 'First name is required',
  last_name: 'Enter last name',
  last_name_required: 'Last name is required',
  phone: 'Enter phone number',
  phone_required: 'Phone number is required',
  phone_min: 'Phone number should be of minimum 10 characters length',
  phone_error: 'Phone Number format is wrong',
  test_type: 'format',
};
