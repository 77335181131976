import {
  uploadVideos,
  uploadVideosChunk,
  uploadVideosComplete,
  uploadVideosStart,
} from 'components/fileUploadPopup/actions';
import { getVideoPreview } from './getVideoPreview';
import { Toastify } from 'hooks';
import {
  CHUNK_NUMBER,
  FILE,
  PREVIEW_IMAGES_KEY,
  UPLOADED_FILES,
  UPLOAD_ID,
  VIDEO,
} from 'constants/fileUpload';
import { TOAST_TYPES } from 'constants';
import { TOAST_MESSAGES } from 'constants';

export const uploadWithChunks = async ({
  acceptedFiles,
  onUploadProgress,
  setPendingLoading,
  id,
  tourId,
  setFilesData,
  setFile,
  setDisableCancel,
}) => {
  const chunkSize = 1024 * 1024 * 50;

  setDisableCancel(true);

  const errorMessage = () => {
    Toastify({
      type: TOAST_TYPES.error,
      message: TOAST_MESSAGES.tryAgain,
    });
    setFilesData([]);
    setFile([]);
    sessionStorage.removeItem(UPLOADED_FILES);
  };

  outerLoop: for (let i = 0; i < acceptedFiles?.length; i++) {
    const file = acceptedFiles[i];
    const totalChunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;

    let upload_id = null;
    const formData = new FormData();

    try {
      await uploadVideosStart({
        file_name: file.name,
        file_size: file.size,
      }).then((res) => (upload_id = res?.data?.upload_id));
    } catch (error) {
      errorMessage();
      break;
    }

    for (let j = 0; j < totalChunks; j++) {
      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      const form = new FormData();

      form.append(FILE, chunk);
      form.append(UPLOAD_ID, upload_id);
      form.append(CHUNK_NUMBER, currentChunk);

      try {
        await uploadVideosChunk(form, setFilesData, setFile).then(() => {
          onUploadProgress(currentChunk + 1, totalChunks, id + i);
        });
      } catch (error) {
        errorMessage();
        break outerLoop;
      }

      if (currentChunk < totalChunks - 1) {
        currentChunk++;
      } else {
        setPendingLoading((prev) => ({ ...prev, [id + i]: true }));
      }
    }

    try {
      await uploadVideosComplete({ upload_id });
    } catch (error) {
      errorMessage();
      break;
    }

    formData.append(UPLOAD_ID, upload_id);

    if (file.type.includes(VIDEO)) {
      const blob = await getVideoPreview({ file });
      formData.append(PREVIEW_IMAGES_KEY, blob, file.name);
    } else {
      formData.append(PREVIEW_IMAGES_KEY, file);
    }

    try {
      await uploadVideos(tourId, formData, setFilesData).finally(() => {
        setPendingLoading((prev) => ({ ...prev, [id + i]: false }));
      });
    } catch (error) {
      errorMessage();
      break;
    }
  }

  setDisableCancel(false);
};
