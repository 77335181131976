import { USER_ROLES } from 'constants/userRoles';

export const isBrokerageAdmin = (user) => {
  return (
    user?.role === USER_ROLES.company ||
    user?.role === USER_ROLES.admin ||
    user?.position === USER_ROLES.ceo || 
    user?.role === USER_ROLES.centuryCompany
  );
};

export const isSuperAdmin = (user) => {
  return user?.role === USER_ROLES.centuryAmin;
}
