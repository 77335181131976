import axios from 'axios';

import { RESPONSE_STATUSES } from 'constants';
import { CENTURY, COMPANY_TYPE } from 'constants/singUp';

import { apiManager } from './apiManager';

const base_url = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: base_url,
  headers: {[COMPANY_TYPE]: [CENTURY]}
});

export const authApi = {
  login: async (params) => {
    const res = instance.post(`/dashboard/login`, params);
    res.catch((err) => {
      if (err.response.status === RESPONSE_STATUSES.unauthorized) {
        localStorage.removeItem('access_token');
      }
    });
    return res;
  },
  signUp: async (params) =>
    apiManager.post(`/dashboard/signup`, params, { [COMPANY_TYPE]: CENTURY }),
  logout: async () => apiManager.post('/dashboard/logout'),
  getUser: async () => apiManager.get('/dashboard/user'),
  forgotPassword: async (params) =>
    apiManager.post('/dashboard/forgot-password', params, { [COMPANY_TYPE]: CENTURY }),
  resetPassword: async (params) =>
    apiManager.post('/dashboard/reset-password', params),
};
