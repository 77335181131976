export const TOUR_STATUSES = {
  new: 'REQUESTED',
  scheduled: 'SCHEDULED',
  uploaded: 'UPLOADED',
  edited: 'EDITED',
  completed: 'COMPLETED',
  delivered: 'DELIVERED',
  other: 'OTHER',
};

export const LOUNGE_TOUR_STATUSES = {
  new: 'REQUESTED',
  uploaded: 'UPLOADED',
  reuploaded: 'REUPLOADED',
  delivered: 'DELIVERED',
  redelivered: "REDELIVERED",
}

export const TOUR_STATUSES_USER_VISIBLE = {
  new: 'REQUESTED',
  scheduled: 'SCHEDULED',
  delivered: 'DELIVERED',
};

export const TOUR_STATUSES_OPTIONS = [
  {
    label: LOUNGE_TOUR_STATUSES.new,
    value: LOUNGE_TOUR_STATUSES.new,
    visibleType: LOUNGE_TOUR_STATUSES.new,
  },
  {
    label: LOUNGE_TOUR_STATUSES.uploaded,
    value: LOUNGE_TOUR_STATUSES.uploaded,
    visibleType: LOUNGE_TOUR_STATUSES.scheduled,
  },
  {
    label: LOUNGE_TOUR_STATUSES.reuploaded,
    value: LOUNGE_TOUR_STATUSES.reuploaded,
    visibleType: LOUNGE_TOUR_STATUSES.scheduled,
  },
  {
    label: LOUNGE_TOUR_STATUSES.delivered,
    value: LOUNGE_TOUR_STATUSES.delivered,
    visibleType: LOUNGE_TOUR_STATUSES.delivered,
  },
  {
    label: LOUNGE_TOUR_STATUSES.redelivered,
    value: LOUNGE_TOUR_STATUSES.redelivered,
    visibleType: LOUNGE_TOUR_STATUSES.redelivered,
  },
]

export const types = [
  {
    type: TOUR_STATUSES.other,
    visibleType: TOUR_STATUSES.other,
    title: 'Other services',
    visibleTitle: 'Other services',
  },
  {
    type: TOUR_STATUSES.new,
    visibleType: TOUR_STATUSES.new,
    title: 'New',
    visibleTitle: 'New',
  },
  {
    type: TOUR_STATUSES.delivered,
    visibleType: TOUR_STATUSES.delivered,
    title: 'Delivered',
    visibleTitle: 'Delivered',
  },
  {
    type: TOUR_STATUSES.scheduled,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Scheduled',
    visibleTitle: 'Scheduled',
  },
  {
    type: TOUR_STATUSES.uploaded,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Uploaded',
    visibleTitle: 'Scheduled',
  },
  {
    type: TOUR_STATUSES.edited,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Edited',
    visibleTitle: 'Scheduled',
  },
  {
    type: TOUR_STATUSES.completed,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Completed',
    visibleTitle: 'Scheduled',
  },
];

export const loungeTypes = [
  {
    type: LOUNGE_TOUR_STATUSES.new,
    visibleType: LOUNGE_TOUR_STATUSES.new,
    title: 'New',
    visibleTitle: 'New',
  },
  {
    type: LOUNGE_TOUR_STATUSES.delivered,
    visibleType: LOUNGE_TOUR_STATUSES.delivered,
    title: 'Delivered',
    visibleTitle: 'Delivered',
  },
  {
    type: LOUNGE_TOUR_STATUSES.uploaded,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Uploaded',
    visibleTitle: 'Uploaded',
  },
  {
    type: LOUNGE_TOUR_STATUSES.reuploaded,
    visibleType: TOUR_STATUSES.scheduled,
    title: 'Uploaded',
    visibleTitle: 'Uploaded',
  },
  {
    type: LOUNGE_TOUR_STATUSES.redelivered,
    visibleType: TOUR_STATUSES.delivered,
    title: 'Delivered',
    visibleTitle: 'Delivered',
  },
];

export const classes = {
  [TOUR_STATUSES.new]: 'badgeDanger',
  [TOUR_STATUSES.scheduled]: 'badgeWarning',
  [TOUR_STATUSES.pending]: 'badgeOutlineDanger',
  [TOUR_STATUSES.delivered]: 'badgeSuccess',
  [TOUR_STATUSES.completed]: 'badgeSuccess',
  [TOUR_STATUSES.edited]: 'badgeDarkWarning',
  [TOUR_STATUSES.uploaded]: 'badgeWarning',
  [TOUR_STATUSES.other]: 'badgeOutlineDanger',
};

export const RESPONSE_STATUSES = {
  unauthorized: 401,
  not_found: 404,
};

export const isMobile = window.innerWidth < 767;

export const isIOS =
  ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;

export const MEDIA_TYPES = {
  image: 'image',
  video: 'video',
  matterport: 'matterport',
  reel: 'reel',
  pdf: 'pdf',
};

export const TOAST_TYPES = {
  error: 'error',
  success: 'success',
  warning: "warning",
};

export const TOAST_MESSAGES = {
  tryAgain: 'Something went wrong. Please try again',
  existingEmail: "The email has already been taken",
  notExistingEmail: 'The email is not registered.',
  connectionCheck: 'Please check your connection and try again.',
  invalidAuth: 'Invalid login or password',
  savedChanges: 'Your Changes are saved',
  passwordChanged: 'Your password has been changed',
  networkCheck: 'Please check your network',
  imageChanged: 'The image has been changed.',
  imageUpdateFailed:
    'Image was not updated, please check your connection or try another image.',
  wrongPassword: "Wrong old password",
  highlightsMessage: "You can select only 6 highlights",
  address_error: "Please choose address and date",
};

export const CONTROL_BAR_PAGES = {
  tourDetails: "tour-details",
  propertyWebsite: "property-website",
}

export const GOOGLE_DRIVE_LINK = "https://drive.google.com";

export const POSTAL_CODE_ERROR = "Write in the correct format";
export const POSTAL_CODE_HELPER_TEXT = "(in A1A 1A1 format)";

export const DEFAULT_DATE = 'Mon 13/06/2022, 10:00 AM';
export const SELECT_DATE = 'Select Date & Time';

export const ACCESS_TOKEN = "access_token";
export const USER_ROLE = "user_role";

export const PAYMENT_ACCEPTED = {
  thank_you: 'Thank you for your order.',
  email_send: 'An email was sent containing the details of your order.',
  lounge_thank_you_text: "Thank you for selecting Content Lounge Services for your video content needs. We are delighted to confirm the receipt of your order and appreciate the opportunity to assist you in creating engaging and professional video content.",
  whats_next: 'What’s Next?.',
  receive_email: 'Once your shoot date/time is confirmed you will receive a Scheduling Confirmation Email.',
  see_order_details: 'See Order Details',
  we_will_contact : 'We will contact you in order to confirm your requested photoshoot',
  launge_text_1: "Attached to the email, you will find a comprehensive set of resources to guide you through the content creation process. Here's what you can expect:",
  lounge_text_2: "1. Sample Script and Instructions: We have included a sample script that serves as a general guideline for your video content. It is essential to customize and deliver your lines with your unique personality, ensuring the content feels personal and effective. The provided sample script and instructions are meant to inspire and provide structure to your content creation process.",
  lounge_text_3: "Please note that while the sample script and instructions offer valuable guidance, we encourage you to add your own touch and make the content truly your own.",
  lounge_text_4: "2. Language Tone and Style: It is important to maintain the tone and style outlined in the sample script to ensure consistency and effectiveness. However, feel free to adapt the language to suit your natural delivery and personality.",
  lounge_text_5: "3. Personalize Your Message: To maximize the impact of your video content, we encourage you to personalize your message and make it relevant to your unique audience and situation. Adding a personal touch can significantly enhance engagement and resonate with your viewers.",
}

export const PROFILE_SETTINGS = {
  title: "Profile Settings",
  brokerage_url: "https://www.century21.com",
  brokerage: "century21.com",
  brokerage_title: "Brokerage",
  update_photo: "Update photo",
  enter_name: "Enter name",
  enter_surname: "Enter surname",
  save: "Save",
  cancel: "Cancel",
  edit: "Edit",
  name_surname: "Name Surname",
  designation: "Designation",
  not_provided: "Not Provided",
  add: "ADD",
  personal_information: "Personal Information",
  email: "Email",
  enter_email: "Enter email",
  email_error: "Email is not valid",
  phone_number: "Phone Number",
  enter_phone: "Enter phone",
  phone_error: "Phone is not valid",
  short_description: "Short Description",
  enter_desc: "Type here...",
  current_password: "Current Password",
  new_password: "New Password",
  password_error: "Password is not valid",
  confirm_password: "Confirm Password",
  confirm_password_error: "Please make sure your password match",
  password: "Password",
  update: "Update",
}

export const PAYMENT_WEBSITE = {
  required: "Required",
  property_website: "Property Website",
  property_sale_price: "Property Sale Price",
  example: "ex. $1.000.000",
  edit: "Edit",
  save: "Save",
  property_highlights: "Property Highlights",
  open_house_dates: "Open House Dates",
  add_new_date: "Add new date",
  date: "Date",
  one: 1,
  alternate_text: "Alternate Text",
  offer_presentation_date: "Offer Presentation Date",
  property_details: "Property Details",
  property_description: "Property Description",
  highlights: "Highlights",
  add_highlight: "Add highlight",
  neighborhood_info: "Neighborhood Info",
  agent_info: "Agent Info",
  add_agent: "Add agent",
  upload_image: "Upload Image",
  first_name: "First Name",
  last_name: "Last Name",
  short_description: "Short Description",
  address: "Address",
  phone: "Phone",
  website: "Website",
  see_live_preview: "See Live Preview",
  email: "Email",
  enter_email: "Enter email",
  title: "Title",
  enter_title: "Enter title",
}

export const DELIVERY_PAGE = {
  menu: "MENU",
  close_video: "CLOSE VIDEO",
  offered_at: "Offered at $",
  request_showing: "REQUEST A SHOWING",
  highlights: "HIGHLIGHTS",
  matterport: "3D MATTERPORT",
  open_house_on: "OPEN HOUSE ON",
  presentation_date: "PRESENTATION DATE",
  video: "VIDEO",
  gallery: "GALLERY",
  load_more: "LOAD MORE",
  display: "display",
  property_details: "PROPERTY DETAILS",
  what_to_know: "WHAT TO KNOW",
  key_highlights: "KEY HIGHLIGHTS",
  neighborhood_info: "NEIGHBORHOOD INFO",
  floor_plans: "FLOOR PLANS",
  agent: "Agent",
  short_phone: "P:",
  short_email: "M:",
  short_designation: "D:",
  short_website: "W:",
  short_address: "A:",
  copyright: "Ⓒ Copyright 2022 PV Apps. All Rights Reserved",
  powered_by: "Powered by",
  pv_apps: "PV Apps",
}