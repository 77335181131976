import { calendlyApi } from 'api/calendly';
import { actions } from './store';
import { toursApi } from 'api';

const base_url = process.env.REACT_APP_API_URL;

export const getTours = ({ queryStr }) => {
  return async (dispatch) => {
    const { data } = await toursApi.getTours({
      queryStr,
    });
    dispatch(actions.setToursList(data));
  };
};

export const getLoungeTours = ({ queryStr }) => {
  return async (dispatch) => {
    const { data } = await toursApi.getLoungeTours({
      queryStr,
    });
    dispatch(actions.setToursList(data));
  };
};

export const getTourById = ({ tourId }) => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await toursApi.getTourById({ tourId });
    dispatch(actions.setTourDetails(data));
  };
};

export const getPricingCompany = (params) => async (dispatch) => {
  const {
    data: { data },
  } = await toursApi.getPricingCompany(params);
  dispatch(actions.setPricingCompanyList(data));
};

export const getPackages =
  (price, setEditingFieldsState = () => {}) =>
  async (dispatch) => {
    const { data } = await toursApi.getPackages(price);
    setEditingFieldsState((prev) => ({
      ...prev,
      services: {
        ...prev.services,
        value: {
          ...prev.services.value,
          package: data.find(
            (elem) => elem.id === prev.services.value.package.id
          ),
        },
      },
    }));
    dispatch(actions.setPackages(data));
  };

export const getServices =
  ({ price, selected_ids, setEditingFieldsState = () => {} }) =>
  async (dispatch) => {
    const { data } = await toursApi.getServices(price);
    setEditingFieldsState((prev) => ({
      ...prev,
      services: {
        ...prev.services,
        value: {
          ...prev.services.value,
          services: prev.services.value.services.map((elem) => ({
            ...elem,
            price: data.find((item) => item.id === elem.id).price,
          })),
        },
      },
    }));
    dispatch(actions.setServices({ data, selected_ids }));
  };

export const orderNewTour = (tour) => async () => {
  return await toursApi.createTour(tour);
};

export const goToPayment =
  ({ id, is_main_service }) =>
  async () => {
    return await toursApi.goToPayment({ id, is_main_service });
  };

export const payForCompanyServices =
  ({ id }) =>
  async () => {
    return await toursApi.payForCompanyServices({ id });
  };

export const goToMarketingTourPayment =
  ({ id }) =>
  async () => {
    return await toursApi.goToMarketingTourPayment({ id });
  };

export const updateTour =
  ({ tourId, body }) =>
  async (dispatch) => {
    await toursApi.updateTour({ tourId, body });
    await dispatch(getTourById({ tourId }));
  };

export const updateCompanyServicesPrice =
  ({ tourId, body }) =>
  async (dispatch) => {
    await toursApi.updateCompanyServicesPrice({ tourId, body });
    await dispatch(getTourById({ tourId }));
  };

export const updateTourServices =
  ({ tourId, body }) =>
  async (dispatch) => {
    await toursApi.updateTourServices({ tourId, body });
    await dispatch(getTourById({ tourId }));
  };

export const getCompanyUsers = () => async (dispatch) => {
  const { data } = await toursApi.getCompanyUsers();
  dispatch(actions.setCompanyUsers(data));
};

export const getExistingToursToSelect = () => async (dispatch) => {
  const { data } = await toursApi.getExistingToursToSelect();
  dispatch(actions.setExistingToursToSelect(data));
};

export const getLoungePackages = () => async (dispatch) => {
  const { data } = await toursApi.getLoungePackages();
  const packages = data?.data;
  dispatch(actions.setLoungePackages(packages));
};

export const createLoungeTour = (tour) => async () =>
  await toursApi.createLoungeTour(tour);

export const getCenturyTourDetails = (id, navigate) => async (dispatch) => {
  try {
    const { data } = await toursApi.getCenturyTourDetails({ id });
    dispatch(actions.setTourDetails(data.data));
  } catch (error) {
    navigate('/');
  }
};

export const updateCenturyVimeoLinks =
  ({ id, params }) =>
  async (dispatch) => {
    await toursApi.updateCenturyVimeoLinks({ id, params });
    await dispatch(getCenturyTourDetails(id));
  };

export const getCalendarAddressData = () => async (dispatch) => {
  const { data } = await toursApi.getCalendarAddressData();
  dispatch(actions.setCalendarAddressData(data));
};

export const getCalendlyDataById = (id, token) => async (dispatch) => {
  try {
    const { data } = await calendlyApi.getCalendlyDataById(id, token);
    dispatch(actions.setCalendarData(data.resource));
  } catch (error) {
    console.error('error', error);
  }
};

export const cancelCalendlyById = (id, body, token) => async () =>
  await calendlyApi.cancelCalendlyById(id, body, token);

export const getLoungeToursAdmin =
  ({ queryStr }) =>
  async (dispatch) => {
    const { data } = await toursApi.getLoungeToursAdmin({ queryStr });
    dispatch(actions.setToursList(data));
  };

export const updateCalendarData =
  (id, body, pathname, token) => async (dispatch) => {
    await toursApi.updateCalendarData(id, body);
    await dispatch(getCalendlyDataById(pathname, token));
  };

export const downloadPdf = (id) => async (dispatch) => {
  const access_token = localStorage.getItem('access_token');

  const response = await fetch(`${base_url}/vision/download-pdf/${id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to download ZIP file');
  }

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'files.zip');
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};
