import { useEffect, useState } from 'react';
import * as DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'pages/tours/store';
import { selectedLoungePackage } from 'pages/tours/selectors';

import VideoModal from 'pages/download-zone/components/video-modal';

import { ADD_BTN, ADDED_BTN } from 'pages/tours/create/constants';

import addedIcon from 'assets/images/add.svg';
import addIcon from 'assets/images/add-dark.svg';
import phone from 'assets/images/Frame.png';
import playButton from 'assets/images/play-button.svg';
import mockup from 'assets/images/mockup.png';

export const LoungePackages = ({ data, sectionId }) => {
  const [added, setAdded] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false);

  const selectedPackage = useSelector(selectedLoungePackage);

  const dispatch = useDispatch();

  const infoSection = document.getElementById(sectionId);

  if (data?.info_section && infoSection) {
    const cleanData = DOMPurify.sanitize(data?.info_section);
    infoSection.innerHTML = cleanData;
  }

  const handleSelectPackage = (id) => {
    !added
      ? dispatch(actions.setSelectedLoungePackage({ id }))
      : dispatch(actions.setSelectedLoungePackage({}));
  };

  useEffect(() => {
    setAdded(data?.id === selectedPackage.id);
  }, [selectedPackage, data]);

  const playVideo = () => {
    setShowModalVideo(true);
  };

  return (
    <div className="loungePackage">
      {showModalVideo && data?.link?.link && (
        <VideoModal
          close={setShowModalVideo}
          show={showModalVideo}
          value={data?.link?.link}
        />
      )}
      <div className="loungeVideo" onClick={playVideo}>
        <img src={mockup} alt="Mockup" className="mockup_image" />
        <img
          src={data?.vimeo_link?.preview_image || phone}
          alt="Phone"
          className="posterImage"
        />
        <img src={playButton} alt="Play" className="play_button" />
      </div>
      <div className="loungeContext">
        <div className="loungeContextHeader">
          <h3 className="loungePrice">{`$${data.price}`}</h3>
          <div className="addContainer showDesktop">
            <button
              onClick={() => handleSelectPackage(data?.id)}
              className={`addBtn ${added ? 'addedBtn' : 'notAdded'}`}
            >
              {added ? (
                <>
                  {ADDED_BTN} <img src={addedIcon} alt="Add package" />
                </>
              ) : (
                <>
                  {ADD_BTN} <img src={addIcon} alt="Remove package" />
                </>
              )}
            </button>
          </div>
        </div>
        <h4 className="loungeTitle">{data.title}</h4>
        <p className="loungeText">{data.description}</p>
        <div className="loungeInfoSection" id={sectionId}></div>
      </div>
      <div className="addContainer showMobile">
        <button
          onClick={() => handleSelectPackage(data?.id)}
          className={`addBtn ${added ? 'addedBtn' : 'notAdded'}`}
        >
          {added ? (
            <>
              {ADDED_BTN} <img src={addedIcon} alt="Add package" />
            </>
          ) : (
            <>
              {ADD_BTN} <img src={addIcon} alt="Remove package" />
            </>
          )}
        </button>
      </div>
    </div>
  );
};
