import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';

import { AgentNoImage } from 'components/agentNoImage';
import { isBrokerageAdmin, isSuperAdmin } from 'helpers/checkUsersRoles';
import { CircularProgress, NavigationControl, Select } from 'components';
import { Button } from 'components/button';
import {
  dayMonthYearHoursMinutesAMSpacesFormatter,
  dayMonthYearSpaces,
  getTimeAMPM,
  getWeekDayByMoment,
} from 'helpers/momentHelpers';
import { OrderSummary } from '../create/new-steps/components/order-summary';
import { TOUR_STATUSES_OPTIONS, USER_ROLE } from 'constants';
import VimeoLinks from './components/vimeo-links/vimeoLinks';
import {
  cancelCalendlyById,
  downloadPdf,
  getCalendarAddressData,
  getCalendlyDataById,
  getCenturyTourDetails,
  updateCalendarData,
} from '../actions';
import SaveVimeoLinks from './components/save-vimeo-links/saveVimeoLinks';
import {
  calendarAddressDataSelector,
  calendarDataSelector,
} from '../selectors';

import { actions } from '../store';
import { types, LOUNGE_TOUR_STATUSES } from 'constants';
import {
  CIRCULAR_PROGRESS_COLOR,
  CIRCULAR_PROGRESS_SIZE_NAMES,
} from 'components/circularProgress/constants';
import { BUTTON_TYPES } from 'components/button/constants';
import { SECTIONS } from './constants';
import {
  ALREADY_CHARGED,
  LOUNGE_TOUR_DETAILS_TITLE,
  INSTRUCTIONS,
  ORDER_PLACED_ON,
  LOUNGE_BOOKING_DATE,
  TO_BE_CONFIRMED,
  EDIT,
  CANCEL,
  REQUESTED_BY,
} from 'constants/loungeTour';

import downloadIcon from 'assets/icons/download_icon.svg';

const base_url = process.env.REACT_APP_IMAGE_BASE_URL;

export const LoungeTourDetails = () => {
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [editingFieldsState, setEditingFieldsState] = useState({
    address: { status: false, loading: false, value: '' },
    date: { status: false, loading: false, value: '' },
    instructions: { status: false, loading: false, value: '' },
    services: {
      status: false,
      loading: false,
      value: {
        services: [],
        company_services: [],
        sqft: '',
        package: {},
      },
    },
  });

  const navigate = useNavigate();

  const pageSettings = {
    backgroundColor: 'fff',
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
    primaryColor: '00a2ff',
    textColor: '4d5055',
    hideGdprBanner: true,
  };

  const changedVimeoLinks = useRef();

  const dispatch = useDispatch();
  const { id } = useParams();

  const tourDetails = useSelector((state) => state.tours.tourDetails);
  const calendarData = useSelector(calendarDataSelector);
  const calendarAddressesData = useSelector(calendarAddressDataSelector);

  const getUserRole = () => localStorage.getItem(USER_ROLE);
  const isAdmin = isSuperAdmin({ role: getUserRole() });

  const isEditingDisabled =
    tourDetails.status === LOUNGE_TOUR_STATUSES.delivered;

  const handleChangeTourDetailsDate = (value) => {
    setDate(value);
    setEditingFieldsState({
      ...editingFieldsState,
      date: { ...editingFieldsState.date, value },
    });
  };

  const handleClickSave = () => {
    dispatch(downloadPdf(tourDetails?.content_lounge_service?.id));
  };

  const handleClickCancel = () => {
    setOpenCalendar(false);
  };

  const handleClickEdit = () => {
    if (!isEditingDisabled) {
      setOpenCalendar(true);
    }
  };

  const handleEventReschedule = (data) => {
    const uri = data.event.uri;
    const url = new URL(uri);
    const { pathname } = url;

    const address = calendarAddressesData?.find(
      (elem) => elem.id === tourDetails?.content_lounge_address?.id
    );
    dispatch(
      cancelCalendlyById(
        tourDetails.calendar_event_id,
        {
          reason: 'Change date',
        },
        address?.token
      )
    );
    dispatch(
      updateCalendarData(
        id,
        { calendar_event_id: pathname },
        pathname,
        address?.token
      )
    );
  };

  useCalendlyEventListener({
    onProfilePageViewed: () => {},
    onDateAndTimeSelected: () => {},
    onEventTypeViewed: () => {},
    onEventScheduled: (e) => handleEventReschedule(e.data.payload),
  });

  const formattedTourCreationDate = dayMonthYearHoursMinutesAMSpacesFormatter(
    tourDetails.created_at
  );

  const formattedTourShootingDate = `${getWeekDayByMoment(
    calendarData?.start_time
  )}, ${dayMonthYearSpaces(calendarData?.start_time)}`;
  const formattedShootingTime = getTimeAMPM(calendarData?.start_time);

  useEffect(() => {
    dispatch(getCenturyTourDetails(id, navigate));
  }, []);

  useEffect(() => {
    setStatus(tourDetails.status);
  }, [tourDetails.status]);

  useEffect(() => {
    if (tourDetails.calendar_event_id && calendarAddressesData?.length) {
      const address = calendarAddressesData?.find(
        (elem) => elem.id === tourDetails?.content_lounge_address?.id
      );
      dispatch(
        getCalendlyDataById(tourDetails.calendar_event_id, address?.token)
      );
    }
  }, [tourDetails.calendar_event_id, calendarAddressesData]);

  useEffect(() => {
    dispatch(getCalendarAddressData());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(actions.setVimeoLinks([]));
    };
  }, []);

  return (
    <div className="content">
      {loading && (
        <div className="circlar_progress_container">
          <div className="circlar_progress">
            <CircularProgress
              progressSecondColor={CIRCULAR_PROGRESS_COLOR.white_opacity}
              progressColor={CIRCULAR_PROGRESS_COLOR.primary}
              size={CIRCULAR_PROGRESS_SIZE_NAMES.medium}
            />
          </div>
        </div>
      )}
      <NavigationControl
        tourStatus={tourDetails?.status}
        isStatus={true}
        isBack
        statuses={types}
        title={`${tourDetails?.id} ${tourDetails?.content_lounge_service?.title}`}
        childNumber={tourDetails?.tour_child_numbers}
        noButtons={true}
        isAdmin={isAdmin}
      />
      {isAdmin && (
        <SaveVimeoLinks
          status={status}
          changedVimeoLinks={changedVimeoLinks}
          setLoading={setLoading}
        />
      )}
      <div className="dashboardMain">
        <div className="main_content_container">
          <div className="card card_spaces">
            <div className="card_heading">
              <div className="card_heading_content">
                <h3 className="heading3">{LOUNGE_TOUR_DETAILS_TITLE}</h3>
                {isAdmin && (
                  <Select
                    isTourDetails
                    options={TOUR_STATUSES_OPTIONS}
                    selectedItem={tourDetails?.status}
                    customClasses="card_heading_select_container"
                    statusClasses="card_heading_select_circle"
                    selectCustomClasses="card_heading_select"
                    onChange={(e) => setStatus(e.target.value)}
                  />
                )}
              </div>
              <Button
                onClick={({ target }) => handleClickSave(target)}
                name={SECTIONS.date}
                type={BUTTON_TYPES.download_dark}
                text={INSTRUCTIONS}
                downloadIcon={downloadIcon}
              />
            </div>
            <h6 className="heading6">{REQUESTED_BY}</h6>
            <div className="requestCard">
              <div className="userType">
                {isBrokerageAdmin(tourDetails?.user)
                  ? `${tourDetails?.user?.name} ${tourDetails?.user?.last_name}`
                  : tourDetails?.user?.position}
              </div>
              <div className="avatar">
                {tourDetails?.user?.image ? (
                  <img
                    width="62px"
                    src={`${base_url}${tourDetails?.user?.image}`}
                    alt="avatar"
                  />
                ) : (
                  <AgentNoImage
                    name={tourDetails?.user?.name}
                    surname={tourDetails?.user?.last_name}
                  />
                )}
              </div>
              <div className="mediaBody">
                <div className="name">{`${tourDetails?.user?.name || ''} ${
                  tourDetails?.user?.last_name || ''
                }`}</div>
                <p>M: {`${tourDetails?.user?.email || ''}`}</p>
                <p>P: {`${tourDetails?.user?.phone || ''}`}</p>
              </div>
            </div>
          </div>
          <div className="tourMain">
            <div className="details_first_section details_section_padding">
              <div className="dates_part_container">
                <div className="">
                  <div className="card-detail tour_details_card_detail">
                    <h3>{ORDER_PLACED_ON}</h3>
                    <p>{formattedTourCreationDate}</p>
                  </div>
                </div>
                <div className="">
                  <div className="card-detail tour_details_card_detail shooting_date_card">
                    <div className="date_card_heading">
                      <h3 className="shooting_date_title">
                        {LOUNGE_BOOKING_DATE}
                      </h3>
                      <span className="to_be_confirmed">{TO_BE_CONFIRMED}</span>
                    </div>
                    {openCalendar ? (
                      <InlineWidget
                        url={tourDetails?.content_lounge_address?.calendar_code}
                        pageSettings={pageSettings}
                        prefill={{
                          name: `${tourDetails?.user?.name} ${tourDetails?.user?.last_name}`,
                          email: tourDetails?.user?.email,
                        }}
                      />
                    ) : (
                      <div className="date_card_contain shooting_time_container">
                        <p className="shooting_date_text">
                          {formattedTourShootingDate}
                        </p>
                        <p className="shooting_time_text">
                          {formattedShootingTime}
                        </p>
                      </div>
                    )}
                    {openCalendar ? (
                      <div className="card_buttons_container">
                        <Button
                          onClick={({ target }) => handleClickCancel(target)}
                          name={SECTIONS.date}
                          type={BUTTON_TYPES.cancel}
                          text={CANCEL}
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={({ target }) => handleClickEdit(target)}
                        name={SECTIONS.date}
                        type={BUTTON_TYPES.edit}
                        text={EDIT}
                        disabledWithClassOnly={isEditingDisabled}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isAdmin && <VimeoLinks changedVimeoLinks={changedVimeoLinks} />}
          <div className="row details_cards_container justify-content-end">
            <div className="col-xl-6">
              <OrderSummary
                title={ALREADY_CHARGED}
                loungeTourDetails
                loungeService={tourDetails?.content_lounge_service}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
