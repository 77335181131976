import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import { getCenturyTourDetails, getTourById } from 'pages/tours/actions';

import { NavigationControl } from 'components';
import { PAYMENT_ACCEPTED } from 'constants';

// Images
import SmileIcon from 'assets/images/smile.svg';

export function PaymentAccepted() {
  const tourDetails = useSelector((state) => state.tours.tourDetails);

  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const tour_id = searchParams.get('tour_id');
  const is_marketing = Boolean(searchParams.get('is_marketing'));

  useEffect(() => {
    if (!!state && !state?.isMarketingTour) {
      if(state?.isLounge) {
        dispatch(getCenturyTourDetails(tour_id || state?.id));
      } else {
        dispatch(getTourById({ tourId: tour_id || state?.id }));
      }
    }
  }, []);

  return (
    <div className="content">
      <NavigationControl
        isBack
        title={tourDetails.address || `${tourDetails?.id} ${tourDetails?.content_lounge_service?.title}`}
        acceptPayment
      />
      <div className="dashboardMain paymentMessage">
        <div className="card">
          <div className="messageThanks">
            <i>
              <img src={SmileIcon} alt="smile" />
            </i>
            <h4>{PAYMENT_ACCEPTED.thank_you}</h4>
            <p>{state.isLounge ? PAYMENT_ACCEPTED.lounge_thank_you_text : PAYMENT_ACCEPTED.email_send}</p>
          </div>
          {!state?.isMarketingTour && !is_marketing && (
            <div className={`${state?.isLounge && "lounge_message"} message`}>
              <h4>{PAYMENT_ACCEPTED.whats_next}</h4>
              <p>
                {state.isLounge ?
                 PAYMENT_ACCEPTED.launge_text_1 :
                `${PAYMENT_ACCEPTED.we_will_contact}
              ${
                tourDetails.date ? moment(tourDetails.date).format('lll') : ''
              }.`}
              </p>
              {state.isLounge ?
                <>
                  <p>{PAYMENT_ACCEPTED.lounge_text_2}</p>
                  <p>{PAYMENT_ACCEPTED.lounge_text_3}</p>
                  <p>{PAYMENT_ACCEPTED.lounge_text_4}</p>
                  <p>{PAYMENT_ACCEPTED.lounge_text_5}</p>
                </>
              :
                <p>{PAYMENT_ACCEPTED.receive_email}</p>
              }
              <div className="btnControl">
                <Link
                  to={state?.isLounge ?
                    `/lounge-tour-details/${tour_id || state?.id}` :
                    `/tour-details/${tour_id || state?.id}`
                  }
                  state={{ tour_id: tour_id || state?.id }}
                  className="btn cta-primary"
                >
                  {PAYMENT_ACCEPTED.see_order_details}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
