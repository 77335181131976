import React from 'react';

// images
import SearchIcon from 'assets/icons/search.svg';

export default function Input({
  id,
  label,
  isLabeShow = true,
  value,
  required = false,
  type = 'text',
  onChange = () => {},
  name,
  placeholder,
  error,
  helperText,
  customClasses,
  searchIcon,
  children,
  disabled,
  noPlaceholder,
  labelClass = "",
  inputCustomClasses = "",
  ...args
}) {
  return (
    <div className={`form-group input_container  ${customClasses}`}>
      {isLabeShow && (
        <label className={labelClass}>
          {label}
          {required && <span> *</span>}
        </label>
      )}
      {searchIcon && (
        <img className="input_icon" alt="search" src={SearchIcon} />
      )}
      <input
        {...args}
        id={id}
        type={type}
        value={value || ''}
        onChange={(e) => onChange(e)}
        name={name}
        className={`form-control input_elem ${searchIcon && 'with_left_icon'} ${inputCustomClasses}`}
        placeholder={noPlaceholder ? '' : placeholder}
        disabled={disabled}
      />
      {error && <div className="error-text">{helperText}</div>}
      {children}
    </div>
  );
}
