import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NavigationControl } from 'components';
import {
  loungeCurrentStepsSelector,
  selectedLoungePackage,
  loungePackagesSelector,
} from 'pages/tours/selectors';
import { actions } from 'pages/tours/store';
import { ControlBar } from './control-bar';
import { LoungeStepOne } from './lounge-step-one';
import { LoungeStepTwo } from './lounge-step-two';

import {
  NEW_ORDER,
  STEP_ONE,
  STEP_TWO,
  THIRTEEN_PERCENT,
  HUNDRED_PERCENT,
  RETURNED_COUNT,
} from '../constants';

export const NewStepLoungeOne = () => {
  const currentStep = useSelector(loungeCurrentStepsSelector);
  const selectedPackage = useSelector(selectedLoungePackage);
  const loungePackages = useSelector(loungePackagesSelector);

  const dispatch = useDispatch();

  const getStepClass = (step) => {
    if (currentStep === step) {
      return 'active';
    }
    if (currentStep > step) {
      return 'active visited';
    }
  };

  const goToStep = (curr) => {
    if (curr < currentStep) {
      dispatch(actions.setLoungeCurrentStep(curr));
    }
    if (curr > currentStep) {
      document.getElementById('next_button').click();
    }
  };

  const getPrice = useMemo(() => {
    const tempSelPack = loungePackages?.find(
      (el) => el.id === selectedPackage?.id
    );

    const price = Number(tempSelPack?.price) || 0;

    return price;
  }, [selectedPackage, loungePackages]);

  const getTotal = useMemo(() => {
    return (getPrice + (getPrice * THIRTEEN_PERCENT) / HUNDRED_PERCENT).toFixed(
      RETURNED_COUNT
    );
  }, [getPrice]);

  useEffect(() => {
    dispatch(actions.setLoungeStepsData({}))
    dispatch(actions.setSelectedLoungePackage({}))
    dispatch(actions.setLoungeCurrentStep(STEP_ONE))
  }, [])

  return (
    <div className="content">
      <NavigationControl title={NEW_ORDER} isBack />
      <ControlBar getStepClass={getStepClass} goToStep={goToStep} />
      <div className="dashboardMain">
        <div className="card">
          {currentStep === STEP_ONE && <LoungeStepOne />}
          {currentStep === STEP_TWO && <LoungeStepTwo getTotal={getTotal} />}
        </div>
      </div>
    </div>
  );
};
