import { toursApi } from 'api';
import { Toastify } from 'hooks';
import { TOAST_TYPES, TOAST_MESSAGES } from 'constants';
import { UPLOADED_FILES } from 'constants/fileUpload';

const customHeaders = { 'Content-Type': 'multipart/form-data' };

export const uploadVideos = async (id, body, setFiles) => {
  const { data } = await toursApi.uploadVideos({
    id,
    body,
    customHeaders,
  });

  const getLocalUploads = JSON.parse(sessionStorage.getItem(UPLOADED_FILES));
  const dataWithId = data?.map((item) => ({ ...item, progress_id: 1 }));
  !!getLocalUploads
    ? sessionStorage.setItem(
        UPLOADED_FILES,
        JSON.stringify([...getLocalUploads, ...dataWithId])
      )
    : sessionStorage.setItem(UPLOADED_FILES, JSON.stringify([...dataWithId]));

  setFiles((prev) => [...prev, ...data]);
};

export const uploadVideosChunk = async (body) =>
  await toursApi.uploadVideosChunk({
    body,
    customHeaders,
  });

export const uploadVideosStart = async (body) =>
  await toursApi.uploadVideosStart({
    body,
  });

export const uploadVideosComplete = async (body) =>
  await toursApi.uploadVideosComplete({
    body,
  });

export const deleteUploadedVideos = async (id, body) => {
  return await toursApi.deleteUploadedVideos({
    id,
    body,
    customHeaders,
  });
};

export const confirmUploadedVideos = async (id) => {
  try {
    await toursApi.confirmUploadedVideos(id);
    Toastify({
      type: TOAST_TYPES.success,
      message: TOAST_MESSAGES.savedChanges,
    });
  } catch (error) {
    Toastify({
      type: TOAST_TYPES.error,
      message: TOAST_MESSAGES.tryAgain,
    });
  }
};
