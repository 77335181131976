import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { filterQueryStringHelper } from 'helpers/filterQueryStringHelper';
import {
  makeToursGetQueryStr,
  makeUserGetQueryStr,
} from 'utils/toursGetQueryUtil';

import { SortPopup } from 'components';
import { FilterDropdown } from 'pages/tours/components';

// Images
import SearchIcon from 'assets/images/search.svg';
import FilterList from 'assets/images/filter-list.svg';
import SortIcon from 'assets/images/sort.svg';

export default function SearchBar({
  currentPage,
  action,
  id,
  isFilterShow,
  isUserPage,
  filters,
  setFilters,
  title,
  isLounge
}) {
  const [isFilter, setIsFilter] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });

  const dispatch = useDispatch();

  const queryRequest = (sort, filter, searchValue) => {
    const selectedFilterObjQueryStr = isFilterShow
      ? filterQueryStringHelper(filter)
      : '';
    const toursGetQueryStr = isUserPage
      ? makeUserGetQueryStr({
          sortDirection: sort.direction || 'DESC',
          page: currentPage,
          selectedFilterObjQueryStr,
          searchValue: searchValue || '',
        })
      : makeToursGetQueryStr({
          sortField: sort.field || 'created_at',
          sortDirection: sort.direction || 'DESC',
          page: currentPage,
          selectedFilterObjQueryStr,
          searchValue: searchValue || '',
        });

    dispatch(action({ queryStr: toursGetQueryStr, id }));
  };

  const handleSort = (data) => {
    setSort(data);
    queryRequest(data, filters);
  };

  const handleChangeFilter = (data) => {
    setFilters(data);
    queryRequest(sort, data);
  };

  const handleSearch = () => {
    queryRequest(sort, filters, searchValue);
  };

  useEffect(() => {
    const defaultSort =
      sort.field || sort.direction
        ? sort
        : {
            field: 'created_at',
            direction: 'DESC',
          };
    queryRequest(defaultSort, {
      statuses: [],
      agents: [],
    });
  }, [currentPage, isLounge]);

  return (
    <div className="controlBar">
      <ul className="filterLinks" id="filter_modal">
        {isFilterShow && (
          <li className="dropdown">
            <div
              className="cta"
              onClick={() => {
                setIsFilter((prev) => !prev);
                setIsSortOpen(false);
              }}
            >
              {`Filter ${title}`}
              <i>
                <img src={FilterList} alt="filter" />
              </i>
            </div>
            {isFilter && (
              <FilterDropdown
                changeFilter={(data) => handleChangeFilter(data)}
                close={() => setIsFilter(false)}
                filters={filters}
                setFilters={setFilters}
              />
            )}
          </li>
        )}

        <li className="dropdown">
          <div
            className="cta"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
            onClick={() => {
              setIsSortOpen((prev) => !prev);
              setIsFilter(false);
            }}
          >
            {`Sort ${title}`}
            <i>
              <img src={SortIcon} alt="sort" />
            </i>
          </div>
          {isSortOpen && (
            <SortPopup
              close={() => setIsSortOpen(false)}
              changeSort={(data) => handleSort(data)}
              hideDataType={isUserPage}
            />
          )}
        </li>
      </ul>
      <div className="searchField">
        <input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          type="text"
          className="form-control"
          placeholder={
            isUserPage ? 'Try ‘Agent Name’' : 'Try ‘Property Address’'
          }
        />
        <i onClick={handleSearch}>
          <img src={SearchIcon} alt="search" />
        </i>
      </div>
    </div>
  );
}
