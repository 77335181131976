export const ControlBar = (props) => {
  const { getStepClass, goToStep } = props;

  return (
    <div className="controlBar">
      <ul className="stepwizard">
        <li className={`${getStepClass(1)}`}>
          <div onClick={() => goToStep(1)}>
            <i>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.9">
                  <path
                    d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                    fill="#A8B0B9"
                  />
                </g>
              </svg>
            </i>
            <span>Property Details & Shooting Date</span>
          </div>
        </li>
        <li className={getStepClass(2)}>
          <div onClick={() => goToStep(2)}>
            <i>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.9">
                  <path
                    d="M13.9998 2H1.99984C1.2665 2 0.666504 2.6 0.666504 3.33333V12.6667C0.666504 13.4 1.2665 14 1.99984 14H13.9998C14.7332 14 15.3332 13.4 15.3332 12.6667V3.33333C15.3332 2.6 14.7332 2 13.9998 2ZM13.9998 12.6667H1.99984V3.33333H8.6665V6H13.9998V12.6667Z"
                    fill="black"
                  />
                </g>
              </svg>
            </i>
            <span>Add Package/Services</span>
          </div>
        </li>
        <li className={getStepClass(3)}>
          <div onClick={() => goToStep(3)}>
            <i>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.9">
                  <path
                    d="M8 0.666656L2 3.33332V7.33332C2 11.0333 4.56 14.4933 8 15.3333C11.44 14.4933 14 11.0333 14 7.33332V3.33332L8 0.666656ZM12.6667 7.33332C12.6667 10.3467 10.68 13.1267 8 13.9533C5.32 13.1267 3.33333 10.3467 3.33333 7.33332V4.19999L8 2.12666L12.6667 4.19999V7.33332ZM4.94 7.72666L4 8.66666L6.66667 11.3333L12 5.99999L11.06 5.05332L6.66667 9.44666L4.94 7.72666Z"
                    fill="black"
                  />
                </g>
              </svg>
            </i>{' '}
            <span>Finalize & Pay</span>
          </div>
        </li>
      </ul>
    </div>
  );
};
