import React from 'react';

export default function Select({
  id,
  value,
  name,
  placeholder,
  error,
  helperText,
  customClasses,
  selectCustomClasses = '',
  label = '',
  type = 'text',
  options = [],
  onChange = () => {},
  required = false,
  hideLabel = false,
  isPlaceholder,
  isTourDetails,
  selectedItem = '',
}) {
  return (
    <div className={`form-group ${customClasses}`}>
      {!hideLabel && (
        <label>
          {label}
          {required && <span> *</span>}
        </label>
      )}
      <select
        id={id}
        type={type}
        value={value}
        onChange={(e) => onChange(e)}
        name={name}
        placeholder={placeholder}
        className={`form-select ${selectCustomClasses}`}
      >
        {!isTourDetails && 
          <option
            disabled
            value=""
            selected={isPlaceholder}
          >
            {placeholder}
          </option>
        }
        {options?.map((item, index) => (
          <option key={index} value={item.value} selected={item.value === selectedItem}>
            {item.label}
          </option>
        ))}
      </select>
      {error && <span className="error-text">{helperText}</span>}
    </div>
  );
}
