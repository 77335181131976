import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import {
  MediaTour,
  NavigationControl,
  Pagination,
  SearchBar,
} from 'components';
import { toursListSelector } from './selectors';
import { getTours, getLoungeToursAdmin, getLoungeTours } from './actions';
import { types, loungeTypes } from 'constants';
import { isSuperAdmin } from 'helpers/checkUsersRoles';
import { USER_ROLE } from 'constants';
import FileUploader from 'components/fileUploadPopup';
import { ACCESS_TOKEN } from 'constants';
import { EmptyPage } from './components';

const pageTitle = 'Listings';

export function Tours({ isLounge }) {
  const [filters, setFilters] = useState({
    statuses: [],
    agents: [],
  });
  const currPage = useSelector((state) => state.tours.currPage);
  const getUserRole = () => localStorage.getItem(USER_ROLE);
  const isAuth = () => localStorage.getItem(ACCESS_TOKEN);

  const action = isSuperAdmin({ role: getUserRole() })
    ? getLoungeToursAdmin
    : isLounge
    ? getLoungeTours
    : getTours;

  const [currentPage, setCurrentPage] = useState(currPage);

  const toursList = useSelector(toursListSelector);

  const { pathname } = useLocation();
  const { id } = useParams();
  const isUploadVideo = pathname.includes('/upload-video');

  useEffect(() => {
    setFilters(() => ({
      statuses: [],
      agents: [],
    }));
  }, [isLounge]);

  return (
    <div className="content">
      <NavigationControl title={pageTitle} />
      <SearchBar
        filters={filters}
        setFilters={setFilters}
        action={action}
        isFilterShow
        currentPage={currentPage}
        title={pageTitle}
        isLounge={isLounge}
      />
      {isUploadVideo && <FileUploader tourId={id} isAuth={isAuth} />}
      <div className="dashboardMain">
        {!!toursList?.data?.length ? (
          toursList?.data?.map((tour) => (
            <MediaTour
              key={tour.id}
              tour={tour}
              types={tour?.content_lounge_service ? loungeTypes : types}
            />
          ))
        ) : (
          <EmptyPage isLounge={isLounge} />
        )}
      </div>
      <div className="pagination-container">
        {!!toursList?.data?.length && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={toursList.meta.total}
            pageSize={toursList.meta.per_page}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
}
