import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InformativeForward } from 'components/informativeForward';

import {
  LOUNGE_INFO_TEXT,
  LOUNGE_TITLE,
  EXPLORE_OUR_WORK,
  NEXT,
  PREVIOUS,
  PAYABLE_NOW,
  STEP_ONE,
  STEP_TWO,
} from 'pages/tours/create/constants';
import { LoungeServiceInfo } from './lounge-service';
import { LoungePackages } from './lounge-packages';
import {
  loungePackagesSelector,
  selectedLoungePackage,
} from 'pages/tours/selectors';
import { getLoungePackages } from 'pages/tours/actions';
import { OrderSummary } from 'pages/tours/create/new-steps/components/order-summary';
import { actions } from 'pages/tours/store';
import { LOUNGE_TOUR_VIDEO_URL } from 'constants/tours';
import VideoModal from 'pages/download-zone/components/video-modal';

export const LoungeStepOne = () => {
  const [showModalVideo, setShowModalVideo] = useState(false);

  const loungePackages = useSelector(loungePackagesSelector);
  const selectedPackage = useSelector(selectedLoungePackage);

  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(
      actions.setLoungeStepsData({
        content_lounge_service_id: selectedPackage.id,
      })
    );
    dispatch(actions.setLoungeCurrentStep(STEP_TWO));
  };

  useEffect(() => {
    dispatch(getLoungePackages());
  }, []);

  return (
    <>
      <div className="d-flex align-items-start align-items-sm-center flex-sm-row flex-column">
        <h3 className="heading3 mb-0 me-4">{LOUNGE_TITLE}</h3>
        <InformativeForward
          infoText={LOUNGE_INFO_TEXT}
          buttonText={EXPLORE_OUR_WORK}
          forwardPath={'/our-work'}
        />
      </div>
      {showModalVideo && (
        <VideoModal
          close={setShowModalVideo}
          show={showModalVideo}
          value={LOUNGE_TOUR_VIDEO_URL}
          showIframe
        />
      )}
      <LoungeServiceInfo setShowModalVideo={setShowModalVideo} />
      <div className="loungePackagesWrapper">
        {loungePackages?.map((item) => (
          <LoungePackages
            data={item}
            key={item?.id}
            sectionId={`lounge_info_${item?.id}`}
          />
        ))}
      </div>
      <div className="row justify-content-lg-end justify-content-md-end mt-5 mb-3 justify-content-around">
        <div className="col-lg-6 col-md-9 col-12 row justify-content-end">
          <OrderSummary
            title={PAYABLE_NOW}
            packages={loungePackages}
            selectedPackage={selectedPackage}
            loungeTour
          />
        </div>
      </div>
      <div className="text-end footerCta loungeTourButtons">
        <button
          id="prev_button"
          className="btn cta-outline-primary"
          onClick={() => dispatch(actions.setLoungeCurrentStep(STEP_ONE))}
        >
          {PREVIOUS}
        </button>
        <button
          className={`btn cta-primary ${!selectedPackage?.id && 'disabled'}`}
          id="next_button"
          onClick={onSubmit}
          disabled={!selectedPackage?.id}
        >
          {NEXT}
        </button>
      </div>
    </>
  );
};
