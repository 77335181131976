import { useSelector } from 'react-redux';

import { OrderSummary } from 'pages/tours/create/new-steps/components/order-summary';
import {
  loungePackagesSelector,
  selectedLoungePackage,
} from 'pages/tours/selectors';
import {
  ORDER_SUMMARY,
  LOUNGE_INFO_MESSAGE,
  PAYMENT_MESSAGE,
} from 'pages/tours/create/constants';

import infoImg from 'assets/images/info-mesaage.svg';

export const LoungeOrderSummary = () => {
  const loungePackages = useSelector(loungePackagesSelector);
  const selectedPackage = useSelector(selectedLoungePackage);
  return (
    <div className="row mt-5">
      <div className="col-lg-6">
        <OrderSummary
          title={ORDER_SUMMARY.title}
          packages={loungePackages}
          selectedPackage={selectedPackage}
          loungeTour
        />
      </div>
      <div className="col-xxl-6 ps-xxl-5">
        <div className="infoMessage">
          <div className="infoHead">
            <img src={infoImg} alt="Info Message" />
            <h3 className="infoTitle">{PAYMENT_MESSAGE}</h3>
          </div>
          <p className="infoText">{LOUNGE_INFO_MESSAGE}</p>
        </div>
      </div>
    </div>
  );
};
