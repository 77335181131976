export const CIRCULAR_PROGRESS_TYPES = {
  danger: 'danger',
  white: 'white',
};

export const CIRCULAR_PROGRESS_SIZE_NAMES = {
  extraSmall: 'extraSmall',
  small: 'small',
  medium: 'medium',
};

export const CIRCULAR_PROGRESS_COLOR = {
  primary: '#A19276',
  danger: '#e71b4c',
  white: '#ffffff',
  secondaryColor: 'rgba(0,0,0,0.44)',
  white_opacity: "#e6e6e6"
};

export const CIRCULAR_PROGRESS_SIZE = {
  extraSmall: 15,
  small: 30,
  medium: 60,
};
