import { useEffect, useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components';
import { SELECT_DATE, SELECT_LOCATION } from 'pages/tours/create/constants';
import {
  calendarAddressDataSelector,
  calendarDataSelector,
} from 'pages/tours/selectors';
import { userSelector } from 'pages/auth/selectors';
import { actions } from 'pages/tours/store';
import { getCalendlyDataById } from 'pages/tours/actions';
import { getBookingDate } from 'helpers/momentHelpers';

export const LoungeDate = () => {
  const [calendarUrl, setCalendarUrl] = useState('');
  const [calendarId, setCalendarId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [calendarEventId, setCalendarEventId] = useState('');

  const calendarData = useSelector(calendarAddressDataSelector);
  const calendarDataFromCalendly = useSelector(calendarDataSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const pageSettings = {
    backgroundColor: 'fff',
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
    primaryColor: '00a2ff',
    textColor: '4d5055',
    hideGdprBanner: true,
  };

  const handleEventSchedule = (data) => {
    const uri = data.event.uri;
    const url = new URL(uri);
    const { pathname } = url;
    setCalendarEventId(pathname);

    dispatch(
      actions.setLoungeStepsData({
        calendar_event_id: pathname,
        content_lounge_address_id: calendarId,
      })
    );
  };

  useCalendlyEventListener({
    onProfilePageViewed: () => {},
    onDateAndTimeSelected: () => {},
    onEventTypeViewed: () => {},
    onEventScheduled: (e) => handleEventSchedule(e.data.payload),
  });

  const changeAddress = (e) => {
    const id = e.target.value;
    const findAddress = calendarData?.find((elem) => elem.id == id);
    setSelectedAddress(findAddress);
    setCalendarUrl(findAddress?.url);
    setCalendarId(findAddress?.id);
  };

  useEffect(() => {
    if (calendarEventId && selectedAddress?.token) {
      dispatch(getCalendlyDataById(calendarEventId, selectedAddress?.token));
    }
  }, [calendarEventId, selectedAddress]);

  useEffect(() => {
    if (Object.keys(calendarDataFromCalendly)?.length) {
      const start_time = calendarDataFromCalendly?.start_time;
      const date = getBookingDate(start_time);
      dispatch(actions.setLoungeStepsData({ date }));
    }
  }, [calendarDataFromCalendly]);

  return (
    <div className="loungeStepTwoDate">
      <h5 className="subtitle">{SELECT_DATE}</h5>
      <div className="col-xl-6 pe-xl-5">
        <p className="">{SELECT_LOCATION}</p>
        <Select
          selectCustomClasses="loungeSelectBox"
          placeholder={SELECT_LOCATION}
          isPlaceholder={true}
          options={calendarData?.map((elem) => {
            return {
              value: elem.id,
              label: elem.address,
            };
          })}
          onChange={changeAddress}
        />
      </div>
      <div className="loungeCalendar d-flex justify-content-md-center justify-content-lg-start pe-xl-5">
        {calendarUrl && (
          <InlineWidget
            url={calendarUrl}
            pageSettings={pageSettings}
            prefill={{
              name: `${user?.name} ${user?.last_name}`,
              email: user?.email,
            }}
          />
        )}
      </div>
    </div>
  );
};
