import Logo from "assets/images/propertyLogo.png";
import MainLogo from "assets/images/propertyLogoMain.png";
import { BROKERAGE, MILLENNIUM_INC, NEW_MILLENNIUM } from "constants/logo";

const PropertyLogo = ({ homePage, download, signin }) => {
  return homePage ? (
    <div className="home_property_logo_container">
      <img src={MainLogo} alt={Logo} className={`${download ? "downloadLogo" : "home_property_logo"}`} />
    </div>
  ) : signin ? (
    <>
      <div className="property_logo_container hide_custom_logo">
        <img src={Logo} className="property_logo" alt="Logo" />
        <div className="logo_text_container">
          <h2 className="logo_millennium">{MILLENNIUM_INC}</h2>
          <p className="logo_brokerage">{BROKERAGE}</p>
        </div>
        <div className="mobile_logo_text_container">
          <h2 className="logo_millennium">{NEW_MILLENNIUM}</h2>
        </div>
      </div>
      <div className="home_property_logo_container show_custom_logo">
        <img src={MainLogo} alt={Logo}  className="home_property_logo" />
      </div>
    </>
  ) : (
    <div className="property_logo_container">
      <img src={Logo} className="property_logo" alt="Logo" />
      <div className="logo_text_container">
        <h2 className="logo_millennium">{MILLENNIUM_INC}</h2>
        <p className="logo_brokerage">{BROKERAGE}</p>
      </div>
      <div className="mobile_logo_text_container">
        <h2 className="logo_millennium">{NEW_MILLENNIUM}</h2>
      </div>
    </div>
  )
}

export default PropertyLogo;